.ai-body-index{

    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


    
    background-image: url('../pict/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;

}

.testbg{
    //background: hsla(0,0%,8%,.1);
    //background: linear-gradient(hsla(0,0%,8%,.3) 10%, transparent);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

.ai-body-container{
    //background: linear-gradient(transparent, hsla(0,0%,8%,.6) 42%, );
    
    max-width: 1260px;
    width: 100%;
 position: relative;
margin-top: 2%;
max-height: 700px;
height: 100%;
display: grid;
grid-template-columns: auto auto auto;
justify-content: space-between;
align-content: space-around;
border-radius: 10px;
border-top: 2px solid white;
border-bottom: 2px solid white;
z-index: 2;
color: white;

}

.MuiFormLabel-root.Mui-focused{
    color: black !important;
    //background: whitesmoke;
}



.ai-body-ToolName{
    
 position: absolute;
 top: 0;
 font-size: 40px;
 font-weight: bold;
 color: white;
 width: 100%;
 text-align: center;
 height: 15%;
 background: linear-gradient(hsla(0,0%,8%,.6) 80%, transparent)
}

.ai-body-text{
    
 position: absolute;
 top: 12%;
 font-size: 20px;
 font-weight: bold;
 color: white;
 width: 100%;
 text-align: center;
 height: 15%;

}

.ai-body-client{
    
    max-width: 320px;
    width: 100%;
   margin: 150px 0 100px 0;

   
}
.ai-body-dropdown{

    max-width: 320px;
    width: 100%;
    height: 70px;
    margin: 150px 0 50px 0;
 
   
   
}

.ai-calculate-btn{
    
    position: absolute;
    bottom: 10px;
    right: 45px;
    //transform: translateX(-50%);
    max-width: 170px;
    height: 40px;
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #2196f3;
    color: white;
    cursor: pointer;

}


//Subpage

.ai-subpage-container{
    display: flex;
    justify-content: center;
    align-items: center;
   
    flex-direction: column;
    font-size: 23px;
    font-weight: bold;
 

    
}

.ai-subpage-subcontainer{
    
    width: 100%;
    position: relative;
  
}

.ai-subpage-container-info{
    display: flex;
    justify-content: space-around;
    padding: 30px 0 20px 0;
    align-items: center;

    //box-shadow: 0px 4px 5px #c1c1c1;
}

.ai-subpage-marke{
    display: flex;
    align-items: center;
   

}



.ai-subpage-head{
    font-size: 15px;
    //border: 1px solid black;
    border-right-color: transparent;
    //background: rgb(196, 196, 196);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    
}

.ai-subpage-targetaudience{
    display: flex;
    align-items: center;
    
}

.ai-subpage-container-ranking{
    
    width: 100%;
  //border: 1px solid;
    display: flex;
    align-items: center;
   height: 74vh;
    justify-content: space-evenly;
}


.ai-subpage-piechart-container{
   
    width: 100%;
    //border: 1px solid;
    height: 100px;
    //margin-left: 30px;
    display: flex;
    justify-content: center;
  align-items: center;
    
   
}

.ai-subpage-container-name-checkbox{
    margin-left: 45px;
}


.ai-subpage-exportBtns{

    max-width: 130px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    height: 70px;
    flex-direction: column;

    margin-right: 2%;
}

.ai-subpage-exportBtn{
  
    background: #1976d2;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45%;
    font-size: 14px;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    
}


.context-loadingScreen{
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 3;
    background: white;
    width: 100%;
    
}

.context-subpage-logo{
    position: absolute;
    top: 5px;
    left: 5px;
}

.context-subpage-head{
    margin-top: 40px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .ai-body-container{

        max-width: 1100px;
        width: 100%;
     position: relative;

    max-height: 550px;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-evenly;
    margin-top: 5%;
    
    }


   .ai-subpage-piechart-container{
    height: 200px;
   }

    .ai-subpage-container-ranking{
    
        width: 100%;
      //border: 1px solid;
        display: flex;
        align-items: center;
       height: 69vh;
        justify-content: space-evenly;
    }

    .ai-subpage-pages{
        
        margin-top: 0px;
    }

    .ai-body-client{
    
        max-width: 320px;
        width: 100%;
       margin: 100px 0 0px 0;
        
    }
    .ai-body-dropdown{
    
        max-width: 320px;
        width: 100%;
        margin: 100px 0 0 0;   
       
    }

    .context-subpage-logo{
        position: absolute;
        top: 5px;
        left: 5px;
      
        
        img{
            width: 80%;
        }
    }
    
    .context-subpage-head{
        margin-top: 10px;
    }


}

@media screen and (width: 1024px){

    .ai-body-container{

        max-width: 800px;
        width: 100%;
     position: relative;
    
    height: 350px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-evenly;
    margin-top: 5%;
    
    }


    .ai-body-client{
    
        max-width: 150px;
        width: 100%;
       margin: 20px 0 100px 0;
        
    }
    .ai-body-dropdown{
    
        max-width: 150px;
        width: 100%;
        margin: 20px 0 100px 0;   
       
    }

    .ai-formControl{
        width: 150px !important
    }

}