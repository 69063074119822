@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.gala-footer{

  
    width: 100%;
    max-width: 1280px;
    padding: 0px 30px;
    margin: 0 auto;
    height: 500px;
}

.gala-footer_container{

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.gala-footer-logo_container{
    width: 10%;
}

.gala-footer-nav_container{
  
    display: flex;
   padding-inline-start: 0;
    margin-top: 3%;
    justify-content: center;
    line-height: 30px;
    padding: 0;

    li{
        margin-right: 30px;
        color: rgb(84, 84, 84);
        font-size: 13px;

    }
}

@media screen and (min-width: 1536px) and (max-width: 1919px){

   
  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

 
    
}



@media screen and (max-width: 1319px){

   
}




@media  (max-width: 576px){


    .gala-footer-logo_container{
        width: 20%;
    }

    .gala-footer{

  
        width: 100%;
        max-width: none;
        padding: 0px;
        margin: 0 auto;
        height: 500px;
     
    }

 
    .gala-footer-nav_container{
        padding: 0;
        width: 85%;
        justify-content: start;
        flex-wrap: wrap;
    
        li{
           margin-left: 0;
        }
    }

    .gala-footer_container{
        margin-left: 40px;
    }

    

}