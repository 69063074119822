.MobileHead{
    height: 75px;
    width: 100%;
    background-color: white;
    //box-shadow: 0px 0px 10px 1px black;
    display: flex;
    justify-content: center;
    z-index: 2;
    position: relative;
}

.Mobile-HeadPanel{
    
    width: 60%;
    height: 75px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.MobileLogoText{
    display: inline-block;
 color: gray;
  width: 40%;
  font-size: 12px;
}

.MobileDropDown{
    display: flex;
  
  max-width: 400px;
  font-weight: bold;
  justify-content: space-evenly;
  width: 100%;
}

.MobileLogoButton{
    display: flex;
 
  width: 300px;
  justify-content: space-around;
  align-items: center;
}

.MobileBtnLog{
    background-color: #094559;
    color: white;
    font-weight: bold;

    font-size: 14px;
  width: 95px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.MobileMenuIcon{
    display: none;
}


@media  (max-width: 480px){
    .MobileDropDown{
        display: none;
    }

    .MobileLogoButton{
        display: none;
    }

    .MobileMenuIcon{
        display: block;
        width: 20px;
    }

    .Mobile-HeadPanel{
        width: 85%;
        justify-content: left;
    }

    #mobilelogo{
        margin-right: 10px;
    }
}