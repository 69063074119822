@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.stern-head{

    height: 170px;
    display: flex;
    justify-content: center;
}

.stern-head_contaier{
    
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.stern-head_navigator{
   
    max-width: 1056px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stern-head_navigator-contaier{
 
    width: 100%;
    height: 70%;
}

.stern-head_topbar-contaier{

    width: 100%;
    height: 30%;
    background: #f3f3f3;
    display: flex;
    
}

.stern-head_navigator_menu{
    display: flex;
    font-weight: 600;
    font-size: 16px;

    span{
        margin-left: 5px;
    }
}

.stern-head_navigator_icon{
    height: 80px;

    img{
        height: 100%;
    }
}

.stern-head_navigator_account{
    display: flex;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    

    span{
        margin-right: 10px;
        color: #cc0000;
    }
}

.stern-head_topbar{
    max-width: 1056px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
}

.stern-head_topbar-list_container{
    display: flex;
    white-space: nowrap;
    overflow: auto;
    scroll-behavior: smooth;
    list-style: none;
    scrollbar-width: none;
    padding: 0;
}

.stern-head_topbar-list_item{
   
    padding: 0 10px;
    font-family:  Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: black;
   
}

.stern-head_topbar-next{
    position: absolute;
    right: 0;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #f3f3f3 50%);
    width: 80px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: end;
    
    
    button{
        border: 0px;
        cursor: pointer;
        background: #f3f3f3;
    }
    button:hover{
        background: #1111110d;
        border-radius: 5px;
        transition: 0.5s all;
    }
}


#accout-text_mobile{
    display: none;
}

#accout-text{
    display: block;
}

@media screen and (min-width: 1536px) and (max-width: 1919px){

  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

   

}



@media screen and (max-width: 1319px){

   

}




@media  (max-width: 576px){

    #accout-text{
        display: none;
    }

    #accout-text_mobile{
        display: block;
    }

    .stern-head_navigator_icon{
        height: 60px;
    }
   

    }