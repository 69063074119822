
// Content


$btn:0;
$btn2:1;

.prime-content{
  
  width: 100%;
  z-index: 0;
  position: relative;
 // background: linear-gradient(180deg, hsla(0,0%,8%,0),hsla(0,0%,8%,0.15) 15%, hsla(0, 0%, 8%, 0.35) 29%,hsla(0, 0%, 8%, 0.58) 44%, #141414, 68%, #141414);
  //max-height: 280px;
  //height: 280px;
  height: 12vw;
  padding-bottom: 40px;

  
}


.PrimeMain-redirect{
  transition: 2s all;
  filter: brightness(0);
  background: #141414;
  transform: scale(1.1);
  transform-origin: center;
 
}


.prime-content-videos{
 width: 100%;
 // border: 1px solid red;
 position: relative;
  height: 100%;
  color: white;
  font-size: 20px;
  cursor: pointer;
  
}



.prime-content-videos-slide{
  
 // width: 300px !important;
  height: 8.3vw;
  border-radius: 5px;

}


.prime-content-videos-slide img{
  width: 100%;
  height: 100%;
  border-radius: 5px;

}






#prime-0-slide{

 

  margin-left: 35px;
  //height: 380px;
  //width: 340px !important;
 
  z-index: 2;
  transition: transform 0.5s;
  //transform: scale(1.5) translateX(25%);
 
}



swiper-container::part(container){
  overflow: visible ;
  }

  /*
#netfl-0-slide:hover{

  transition: transform 0.5s;
  transform: scale(1.5) translateX(25%);
  //transition-delay: 1s;
  .Test{
    opacity: 1;
  }


  
}
*/

.primeSlide{

  
  z-index: 2;
  transition: transform 0.5s;
  //transform: scale(1.5) translateX(25%);
}

.prime-slide-block{
  transition: all 0.3s;
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 8%;
  width: 100%; //17vw
  cursor: pointer;
  

  img{
    border-radius: 10px;
    
  }
  
}

.slide-primenone{

  
}


.prime-slide-block:hover{
  
  transform: scale(1.3);
  transform-origin: center center;
  transition-delay: 0.3s;
  z-index: 3;
  .prime-slide-menu{
  opacity: 1;
  z-index: 3;
  transition-delay: 0.3s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  }




  img{
    border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
 
  }
}


.prime-slide-menu{
  background-color: #181818;
  
  height: 170px;
  display: block;
  transition: all 0.3s;

  opacity:0; //0
  z-index: 0;
  position: relative;
  box-shadow: 0 6px 9px black;
 
}

.prime-content-title{
  color: white;
  font-size: 19px;
  font-weight: bold;
  margin-left: 3.5vw;
  padding-bottom: 15px;
}


#primecontentvideos::part(button-next) {
  opacity: 0;
  width: 20px;
  color: white;
  transition: 0.5s background;
  background: hsla(0, 0%, 8%, 0.5);
  height: 45%;
  padding: 0 20px 0 20px;
  top: 11%;
  
}



#primecontentvideos::part(button-prev) {
  opacity: 0;
  width: 20px;
  color: white;
  transition: 0.5s background;
  background: hsla(0, 0%, 8%, 0.5);
  height: 45%;
  padding: 0 20px 0 20px;
  top: 11%;
}


.prime-content:hover{

#primecontentvideos::part(button-next) {
    opacity: 1;
  
    
  }
#primecontentvideos::part(button-prev) {
    opacity: 1;
  }
   
}


#primecontentvideos::part(button-next):hover {

background: hsla(0,0%,8%,.7);
  
}
#primecontentvideos::part(button-prev):hover {

background: hsla(0,0%,8%,.7);
  
}



.prime-slide-menu-btns{
  display: flex;
  margin-top: 10px;

  width: 95%;
  float: right;
}







.prime-slide-menu-btns-btn{

  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background: #333333;

}

.primeplay{
  background: white;
  margin-left: 5px;
  svg{
    width: 60%;
  }
}

.netlfinfo{
  margin-right: 0;
  position: absolute;
  right: 5px;
}


.prime-slide-menu-text{
  margin-left: 5%;
  span{
    color: white;
    font-size: 15px;
    font-weight: 700;
    margin-left: 5px;
  }
}


.prime-slide-menu-timeline{
  width: 80%;
  margin: 15px 0 0 5px;
  height: 1.5px;
  background: gray;
}



//Video


.prime-video-container{
  background-color: black;
  height: 100vh;
  width: 100%;
  position: relative;

  
}





.prime-main-vid{
  width: 100%;
  height: 100vh;
}



.prime-video-controlls-player{
  display: flex;
  margin-left: 20px;
  align-items:center;
  cursor: pointer;
}

.prime-video-controlls-setting{
  display: flex;
  
  align-items:center;

}






.prime-videoTrack{
  height: 4px;
  width: 92%;
  margin-left:2%;
  background-color: gray;
}






#video-container-adblock{
  z-index: 1;
  position: absolute;
}

/*
.test{
  z-index: 1;
  position: absolute;
  height: 10vh;
  width: 50%;
  background-color: green;
}
  */

  .test{
    width: 300px;
    height: 200px;
    border: 1px solid;
  }

.notest{
  display: none;
}


.prime-slide-menu-playBtn_container{
 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 50px;
}

.prime-slide-menu-playBtn{

  width: 90%;
  height: 100%;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 17px;
  background: #333333;
}



@media  (max-width: 1280px){

  .prime-slide-block{
   
    margin-left: 8%;
    width: 100%;
 
    
    
  }

  .prime-slide-menu-playBtn{
    font-size: 11px;
  }

  .prime-slide-menu-playBtn_container{
    height: 35px;
  }

  .prime-play_svg{
    height: 15px;
    width: 15px;
  }

  .prime-slide-menu-text{
   
    span{
     
      font-size: 13px;
 
    }
  }

  .prime-slide-menu-btns-btn{

    width: 30px;
    height: 30px;
   
  }


}

@media  (max-width: 1024px){

}