
// Head

.head-autoscout{
    width: 100%;
    display: flex;
    height: 72px;
    background: #333;
    justify-content: center;
}

.head-autoscout-hamburger-menu{
    display: none;
}

.head-autoscout-container{
   
    max-width: 1400px;
    width: 100%;
    height: 100%;
    display: flex;
}

.head-autoscout-logo-container{
    
    height: 100%;
    display: flex;
    align-items: center;
    
    padding-right: 50px;
    cursor: pointer;

    img{
        width: 126px;
    }
}


.head-autoscout-menu-container{
    
    display: flex;
    color: white;
    flex-basis: 100%;
    align-items: center;
    gap: 50px;
}

.head-autoscout-menu-items{
    display: flex;
    gap: 30px;
    cursor: pointer;
  
}



.head-autoscout-menu-item{
   
    height: 40px;
    display: flex;
    align-items: center;
    transition: 0.3s all;
    
    padding-right: 10px;
    border-radius: 5px;
    
}

.head-autoscout-menu-item:hover{
    background: white;
    color: black;

}
.head-autoscout-menu-item:hover::after{
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;

}

.head-autoscout-menu-item::after{
    content: "";
    width: 7px;
    height: 7px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-radius: 1px;
    margin-left: 8px;
    transform: rotate(135deg);
    transition: 0.3s all;
    
}


.head-autoscout-menu-interact{
    
    height: 40px;
    display: flex;
    align-items: center;
    gap: 25px;
 
}

.head-autoscout-menu-icon{
    width: 24px;
    height: 26px;
    filter: brightness(0) invert(1);
}

.head-autoscout-menu-verticalLine{
    height: 40px;
    border-left: 1px solid white;
    padding-right:10px;
    display: block;
}

.head-autoscout-menu-loginbtn{
    border: 2px solid white;
    height: 35px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all;

    
}


.head-autoscout-menu-loginbtn:hover{

    background: white;
    color: black;
    
}

.head-autoscout-menu-language{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;

    cursor: pointer;

  
    
    img{
        border-radius: 5px;
        width: 20px;
        height: 22px;
    }
}
.head-autoscout-menu-language:hover{
   background: white;
}

.head-autoscout-menu-selling-cta{
    color: black;
    background: #fbfa99;
    height: 40px;
    width: 180px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;

}



// Body

.body-autoscout{
    width: 100%;

    background: #f4f4f4;
 
}

.body-autoscout-start-img{
   
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;

   
}



.body-autoscout-container{
   // border: 1px solid;
    max-width: 1100px;
    width: 100%; 
   
    margin: auto;
}

//Filter



  .filter-autoscout-container{
    margin-top: -300px;
    z-index: 1;
    position: relative;
   
  }

.filter-autoscout{
    
    width: 100%;
    background: white;
    border-radius: 5px;

}


.filter-autoscout-category{
  
    width: 40%;
    display: flex;
    height: 45px;

    
}

.filter-autoscout-category-item{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    background: #dcdcdc;
    cursor: pointer;

    svg{
        width: 40%;
    }
}

.autoscout-picked{
    background: white;
    position: relative;
}

.autoscout-picked::after{
    border-top: 3px solid black;
    bottom: 0;
    content: " ";
    display: block;
    position: absolute;
    width: 75%;
}

.filter-autoscout-category2{
   
    display: flex;

    padding-left: 30px;
    

}

.filter-autoscout-category-item2{
  
    max-width: 80px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: gray;
    position: relative;
    cursor: pointer;
 
}
.filter-autoscout-category-item2::after{
  content: " ";
  border: 1px solid #eaeaea;
  bottom: 0;
  position: absolute;
  width: 100%;
    
}

.autoscout-item2-picked{
 color: #1166a8;
}

.autoscout-item2-picked::after{
  content: " ";
  border: 1px solid #1166a8;
  bottom: 0;
  position: absolute;
  width: 100%;
    
}

.filter-autoscout-dropdown{

    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    
}

.filter-autoscout-dropdown-container{
    
    //max-width: 250px;
    flex-basis: 30%;
   // width: 100%;
    height: 40px;
}
.filter-autoscout-dropdown-searchbtn{
   border-radius: 5px;
   box-shadow: 1px 2px 4px gray;
   background: #f5f200;
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 600;
   font-size: 16px;
    height: 40px;
    cursor: pointer;

}


.filter-autoscout_cta-link{
    flex-basis: 30%;
    color: black;
}

.filter-autoscout-select{
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 16px;
    color: #535353;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
}

.filter-autoscout-select-textfield{
    width: 99%;
  height: 90%;
    border-radius: 5px;
    border: 1px solid;

}



.filter-autoscout-dropdown-container-textfield{
    flex-basis: 30%;
  height: 40px;


}

.filter-autoscout-dropdown-checkbox-container{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.filter-autoscout-dropdown-checkbox-container-section{
    width: 97%;
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.filter-autoscout-dropdown-div-contianer{

height: 100%;
display: flex;
align-items: center;


}

._detail-search{
    display: flex;
    align-items: center;

    color: #1166a8;
   
}

.filter-autoscout-dropdown-label{
    border: 1px solid;
    font-size: 15px;
}

.container-filter-autoscout{
    max-width: 1100px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

//Cards

.cards-autoscout-container{


    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cards-autoscout-element{
    
    height: 100%;
    //flex-basis: 48%;
    max-width: 540px;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
}

.cards-autoscout-element-card{
    
    height: 60%;
    display: flex;
    align-items: center;
}

.cards-autoscout-element-card-btn_conainer{
   border-top: 1px solid gray;
    height: 40%;
    display: flex;
    align-items: center;
   

}

.cards-autoscout-card-icon_container{
  
    display: flex;

    span{
        margin-left: 15px;
    }
}

.cards-autoscout-card-icon{
    background: #f4f4f4;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cards-autoscout-element-card-cta_btn{
    border: 2px solid black;
    border-radius: 4px;
    width: 150px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    span{
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
    }
}


.autoscout-swiper-item{
 
    display: flex;
    align-items: center;
    flex-direction: column;

    span{
        font-size: 12px;
        font-weight: 600;
    }
}


.autoscout-swiper-item_img{
   
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 100%;
        
    }
}

.cards-autoscout_ol453{
    width: 100%;
    height: 60px;
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    span{
        color: #0c4f90;
        margin-left: 10px;
    }
}

#autoscout_arrow{
    margin-left: 15px;
    
}

//Cards Vehicle

.autoscout-cards_Vehicle-HeadText{
    margin-top: 25px;
    font-size: 20px;
    font-weight: 600;
}

.autoscout-cards_Vehicle-container{
    width: 100%;
 
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.autoscout-cards_Vehicle-item_container{
   
    flex-basis: 23%;
    height: 380px;
    background: white;
    border-radius: 5px;
}

.autoscout-cards_Vehicle-item_imgContainer{
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 190px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.autoscout-cards_Vehicle-item_text-container{
    
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    height: 185px;
    position: relative;
 
    
}

.autoscout-cards_Vehicle-item_marke{
    font-weight: 600;
    font-size: 15px;
    margin-top: 10px;
}

.autoscout-cards_Vehicle-item_price{
    font-size: 19px;
    font-weight: 600;
    margin-top: 5px;
}

.autoscout-cards_Vehicle-item_descr{
    font-size: 14px;
    margin-top: 5px;
}

.autoscout-cards_Vehicle-item_location{
    font-size: 13px;
    bottom: 10px;
    position: absolute;
}

.ol454{
    margin-top: 40px;
}

//Cards Info

.cards_info-autoscout-container{
   
    position: relative;
    display: flex;
    justify-content: space-between;
    top: 30px;
    margin-bottom: 60px;

  
}

.cards_info-autoscout_img-container{

    flex-basis: 49%;
    position: relative;
    
}

.cards_info-autoscout_img{
    width: 100%;
    height: 100%;
   

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}

.cards_info-autoscout_text-container{
    
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    font-size: 19px;
 
    
}

#logo565{
    width: 90px;
  position: absolute;
  top: 20px;
  left: 40px;
}

.rt4er{
    margin-left: 20px;
    bottom: 20%;
}

.rt5er{
    font-size: 17px;
}


.kio322{
    width: 30%;
  
    margin-left: 40px;
}


// Icons

.icons-autoscout-container{

    margin-top: 30px;
}

.icons-autoscout_headText{
    font-size: 27px;
 
}

.icons-autoscout_typeIcons{
   
    display: flex;
    justify-content: space-between;
    
}

.icons-autoscout_typeIcons_item{
    
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color:#0c4f90;
}

.icons-autoscout_typeIcons_img-container{
    
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 50%;
        height: 100%;
    }
}

.icons-autoscout_marken{
    width: 100%;
   
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(120px,max-content));
    gap:120px
 
}


.icons-autoscout_marken_item_img-container{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
height: 100%;
border: 1px solid gray;
border-radius: 20px;


}

.icons-autoscout_marken_item-container{
   
    
    text-align: center;
    color: #0c4f90;
   

    
    img{
        width: 50%;
    }
   
}


.icons-autoscout_model{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.icons-autoscout_model_column{
    display: flex;
    flex-direction: column;

}



.t463{
    margin-top: 5px; 
}

.icons-autoscout_model_column_head{
    color: black;
    font-weight: 600;
}

.icons-autoscout_model_column_item{
    color: #0c4f90;
    margin-top: 12px;
}


// White Block


.autoscout-white_block{
    margin-top: 120px;
    background: white;
    
    width: 100%;
    position: relative;
}

.autoscout-white_block-text_head{
   padding-top: 20px;
   margin-left: 10px;

    font-size: 28px;
    font-weight: 400;
    width: 90%;
}

.autoscout-white_block-container{
   
    margin-top: 40px;
}

.autoscout-white_block-content_section{
    
    display: flex;
    flex-direction: row;
    height: 270px;
    display: flex;
    align-items: center;
   
}

.white_block-content_section_img-right{
    flex-direction: row-reverse;
    margin-right: 10px;
}

.autoscout-white_block-img_container{
    height: 100%;
    
    display: flex;
    align-items: center;
    margin-left: 10px;

    img{
        height: 80%;
        object-fit: cover;
    }
}

.autoscout-white_block-text_container-head{
    font-weight: 600;
    margin-left: 10px;
}

.autoscout-white_block-text_container-text{
    font-size: 16px;
    color: rgb(49, 49, 49);
   margin-top: 10px;
   margin-left: 10px;
   line-height: 26px;
   width: 90%
}

//Footer

.autoscout-footer_head-text{
    font-weight: 600;
    font-size: 16px;
    margin-top: 70px;
}

.autoscout-footer_item-container{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;
}

.autoscout-footer_item{
    
    line-height: 40px;
    margin-top: 20px;
}
.autoscout-footer_item_special{

    line-height: 30px;
    margin-top: 20px;
    max-width: 220px;
    width: 100%;
}

.autoscout-footer_item_text-head{
    font-weight: 600;
}

.autoscout-footer_item-container_sr4{
    display: flex;
   
   width: 100%;
   margin-top: 10px;
  
}

.autoscout-footer_img-container_sr4{
    width: 18px;
}

.autoscout-footer_text-container_sr4{
    margin-left: 10px;
}

.autoscout-footer_img-container_sr5_img-container{
    width: 40px;
  height: 40px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 10px;

 
 
}

#inst453{
   width: 50%;
    }

.autoscout-footer_img-container_sr5{
    width: 100%;
  display: flex;
  flex-wrap: wrap;
  
}

// Subpage

.subpage-autoscout{
    width: 100%;
    // löschen
    background: #f4f4f4;
    

    
}
.subpage-autoscout-item{
    margin-bottom: 20px;
    background: white;
    box-shadow: 0 2px 6px #d9d9d9;
    padding: 10px 0 30px 0;
    max-width: 830px;
    width: 100%;
    height: 320px;
    display: grid;
    grid-template-columns: repeat(2, minmax(285px, max-content));
    grid-template-rows: repeat(3, minmax(auto, max-content));
    //grid-template-rows: repeat(3, auto);
    grid-template-areas: 
    "head head"
    "img descr"
    "imgfoot sellerfoot";
    
}

.subpage-autoscout_content-container{

    max-width: 1110px; //1200px
    width: 100%;
    margin: auto;
    height: 100%; 
    flex-direction: column;
    display: flex;
    justify-content: space-around;



}

.subpage-autoscout_container{
    max-width: 1200px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-around;
}


.subpage-autoscout_ad_content-container{

    width: 100%;
    height: 100%;
    display: flex;
}

.subpage-autoscout-filter{
    max-width: 260px;
    width: 100%;
    padding-bottom: 50px;
   // löschen

    background-color: white;
}

.subpage-autoscout-filter_block2_rgt44-drop-down{
   
  border-top: 1px solid #929292;
  border-bottom: 1px solid #929292;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.rgt44_label{
margin-left: 10px;
font-weight: 600;
}
.rgt44_btn{
margin-right: 10px;
}


// HPTO Ad

.hpto_ad-top-autoscout{
    width: 100%;
    display: flex;
    justify-content: center;
    


}

.hpto_ad-top-autoscout_img-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    

    img{
        width: 100%;
    }
}

.hpto_ad-top-autoscout_img_fireplace_top{
    position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.hpto_ad-side-autoscout{

  //max-width: calc((100vw - 1200px)/2);
  width: 40%;
  height: 93vh;
  position: relative;
  transition: 0.5s all;
  
}

.hpto_ad-side-autoscout-background{
    background: linear-gradient(to top, rgba(77, 79, 114, 0) 0%, rgb(63, 75, 108) 50%);
    width: 100%;
    height: 180px;
    position: absolute;
    top: 0;
    z-index: 1;
}

.hpto_ad-side-autoscout_img-container{
    width: 100%;
    height: 100%;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 65%;
       
    }
}

#hpto_ad-side-autoscout_fireplace-right-img{
    transform: scale(1.3);
    transition: 5s all;
}
#hpto_ad-side-autoscout_fireplace-left-img{
    transform: scale(1.3);
    transition: 5s all;
}

#hpto_ad-side-autoscout_fireplace-text_btn-container{
    opacity: 0;
    transition: 0.5s all;
}

#pto_ad-side-autoscout_text-fireplace-special{
    position: absolute;
    top: 4%;
    font-family: audi3;
    font-size: 1vw;
    transition: 0.5s all;
}

#pto_ad-side-autoscout_text-fireplace-left{
    opacity: 0;
    transition: 0.5s all;
}

#hpto_ad-side-autoscout_cta-audi-fireplace{
    opacity: 0;
    transform: scale(1.2);
    transition: 1s all;
}


// Subpage Items

.subpage-autoscout-item_img-container{
    grid-area: img;
    max-width: 285px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(211, 211, 211);

    img{
        width: 90%;
    }
}

.subpage-autoscout-item_descr{
    grid-area: descr;
    border-bottom: 1px solid rgb(211, 211, 211);
    display: grid;
    grid-template-rows: max-content;
    
}

.subpage-autoscout-item_descr_detail{
   display: flex;
   
   flex-wrap: wrap;

}

.subpage-autoscout-item_name{
    font-weight: 700;
    font-size: 19px;
    margin-left: 15px;
    padding: 10px 0 5px 0;
}

.subpage-autoscout-item_descr_price{
    font-size: 25px;
    font-weight: 700;
}

.subpage-autoscout-item_descr_finance{
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;

    span{
        color: #1166a8;
        margin-left: 5px;
    }
}

.line493{
    border-top: 1px solid rgb(211, 211, 211);
    border-bottom: 1px solid rgb(211, 211, 211);
    width: 98%;
}

.subpage-autoscout-item_descr_detail-container{
    display: flex;
    font-size: 13px;
    flex-basis: 23%;
    align-items: center;

}

.subpage-autoscout-item_descr_detail_icon-container{
margin-right: 10px;
}

.subpage-autoscout-item_descr_detail_icon-container{
    width: 17px;
    height: 17px;
}

.subpage-autoscout-item_foot{
    //"imgfoot sellerfoot";
    grid-area: imgfoot;

    padding: 10px 0 10px 0;
    
}


.subpage-autoscout-item_foot_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.subpage-autoscout-item_foot_img-container{
    max-width: 47px;
    max-height: 30px;
    width: 100%;
    height: 100%;
    margin-left: 10px;


    img{
        width: 100%;
        height: 100%;
    }
}

.hpto_ad-side-autoscout_ad-text{
    position: absolute;
    bottom: 10%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s all;

    img{
        width: 100%;
    }
}

.hpto_ad-side-autoscout-logo-container{
    max-width: 160px;
  margin: 30px 0 0 30px;
  width: 100%;

  img{
    width: 100%;
  }
}



.hpto_ad-side-autoscout_text_btn-container{
  
    height: 40%;
    position: absolute;
    top: 50%;
    width: 80%;
    left: 5%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.hpto_ad-side-autoscout_text{
    font-family: audi2;
    font-size: 0.8vw;
}

#hpto_ad-side-autoscout_text1{
    font-family: audi3;
}

#hpto_ad-side-autoscout_text2{
    font-family: audi3;
    display: block;
}


.hpto_ad-side-autoscout_foot-text{
    margin-top: 10%;
    font-size: 0.8vw;
    font-weight: 600;
}


.hpto_ad-side-autoscout_cta{

  width: 55%;
  margin-top: 60px;

  img{
    width: 100%;
  }
}

.subpage-autoscout-item_foot_text-container{
    
    font-size: 12px;
    color: #333;
    line-height: 18px;

    span{
        display: block;
    }
}

.subpage-autoscout-item_foot_2{
     grid-area: sellerfoot;
   
     display: flex;
     align-items: center;
     justify-content: end;
     
}

.subpage-autoscout-item_foot_2-text{
    color: #1166a8;
    font-size: 12px;
    margin-right: 10px;
    
}

.subpage-autoscout-item_name_info{

    font-size: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 
  width: 250px;
}

//Filter Items


.subpage-autoscout_filter-btn_container{
   
    height: 70px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 2px gray;
}

.subpage-autoscout_filter_btn_container{
    max-width: 260px;
    width: 100%;
    margin-right: 10px;
}

.subpage-autoscout_filter-btn{
    background: #f5f200;
    width: 90%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0px 1px 3px gray;

}

.subpage-autoscout-filter_block1{

    border-bottom: 1px solid rgb(211, 211, 211);
    padding-bottom: 100px;
}

.subpage-autoscout-filter_block1_text1{
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 15px;
  

    svg{
        width: 17px;
        height: 17px;
    }

    span{
        font-weight: normal;
        color: #1166a8;
    }
}

.subpage-autoscout-filter_block1_svg-container{
 
    display: flex;
    align-items: center;
    margin-top: 10px;
 

    div{
        margin-left: 8px;
    }

}

.subpage-autoscout-filter_block1_btn{
    background: #f4f4f4;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 5px;
    margin: 10px 0 0  10px;
}

.subpage-autoscout-filter_block2_checkboxes{
    display: flex;
    flex-direction: column;
}

.subpage-autoscout-filter_block2_standard-text{
    font-size: 15px;
    margin-top: 10px;
}

.subpage-autoscout-filter_block2_container{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.subpage-autoscout-filter_block2_container_r643{
    width: 90%;
}
.subpage-autoscout-filter_block2_drop-down{

width: 100%;
height: 40px;
margin-top: 10px;
border-radius: 5px;
color: gray;
border: 1px solid gray;
background: white;
}

.ez32{
    width: 50%;
}

.subpage-autoscout-filter_block2_radio{
    display: flex;
    align-items: center;
    margin-right: 15px;
 
}

.subpage-autoscout-filter_block2_radio_input{
    width: 20px;
    height: 20px;
    

}

.subpage-autoscout-filter_block2_radio_label{
    margin-left: 5px;
    font-size: 15px;
}

.ra32{
    display: flex;
    
}

.CheckBox2{
    transform: scale(1.5);
  margin-left: 20px;
}

.cb32{
    margin-top: 30px;
}

.subpage-autoscout-filter_block2_checkboxes-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
}

.turf45{
    width: 100px;
    background: gray;
    border-radius: 3px;
   
}

.trgr33{
    font-size: 14px;
}

#subpage-autoscout-filter_block2_checkboxes_price-line_5{
    height: 5px;
  background: green;
  margin-top: 2px;
  width: 100px;
 
  
}
#subpage-autoscout-filter_block2_checkboxes_price-line_4{
    height: 5px;
  background: #7dc32f;
  margin-top: 2px;
  width: 80px;
}
#subpage-autoscout-filter_block2_checkboxes_price-line_3{
    height: 5px;
  background: #add042;
  margin-top: 2px;
  width: 60px;
}
#subpage-autoscout-filter_block2_checkboxes_price-line_2{
    height: 5px;
  background: #f2ca26;
  margin-top: 2px;
  width: 40px;
}
#subpage-autoscout-filter_block2_checkboxes_price-line_1{
    height: 5px;
  background: #e67e23;
  margin-top: 2px;
  width: 20px;
}

.subpage-autoscout-filter_block2_ghgt56{
    display: flex;
    
   
    height: 40px;
   

    div{
        height: 100%;
        width: 100%;
        border: 1px solid #333333;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.CheckBoxLabel2{
    margin-left: 15px;
    font-size: 15px;
}

// HPTO Audi A5

.hpto_ad_a5-side-autoscout{
    width: 40%;
  height: 100%;
  position: relative;
  transition: 0.5s all;
  position: sticky;
  top: 0;
}

.A534583{
    display: flex;
    justify-content: end;
}

.hpto_ad_a5-side-autoscout_img-container{
    width: 85%;
    height: 100%;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
       
       
    }
}

.hpto_ad_a5-side-autoscout_text{
    font-family: audi2;
    font-size: 0.7vw;
}

.hpto_ad_a5-side-autoscout_cta{
   
  width: 50%;
 

  img{
    width: 100%;
  }
}

.hpto_ad_a5-side-autoscout_text_btn-container{
  
    height: 40%;
    position: absolute;
    top: 55%;
    width: 80%;
    left: 5%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.a5_54985{
left: 20%;
}

.hpto_ad_a5-side-autoscout_foot-text{
    margin-top: 10%;
    font-size: 0.7vw;
 
    font-family: audi2;

    span{
        font-weight: 600;
    }
}

#hpto_ad_a5-side-autoscout_img-bg{
    transform: scale(1.1);
    transform-origin: 0% 50%;
}


.hpto_ad_a5-side-autoscout-logo-container{
    max-width: 100px;
  margin: 30px 0 0 30px;
  width: 100%;
  position: absolute;
  z-index: 1;

  img{
    width: 100%;
  }
}

.hpto_ad_a5-side-autoscout_foot-text2-container{
    opacity: 0;
    font-size: 0.7vw;
    font-family: audi2;
    transition: 0.5s all;
}

.hpto_ad_a5-side-autoscout_foot-text2{
    margin-top: 3%;
}

.hpto_ad_a5-side-autoscout_cta2{
    max-width: 160px;
  width: 100%;
  
  height: 40px;
  //margin-top: 10%;

  img{
    width: 100%;
  }
}

.hpto_ad_a5-side-autoscout_foot-text3{
    
    font-size: 0.7vw;
    position: absolute;
    bottom: 0;
    font-family: audi2;

    span{
        font-weight: 600;
    }
}


#hpto_ad-a5_2-side-autoscout_text-fireplace-special{
    position: absolute;
    top: 0%;
    font-family: audi3;
    font-size: 1vw;
    transition: 0.5s all;
}


// Ad Top Ad

.hpto_ad-top-autoscout_img-container_adTopad{
    height: 100%;
    max-height: 370px;
    position: relative;
    width: 100%;
    min-height: 335px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    max-width: 1000px;
    width: 100%;

    img{
        
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hpto_ad-top-autoscout_img-adTopad{
  position: absolute;
  height: 100%;
  max-height: 370px;
  transition: all 0.8s ease-in
}


.hpto_ad-top-autoscout_logo-container_adTopad{
    position: absolute;
    z-index: 2;
}

.hpto_ad-top-autoscout_img-adTopad_img1_text{
    position: absolute;
  z-index: 2;
  top: 0;
  transition: 0.5s all;
}

.hpto_ad-top-autoscout-adTopad-cta{
    position: absolute;
  bottom: 10px;
  z-index: 2;
  right: 30px;
  background: white;
  width: 150px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s all;
  transform: scale(1.3);
  opacity: 0;
}

.hpto_ad-AudiQ_img-container{
    position: sticky;
    top: 0;
}

// AdBg

.subpage-autoscout_ad_content-container_adbg{

    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}


.subpage-autoscout_content-container_adbg{

    max-width: 1110px;
    width: 100%;
    margin: auto;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    z-index: 3;
    margin-top: 200px;

}

.hpto_ad-AudiQ{
    position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

#hpto_ad-AudiQ_adBg{
    width: 100%;
    top: 0;
    position: fixed;
}

.hpto_ad-AudiQ_img-container{
    position: sticky;
  top: 0;
}

.hpto_ad-AudiQ_logo-container{
    position: absolute;
    z-index: 1;
   
    width: 9%;
    top: 50px;
    left: 20px;
  

  img{
    width: 100%;
 
  }
}

.hpto_ad-AudiQ_text-container{
    //position: absolute;
    z-index: 1;
    font-size: 0.9vw;
    font-family: audi3;
    color: white;
    //top: 16%;
    width: 100%;
  display: flex;
  justify-content: center;
  //text-shadow: 0px 0px 3px black;
  transition: 0.5s all;
}

.hpto_ad_AudiQ-side-autoscout{
 
    z-index: 4;
    position: relative;
    width: 35%;
   
    
}

.hpto_ad_AudiQ-side-autoscout-div_container{
   
      height: 100%;
    
}

.hpto_ad-AudiQlogo-container{
    width: 40%;
  margin-left: 10px;
  position: sticky;
  top: 30px;

    img{
        
    width: 100%;

    }
}

.hpto_ad-AudiQ_logotext-container{
  
    position: sticky;
    color: white;
    font-family: audi3;
    display: flex;
    top: 63%;
    
    flex-direction: column;
    align-items: center;
}


.hpto_ad-AudiQlogo-AudiQ_q232{
    font-family: audi2;
    display: flex;
    align-items: center;
    font-size: 0.7vw;
    transition: 0.5s all;
}

.hpto_ad-AudiQlogo-AudiQ_q232_r{
    font-family: audi2;
    display: flex;
    text-align: center;
    font-size: 0.7vw;
    transition: 0.5s all;

    
}

.hpto_ad-AudiQlogo-AudiQ_q463{
    transition: 0.5s all;
    font-size: 0.9vw;
}

.hpto_ad-AudiQlogo-AudiQ_logotext{
  
  display: flex;
  justify-content: center;
  transition: 0.5s all;
  img{
    width: 55%;
 
  }
}

.hpto_ad-AudiQlogo-AudiQ_cta1{
    font-size: 0.7vw;
    background: white;
    color: black;
    font-family: audi1;
    max-width: 150px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    transition: 0.5s all;
    transform: scale(1.3);

    

}

.q_cta-AudiQ_cta2{
    position: absolute;
    opacity: 0;
    bottom: 0;
    transform: scale(1);
}

.hpto_ad-AudiQlogo-AudiQ_cond{
    color: white;
    transition: 0.5s all;
    font-size: 0.6vw;
    position: sticky;
    top: 90%;
}


//Etron hpto



.hpto_ad_etron-side-autoscout_img-container{
    width: 82%;
    height: 100%;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
       
       
    }
}

.hpto_ad_etron_l_hpto-side-autoscout_text_btn-container{
  
    height: 40%;
    position: absolute;
    top: 55%;
    width: 80%;

    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


}


.hpto_ad_etron-side-autoscout_text{
    font-family: audi3; 
  font-size: 0.9vw;
  font-weight: 600;
}

.hpto_ad_etron-side-autoscout_text2{
    position: absolute;
  top: 20%;
  font-family: audi2;
  font-size: 0.7vw;
  transition: 0.5s all;
}

.E49783{
    width: 100% !important;
}

#hpto_ad-side-autoscout_fireplace-text_btn-containerE3322{
    left: 4%;
}

.hpto_ad_etron-side-autoscout_text3{
    position: absolute;
  top: 35%;
  font-family: audi2;
  font-size: 0.6vw;
  transition: 0.5s all;
}

.hpto_ad2-top-autoscout-adTopad-cta{
    display: flex;
  z-index: 2;
  background: white;
  height: 50px;
  max-width: 155px;
  width: 100%;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 30px;
  bottom: 45px;
  transition: 0.5s all;
  transform: scale(1.3);
  opacity: 0;
}

@media  (max-width: 1600px){

    //Head

    .head-autoscout-container{
   
        max-width: 1200px;
        width: 100%;
        height: 100%;
        display: flex;
    }


    .head-autoscout-menu-selling-cta{
        display: none;
    }


    .hpto_ad_a5-side-autoscout_cta{
        
      width: 60%;
     
    
      img{
        width: 100%;
      }
    }

    .hpto_ad_a5-side-autoscout_cta2{
        
      width: 60%;
     
    
      img{
        width: 100%;
      }
    }

    .hpto_ad_a5-side-autoscout_foot-text2-container{
        font-size: 0.6vw;
    }

    .hpto_ad_a5-side-autoscout_text{
        font-size: 0.6vw;
    }

    .subpage-autoscout_container{
        max-width: 900px;
    }

    .hpto_ad-AudiQlogo-AudiQ_cond{
        color: white;
        transition: 0.5s all;
        font-size: 0.6vw;
        position: sticky;
        top: 90%;
    }

   

    .hpto_ad_etron-side-autoscout_img-container{
        width: 90%;
        height: 100%;
        overflow: hidden;
    
        img{
            width: 100%;
            height: 100%;
           
           
        }
    }


    .hpto_ad-AudiQ_logotext-container{
  
    
        top: 70%;
        
       
    }

    .hpto_ad_etron_l_hpto-side-autoscout_text_btn-container{
  
        height: 40%;
        position: absolute;
        top: 55%;
        width: 85%;
        left: 12%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
    
    }


   
    .hpto_ad_etron-side-autoscout_text2{
        position: absolute;
      top: 20%;
      font-family: audi2;
      font-size: 0.7vw;
      transition: 0.5s all;
    }

    .hpto_ad_a5-side-autoscout_cta{
      
         width: 60%;
    }



    .hpto_ad-top-autoscout_logo-container_adTopad{
       right: 50px;
    }

}

@media  (max-width: 1280px){
    .hpto_ad-AudiQ_text-container{
       
        font-size: 0.8vw;
        
    }

    .hpto_ad-side-autoscout_cta{

        width: 60%;
      
       
      }
      

    .hpto_ad-AudiQlogo-AudiQ_q232{
    
        font-size: 0.7vw;
      
    }

    .hpto_ad-AudiQlogo-AudiQ_q463{
        font-size: 0.9vw;
    }


    .hpto_ad-AudiQlogo-AudiQ_cta1{
        font-size: 0.7vw;
 
        max-width: 100px;
        width: 100%;
        height: 35px;
        
        
    
        
    
    }

    .q_cta-AudiQ_cta2{
        font-size: 0.7vw !important;
    }

}


@media  (max-width: 1100px){

    //Head

    .head-autoscout{
        width: 100%;
        display: flex;
        height: 50px;
        background: black;
        justify-content: center;
    }

    .head-autoscout-logo-container{
        padding-right: 0;
    }

    .head-autoscout-container{
   
        max-width: 980px;
        
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }


    .head-autoscout-menu-selling-cta{
        display: none;
    }



    .head-autoscout-menu-verticalLine{
       display: none;
    }
    
    .head-autoscout-menu-loginbtn{
        display: none;
        
    }

    .head-autoscout-menu-language{
        display: none;
    }

    .head-autoscout-menu-items{
        display: none;
    }

    .head-autoscout-hamburger-menu{
        display: flex;
        align-items: center;

    }

    .head-autoscout-menu-container{
        flex-basis: 0;
    }

}


