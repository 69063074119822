.facebook_content{
    
    width: 100%;
 
}

.facebook_content_container{
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-bottom: 4px solid #d1d1d1;
    padding-bottom: 5px;
    
}

.facebook_content_head{

height: 55px;
display: flex;
align-items: center;
position: relative;
}

.facebook_content_text_container{
display: flex;
 max-height: 120px;
 height: 100%;
 margin-top: 5px;
}

.facebook_content_img{
    
    margin-top: 10px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.facebook_content_infeed-videoad_instream{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    display: none;
}

.facebook_content_infeed-videoad{
    width: 100%;
  z-index: 1;
  position: relative;
}

.facebook_content_video{
    
    margin-top: 10px;
    position: relative;
    
}

.facebook-content_head-post_img{
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin-left: 1%;
    background: #E3E4E8;
    display: flex;
    justify-content: center;
    align-items: end;

    img{
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
        height: 100%;
    }
}


.facebook-content_head-post-name_container{
  
    margin-left: 3%;
    height: 40px;
    max-width: 100px;

  width: 100%;
}

.facebook-content_head-post-name{
    font-weight: 600;
    font-size: 15px;
}

.facebook-content_head-post-publ{
    font-size: 12px;
    color: gray;
    display: flex;
    margin-top: 5px;
    align-items: center;
}

.facebook-content_head-post-func_container{
    
    display: flex;
    max-width: 65px;
    height: 40px;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    right: 1%;
}


.facebook_content_text{
   
    width: 87%;
    height: 100%;
    margin-left: 10px;
    font-size: 14px;
}

.facebook_content_text_vert-line{
   
    width: 2px;
    background: gray;
    margin-left: 2%;
}

.facebook_content_foot_container{
    width: 100%;
   

}

.facebook_content_foot_info{
   
    display: flex;
    justify-content: space-between;
}

.facebook_content_foot_info_likes{
  font-size: 13px;
  color: gray;
  margin-left: 1%;
  display: flex;
  align-items: center;
 
}

.facebook_content_foot_info_rep-com{
  
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: gray;
    font-size: 13px;
    max-width: 175px;
    margin-right: 3%;
}

.facebook_content_foot_btns{

 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-top: 15px;
}

.facebook_content_foot_btn{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: gray;
    font-weight: 600;
}



.facebook_content_infeed-videocont{
    width: 100%;
    z-index: 1;
    position: relative;
}

.facebook_content_foot_btn-like{
    background-image: url("./assets/icons.png");
  background-position: 0px -735px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
 
  
}
.facebook_content_foot_btn-com{
    background-image: url("./assets/icons.png");
  background-position: 0px -546px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.facebook_content_foot_btn-send{
    background-image: url("./assets/icons.png");
  background-position: 0px -399px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.facebook_content_foot_btn-share{
    background-image: url("./assets/icons.png");
  background-position: 0px -861px;
  background-size: auto;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
  
}


.facebook_time_container{
    width: 100%;
    height: 4px;
    background: gray;
    bottom: 1%;
    position: absolute;
    z-index: 2;
    opacity: 0;
}

.facebook_videoAd-timeline{
    width: 0%;
    background: #dbdb25;
    height: 100%;
}