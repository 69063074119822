@font-face {
    font-family: "fact-variable";
    src: url("https://fonts.adobe.com/fonts/fact-variable");
  }



.stern-main_article-container{
    width: 100%;


    img{
        width: 100%;
    }
}

.stern-hightlight_article{
    position: relative;
    background: white;
}

.stern-hightlight_article-text_container{
  
    position: absolute;
    bottom: 6%;
    color: white;
    display: flex;

    flex-direction: column;
    width: 100%;

    p{
        margin-block-start: 0;
        margin-block-end: 0;
    }
}


.stern-article_teaser-items-text-container{

    p{
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

.stern-hightlight_article-text_category{
    font-family: fact-variable, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    max-width: 680px;
    margin-left: 80px;
}

.stern-hightlight_article-text_title{

    font-size: 30px;
    font-weight: 790;
    max-width: 680px;
    margin-left: 80px;
}

.stern-hightlight_article-text_desc{
    font-family: fact-variable, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 22px;
    max-width: 680px;
    margin-left: 80px;
}


.stern-hightlight_article_layout{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 50%), linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 0%), linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, transparent 0%), linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, transparent 0%);

}

.stern-hightlight_article-text_author{
    font-family: fact-variable, Arial, Helvetica, sans-serif;
    font-size: 11px;
    color: rgba(255,255,255, .8 );
    margin-top: 50px;
    max-width: 680px;
    margin-left: 80px;
}



// artikel


.stern-article-container{
    background: white;
    padding: 50px 75px;
    margin-top: 20px;
}

.stern-article-container-main{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: start;
   
    background-color: white;
    gap: 25px;
    
    
}


.stern-article-img-container-main{
    max-width: 410px;
    max-height: 275px;
    width: 100%;
}

.stern-article-text-container-main{
    margin-left: 20px;
    line-height: 25px;
    max-width: 405px;
    width: 100%;

    p{
        margin-block-start: 10px;
        margin-block-end: 10px;
    }
   }
   
   
   
   .stern-article-text-head{
   color: #dc0028;
  
   font-weight: 700;
   font-size: 13px;
   text-transform: uppercase;
   }
   
   .stern-article-text{
       color: black;
       font-size: 26px;
       font-weight: 700;
      
   
   }
   
   .stern-article-img{
       display: block;
   }


   .stern-article-main-theme{
   
    font-size: 13px;
    color: #697882;
    font-weight: 600;
    text-transform: uppercase;

}



.stern-article-main-desk-head{
    color: #dc0028;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.stern-article-main-desk-text{
    color: black;
    margin-top: 5px;
    font-size: 22px;
    font-weight: 800;
    max-width: 330px;
    width: 100%;
   
}

.stern-article-main-desk-desc-main{
font-size: 15px;
margin-top: 10px;
}

.stern-article-text_author{
    color: #545454;
    font-size: 15px;
    margin-top: 10px;
}


.stern-artikel_teaser-block_container{
    width: 100%;
   
    background: white;
    display: flex;
    flex-wrap: wrap;
}

.stern-artikel_teaser-block-body{
    width: 100%;
    display: flex;
    padding: 0px 80px 80px 80px;
    flex-wrap: wrap;
    justify-content: space-between;
}


.stern-article_teaser-block-img-container{
    width: 96px;
    height: 96px;


    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.stern-article_teaser-block{
    display: flex;
    flex-basis: 42%;
    height: fit-content;
    margin: 25px 0;
}

.stern-article_teaser-block-text-container{
    width: 70%;
    margin-left: 15px;

    p{
        margin-block-start: 0;
        margin-block-end: 10px;
    }
}

.stern-article_teaser-block-desk-head{
    color: #dc0028;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
}

.stern-article_teaser-block-desk-text{
    color: black;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 800;
   
}

.stern-article_teaser-block-text_author{
    color: #545454;
    font-size: 12px;
    margin-top: 10px;
}


.stern-artikel_teaser-items_container{
    width: 100%;   
    background: white;
    display: flex;
    flex-wrap: wrap;

}

.stern-artikel_teaser-items-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 80px;
    justify-content: space-between;
}






.stern-article_teaser-items-desk-head{
    color: #dc0028;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.stern-article_teaser-items-desk-text{
    color: black;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 800;
    width: 90%;
}

.stern-article_teaser-items-text_author{
    color: #545454;
    font-size: 12px;
    margin-top: 10px;
}

.stern-article_teaser-items{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-basis: 30%;
}


.stern-article-medrec{
    display: none;
}


.stern-artikel-headText_container{

    margin-left: 80px;

    p{
        font-size: 32px;
        font-weight: bold;

    }
}

.stern-artikel_teaserblock-headText_container{


    p{
        font-size: 32px;
        font-weight: bold;

    }
}

.stern-hightlight-artikel-headText_container{

p{
        font-size: 32px;
        font-weight: bold;
        
        padding: 50px 80px;
        margin-block-end: 0;
      
    }
}

.noPadding{
    padding: 0 !important;
}


@media screen and (min-width: 1536px) and (max-width: 1919px){


   
            
}







@media screen and (min-width: 1320px) and (max-width: 1535px){

  
    

}

@media screen and (max-width: 1319px){

    .stern-article-container-main{
      
        align-items: center;
     
        
    }


    .stern-article_teaser-items-desk-text{
        color: black;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    }

}


@media  (max-width: 576px){

   
    .stern-hightlight_article{
        height: 600px;
    }

    .stern-hightlight_article_container{
        height: 100%;
    }

    .stern-main_article-container{
     
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }


    .stern-hightlight_article_layout{
        background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 0%), linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, transparent 0%), linear-gradient(to left, rgba(0, 0, 0, 0.6) 0%, transparent 0%);
    }


    .stern-hightlight_article-text_container{
        bottom: 10%;
        align-items: center;
        line-height: 32px;
      
    }

    .stern-hightlight_article-text_category{
        margin-left: 0;
        font-weight: 900;
    }

    .stern-hightlight_article-text_title{
        font-size: 25px;
        margin-left: 0;
        text-align: center;
        max-width: 300px;
        font-weight: 900;
    }

    .stern-hightlight_article-text_desc{
        margin-left: 0;
        text-align: center;
        max-width: 290px;
        font-weight: 600;
        margin-top: 20px;
    }

    .stern-hightlight_article-text_author{
        display: none;
    }


    .stern-article-container{
        
        padding: 10px 15px;
    
    }

    .stern-article-container-main{
        flex-direction: column;
    }

    .stern-article-text-container-main{
        margin-left: 0;
    }


    .stern-artikel_teaser-block-body{
        padding: 10px 15px;
    }

    .stern-article_teaser-block{
        flex-basis: 100%;
    }
    
    .stern-artikel_teaser-items-body{
        padding: 0px 15px;
        flex-direction: column;
        margin-top: 30px;
        flex-wrap: nowrap;
    }
    
    .stern-article_teaser-items{
        margin-bottom: 25px;
    }

    .stern-article-medrec{
        display: block !important;
        background: white;
    }
    

    .stern-hightlight-artikel-headText_container{

        p{
            font-size: 27px;
            font-weight: bold;
            padding: 10px 15px;
            margin-block-end: 0;
              
            }
        }

        .stern-hightlight_article-img_container{
            height: 91%;
        }

        .fullSize{
            height: 100% !important;
        }


        
    .stern-article_teaser-items-desk-text{
        color: black;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    }

    .stern-article_teaser-items-desk-head{
        font-size: 13px;
    }
}

