
#menu{
    display: none;
}

#wagen{
    display: none;
}

#user{
    display: none;
}

#mobileLogos{
    display: none;
}

.Head{
    width: 100%;
    max-height: 250px;
    height: 100%;
  
}


.FirstLayer{
    width: 100%;
    height: 80px;
    background-color: #f1f1f1;
  
}

.Content{
    
    
    display: flex;
    align-items: end;
    justify-content: center;
    margin-left: 20px;

}

.HeadButtons{
   
    height: 50px;
    max-width: 900px;
    width: 100%;
    display: flex;
    align-items: end;
   
   
}

.LogoImg{
 width: 20rem; /*260 80*/
 height: 80px;
 cursor: pointer;

 img{
    width: inherit;
    height: inherit;
 }

}


.Button{
    width: 200px;
    height: 40px;
    border: 1px solid rgb(156, 156, 156);
    box-shadow: 3px 3px 5px rgb(162, 162, 162);
    margin-left: 10px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.2em;
    background-color: white;
}

.Button:hover{
   color: rgb(190, 0, 0);
   cursor: pointer;
   height: 45px;
}


.Panel{
 
    height: 80px;
    max-width:550px;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content:right;
   
 
}

.PanelItem{
    display: flex;
    align-items: center;
    margin-right: 25px;
    font-size: 0.8em;
    cursor: pointer;
   
    span{
        margin-left:10px;
        margin-right:10px;
    }
    

}

.PanelItem:hover{
    color: rgb(190, 0, 0);

}



.PayBackImg{
    width: 55px;
    height: auto;
    border-radius: 5px;
}

.Secondlayer{
    width: 100%;
    height: 80px;
    box-shadow: 0px -1px 5px rgb(162, 162, 162);
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    position: relative;
    z-index: 0;
    
    
}

.Content2{
  
    width: 84%;
    height: 80px;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;
    
    justify-content: space-between;
 
}

.SearchField{
    width: 595px;
    height: 50px;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    border-radius: 5px;

    span{
        position: absolute;
        left: 10px;
    }
   
}

.SearchField:hover{
    background-color: #f1f1f1;
}


.OptionenBtn{
    width: 500px;
    display: block;
    height: 75px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.OptionButton{
    border: 1px solid #cccccc;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.9em;
}


.OptionButton:hover{
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid #1c1c1c;
    box-shadow: inset 0 0 0 1px #1c1c1c;
}


.Thirdlayer{
    background-color: #fbfaf9;
    width: 100%;
    height: 79px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
}

.Content3{
   
    width: 84%;
    height: 80px;
    display: flex;
    align-items: end;
}

.FourButtons{
    border: 1px solid #cccccc;
    border-left: transparent;
     width: 110px;
     height: 50px;
     border-bottom: transparent;
     font-size: 0.85em;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.FourButtons:hover{
    color: rgb(190, 0, 0);
    cursor: pointer;
}

#leftbutton{
    border: 1px solid #cccccc;
    border-bottom: transparent;
     width: 110px;
     height: 50px;
     font-size: 0.85em;
     display: flex;
     align-items: center;
     justify-content: center;
     border-top: 4px solid rgb(190, 0, 0);
     color: rgb(190, 0, 0);
}

#leftbutton:hover{
    color: rgb(190, 0, 0);
    cursor: pointer;
}


@media  (width: 1920px) {

    .LogoImg{
        width: 10rem; /*260 80*/
        height: 80px;
      
        
       
       
       
       }


       
    }


    @media  (max-width: 1600px) {
        .Content2{
  
           width: 100%;
        }
        .Content3{
            width: 100%;
        }

        .SearchField{
       
            margin-left: 20px;

            span{
              left: 25px;
            }
        }

        .Content{

            margin-left: 20px;
        
        }

        .LogoImg{
            width: 10rem; /*260 80*/
            height: 80px;
            cursor: pointer;
           
            img{
               width: inherit;
               height: inherit;
            }
           
           }

    }
    
    
    @media  (max-width: 1260px) {

        .Panel{
           position: absolute;
           
         
        }
    }



    @media  (max-width: 480px){


        .SearchField{
        width: 90%;
        height: 35px;
        border: 2px solid black;
        display: flex;
        align-items: center;
        justify-content: end;
        cursor: pointer;
        border-radius: 5px;
        margin-left: 0;
        background: #ffffff;
        margin: 0 auto;

        span{
            position: absolute;
            left: 25px;
        }
}
        

        .Secondlayer{

            display: none;
        }
        .Content{
            margin-left: 0;
        }
            
        .Thirdlayer{
            display: none;
        }

        #mobileLogos{
        display: block;
        height: 30px;
        justify-content: space-around;
        display: flex;
        }

        #user{
            width: 25px;
            height: 25px;
            display: inline-block;
            filter: brightness(0) invert(1);
            margin-right: 15px;
            margin-top: 5px;
        }

        #menu{
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-left: 15px;
            margin-top: 5px;
        }

        #wagen{
            width: 25px;
            height: 25px;
            display: inline-block;
            filter: brightness(0) invert(1);
            right: 55px;
            position: absolute;
            margin-top: 5px;
        }

        #logo{
            width: 5rem;
            height: 30px;
        }

       .Content2{
        display: none;
       }

        .FirstLayer{
            background: #cd0118;
        }

    .LogoImg{
       // display: none;
       width: 100%;
       height: 35px;
       cursor: pointer;
       display: flex;
       justify-content: space-between;
    }

    .HeadButtons{
        display: none;
    }

    .Panel{
        display: none;
    }

    }