.MainBody{
    min-height: 100vh;
    width: 100%;
    
}


.DivElement{
    
    display: none;
    
      }

      .ScrollBtns{
        display: none;
      }


.Sortiment{
    width: 84%;
    height: 120px;
  
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    

}

.SortimentName{
  
  width: 300px;
  height: 70px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 margin-left: 20px;

}




.SortimentPage{
  
    width: 400px;
    height: inherit;
    display: flex;
}

.BackText{
    font-size: 0.8em;
    cursor: pointer;
}

.BackText span{
   
    padding-left: 20px;
}



.BackText:hover{
   color: rgb(190, 0, 0);
    
}

.ProductCount{
    
    font-size: 0.85em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: inherit;
    cursor:default;
}

.ProductCount #count{
    border: 2px solid #676767;;
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#count:hover{
    border: 2px solid black;;
    
}

.ProductCount #sort{
    border: 2px solid #676767;;
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#sort:hover{
    border: 2px solid black;;
    
}

.ProductsandCategory{
    
    width: 84%;
    min-height: 100vh;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}



.Products{

    height: inherit;
    width: 85%;
    margin-left: 20px;
    position: relative;
    transition: 0.2s all;
   
}

.Product{
  border: 2px solid #cccccc;
  width: 240px;
  height: 415px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 14px;
  margin-top: 15px;
  pointer-events: all;
}


.Sponsor{
    background-color: black;
    color: white;
    display: inline-block;
    font-size: 0.8em;
    padding: 2px 7px 2px 7px; 
    font-weight: 600;
    position: absolute;
    display:none;
}

.Showroom{
    
    width: 96%;
    position: relative;
    border: 2px solid transparent;
    margin: 20px 0 20px 0;
    cursor: pointer;
}

.ShowroomText{
    position: relative;
    z-index: 2;
    /* right: 130px; */
    top: 20px;
    color: #69a6dc;
    font-weight: bold;
    font-size: 2.1em;
    float: right;
}

.Carousel{
    width: inherit;
    background-color: #ebecf3;
    height: 340px;
    border-radius: 10px;
    white-space: nowrap;
    justify-content: space-between;
    overflow: auto;
    width: 96%;
    border-left: 1.5rem solid #ebecf3;
    border-right: 1.5rem solid #ebecf3;
    border-radius: 10px;
    position: relative;
    //margin-top: 155px;
    margin: 270px auto auto;
}

.ShowroomImg{
    width: inherit;
    
    height: 300px;
    
    width: 100%;
    position: absolute;
    top: 0;
}
.ShowroomImg img{
    display: inline-block;
    vertical-align: middle;
    max-width: 1315px;
    height: auto;
    //-ms-interpolation-mode: bicubic;
   
    width: 100%;
    height: 400px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}


#ShowroomImgSonst{
    
    border-radius: 40px;
background: linear-gradient(0deg, rgba(251,244,244,1) 0%, rgba(251,236,243,1) 24%, rgba(252,252,252,1) 100%);
text-align: center;

}


#showroomimgsonst{
    width: 65%;
    

}

.CarouselProduct{
    width: 320px;
    height: 190px;
    border:2px solid black;
    display: inline-block;
    margin-left: 25px;
    margin-top:25px;
    border-radius: 20px;
    background-color: white;
    position: relative;
}

.CarouselImgName{
    display: flex;

    
}

.ProdShowImg{
    margin-top: 10px;

}

.ProdShowImg img{
    max-width: 80px;
    max-height: 80px;

}

.ProdShowName{
    white-space: break-spaces;
    margin-top: 10px;
}

.CarouselGerus{
    display: flex;
    
    justify-content: space-between;
    
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.CarouselFavorite{
    width: 30px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
    margin-left: 15px;
}

.CarouselFavorite img{
    width: 18px;
    height: 18px;
}

.CarouselBuy{
    width: 60px;
    height: 32px;
    text-align: center;
    margin-right: 15px;
    background-color: rgb(190, 0, 0);
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.CarouselBuy img{
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
}

.CarouselBuy span{
    font-size: 30px;
}

.CarouselButtons{
    
    width: 150px;
    margin-top: 20px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   position: sticky;
   left: 0;

}

.ButtonPlace{
    border: 2px solid blue;
    width: inherit;
    height: 50px;
   

}


.Prev{
    border: 2px solid black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.5em;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Next{
    border: 2px solid black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.5em;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Product:hover{
    border: 2px solid black;
    
  }

.ProductImg{
 
    text-align: center;
    margin-top: 20px;
}

.ProductName{
    display: -webkit-box;
    width: 85%;
    height: auto;
    font-weight: bold;
    color: #1c1c1c;
 
    overflow: hidden;
    text-overflow: ellipsis;
   
    
    -webkit-box-orient: vertical;
    padding: 20px 30px 0 20px;
}

.ProductFoot{
   
    height: auto;
    position: absolute;
    bottom: 0px;
     height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}



.Favorite{
    width: 40px;
    height: 40px;
    border: 1px solid black;
    text-align: center;
   margin-left: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   
}

.Favorite img{
   width: 20px;
   height: 20px;
}

.Buy{
    width: 70px;
    height: 40px;
    
    text-align: center;
    margin-right: 20px;
    background-color: rgb(190, 0, 0);
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}





.Buy img{
    width: 25px;
    height: 25px;
    filter:brightness(0) invert(1);
   
 }

 

 /*Kategorien*/


 .Categories{
    
    height: inherit;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

 .Category{
    height: auto; /*500px*/
    width: 230px;
    margin-top: 15px;
    box-shadow: 0px 0px 5px #555;
    
 }


 .Checkbox{
    pointer-events: none;
 }

 .Category #kategorie{
   font-weight: bold;
    margin-top: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #676767;
    cursor: pointer;
 }

 .Category #kategorie:hover{
    color: rgb(190, 0, 0);
  }

 .Category ul{
    list-style: none;
  }


  .Category ul li{
    margin-left: -15px;
    margin-top: 10px;
    cursor: pointer;
   padding-left: 20px;
   
  }

  .Category ul li:hover{
   
    background-color: #e4e4e4;
    
  }

  .CategoryBtn{
    border: 1px solid black;
    background-color: #f1f1f1;
    height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    padding-top: 8px;
    font-size: 0.9em;
    cursor: pointer;
    margin-bottom: 20px;

  }

  .CategoryBtn:hover{
    border: 2px solid black;
    

  }

  
  #checkboxlist li:hover{
    background-color: transparent;
  
  }

  #checkboxlist li span{
    margin-left: 10px;
  
  }

  .Dialog{
 position: fixed;   
width: 21%;
max-height: 200px;
height: 100%;
border: 2px solid black;
border-radius: 20px;
background: white;
z-index: 1;
display: none;
opacity: 0;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
transition: 1s all;

  }


.DialogBtn{
position: absolute;
left: 50%;
transform: translateX(-50%);
cursor: pointer;
bottom: 15px; 
width: 50px;
height: 30px;
border-radius: 10px;
background-color: #555;
color: white;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
  }

  #dialogImg{
    width:150px;
    margin-top:15px;
  }

  #logoRexona{
    display: block;
position: absolute;
top: 0;
width: 80px;
height: 80px;
left:5px;
  }

  #logoNivea{
    display: block;
position: absolute;
top: 0;
width: 70px;
height: 70px;
left:5px;
  }

  #sloganRexona{
    position: absolute;
    top: 50px;
    font-size: 40px;
    width: 235px;
    left: 120px;
    color: #002878;
    text-align: initial;

    span{
        font-size: 15px;
    font-weight: 700;
    display: inherit;
    margin-top: 10px;
    }
  }

  #sloganNiveaAxe{
    position: absolute;
    top: 0px;
    font-size: 100px;
    width: 239px;
    /* left: 110px; */
    color: #002878;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    line-height: 0.9;
    right: 50px;


    #spanSloganNiveaAbove{
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 400;
        line-height: 0;
        margin-top: 20px;
    
    }
    #spanSloganNiveaMid{
        text-transform: uppercase;
        font-size: 38px;
        padding-bottom: 10px;
    }
    #spanSloganNiveaBelow{
        text-transform: uppercase;
        font-size: 22px;
    }


  }


  #sloganStars{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 5px;

    img{
        width: 35px;
        height: 35px;
    }

  }

  #sloganNivea{
    position: absolute;
    top: 0px;
    font-size: 100px;
    width: 239px;
    left: 110px;
    color: #002878;
    
    display: flex;
    flex-direction: column;
    font-weight: bold;
    line-height: 0.9;
  

    #spanSloganNiveaAbove{
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 400;
        line-height: 0;
        margin-top: 20px;
    
    }
    #spanSloganNiveaMid{
        text-transform: uppercase;
        font-size: 38px;
        padding-bottom: 10px;
    }
    #spanSloganNiveaBelow{
        text-transform: uppercase;
        font-size: 22px;
    }


  }


  #sloganStars{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 5px;

    img{
        width: 35px;
        height: 35px;
    }
  }


@media  (max-width: 1600px) {


    

    .Product{
       
        width: 220px;
       
      }


    .Sortiment{
        width: 100%;
    }

    .ProductsandCategory{
        width: 100%;
    }


    


    .Thirdlayer{
      /* margin-left: 20px;*/
    }

    .BackText{
        margin-left: 20px;
    }

    #headtext{
        margin-left: 20px;
    }

    .Products{

        width: 80%;
        margin-left: 0px;
       
       
    }

    .Showroom{
        width: 95%;
        margin-left: 20px;
    }


    .Carousel{
        //width: 93%;
       
    }
}


@media  (max-width: 480px){

    #categories{
        display: none;
    }

    .Buy{
       pointer-events: none;
    }

    .ProductCount{
        display: none;
    }

    .SortimentPage{
  
     display: none;
    }


    .SortimentName{
        margin-left: 0;
    }

    .Products{
        width: 99%;
    }

    .Product{
        margin-left: 0;
        width: 100%;
        height: 200px;
        margin-top: 0;
        border-top: 2px solid #cccccc;
        border-bottom: none;
        border-left: none;
        border-right: none;
        pointer-events: none;
       
    }

    .ProductImg{
        text-align: start;
        width: 25%; //90px
        
        display: inline-block;
        margin-top: 30px;

        img{
            width: 100%; //90px
            //height: 90px;
        }
    }

    .ProductName{
        display: inline-block;
        width: 65%;
        padding: 0;
 
        margin-bottom: 20%;
        margin-left: 5%;
    }

    .Showroom{
        margin-left: 10px;
    }

    .ShowroomImg{
        height: 300px;
    width: 100%;
    position: absolute;
    top: 0;

    img{
        display: inline-block;
        vertical-align: middle;
        max-width: 1315px;
        width: 100%;
        height: 90px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    }


    .Carousel{
       
        background-color: #ebecf3;
        height: 160px;
        border-right: 1.2rem solid #ebecf3;
        border-left: none;
        white-space: nowrap;
        overflow: auto;
        width: 96%;
        border-radius: 10px;
        position: relative;
        margin: 85px auto auto;
    }

    .CarouselButtons{
        display: none;
    }


    .CarouselProduct{
        width: 210px;
        height: 117px;
        border: 2px solid black;
        display: inline-block;
        margin-left: 25px;
        margin-top: 25px;
        border-radius: 20px;
        background-color: white;
        position: relative;
    }

  

    .Dialog{
        width: 80%;
        max-height: 220px;
    }

    #dialogImg{
        width:70%;
        margin-top:15px;
      }

      .Element{
        border: 2px solid black;
        height: 45px;
        width: 130px;
        margin-top: 10px;
      }

      #element1{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        margin-left: 5px;
        
      }

      #filter{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
      }

      #filterImg{
        height: 20px;
        width: 20px;
        transform: rotate(90deg);
        margin-right: 5px;
      }

      .DivElement{
    
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
      }
      
      .BackText{
        margin-top: 10px;

      }


     .ScrollBtns{
        display: block;
        height: 60px;
    padding-bottom: 10px;
        white-space: nowrap;
        
        overflow-x: scroll;
        
      }

      .Btn{
        border: 2px solid black;
        display: inline-block;
        height: 30px;
        width: 120px;
       margin-left: 20px;
        border-radius: 20px;
         transform: translateY(40%);
         text-align: center;
         font-size: 14px;
        position: relative;

        div{
            margin-left: 10px;
            margin-top: 3px;
        }

         img{
            position: absolute;
            left: 5px;
            top:3px
            
         }
         
      }

      #btnbio{
        width: 80px;
      }

      #btnneu{
        width: 80px;
      }

      .ProdShowName{
        font-size: 11px;
      }

      .ProdShowImg{

        margin-top: 10px;
     
        img{
            max-width: 55px;
    max-height: 55px;
        }
      }
      
      #ShowroomImgSonst{
       height: 150px;
      }

      #logoRexona{
        display: block;
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    left:5px;
      }

      #logoNivea{
        display: block;
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    left:5px;
      }


      #sloganRexona{
        left: 45px;
        font-size: 10px;
        top: 20px;
        width: 70px;
        color: #002878;
        text-align: initial;
       
    
        span{
            font-size: 6px;
            font-weight: 700;
            display: inherit;
            margin-top: 2px;
        }

    }





    #sloganNivea{
        position: absolute;
        top: 3px;
        font-size: 30px;
        width: 71px;
        left: 40px;
        color: #002878;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        line-height: 0.9;
   
    
        #spanSloganNiveaAbove{
            text-transform: uppercase;
            font-size: 8px;
            font-weight: 400;
           padding-bottom: 5px;
            margin-top: 10px;
        
        }
        #spanSloganNiveaMid{
            text-transform: uppercase;
            font-size: 10px;
            padding-bottom: 2px;
        }
        #spanSloganNiveaBelow{
            text-transform: uppercase;
            font-size: 6px;
        }
    
    
      }
    
    
      #sloganStars{
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
    
        img{
            width: 10px;
            height: 10px;
        }
      }



      #sloganNiveaAxe{
        position: absolute;
        top: 3px;
        font-size: 30px;
        width: 61px;
        right: 25px;
        color: #002878;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        line-height: 0.9;
      
    
        #spanSloganNiveaAbove{
            text-transform: uppercase;
            font-size: 8px;
            font-weight: 400;
           padding-bottom: 5px;
            margin-top: 10px;
        
        }
        #spanSloganNiveaMid{
            text-transform: uppercase;
            font-size: 10px;
            padding-bottom: 2px;
        }
        #spanSloganNiveaBelow{
            text-transform: uppercase;
            font-size: 6px;
        }
    
    
      }
    
    
      #sloganStars{
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
    
        img{
            width: 10px;
            height: 10px;
        }
    
    
      }

}