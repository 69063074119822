@font-face {
    font-family: "fact-variable";
    src: url("https://fonts.adobe.com/fonts/fact-variable");
  }



.stern-main_article-container{
    width: 100%;


    img{
        width: 100%;
    }
}

// hightlight

.gala-hightlight_article{
    position: relative;

    display: grid;
    justify-content: space-evenly;
    grid-template-columns: 38% 22% 30%;
    min-height: 854px;
   
}

.gala-hightlight_article1_container{
    grid-area: 1 / 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.gala-hightlight_article1-img_container{
    height: 100%;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 94%);

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.gala-hightlight_article1-text_title{
    font-size: 16px;
    color: rgb(178, 128, 75);
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 5px;
}

.gala-hightlight_article1-text_desc{
    font-size:28px;
    color: black;
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    margin-top: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.gala-hightlight_article2_container{
    grid-area: 1 / 2;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: space-around;
}

.gala-hightlight_article2{
    height: 100%;
    width: 100%;
}

.gala-hightlight_article2-img_container{

 
    width: 100%;
    max-width: 278px;
    max-height: 278px;
    height: 100%;
    //clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 94%);

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


.gala-hightlight_article2-text_title{
    font-size: 14px;
    color: rgb(178, 128, 75);
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-block-start: 5px;
    margin-block-end: 5px;
}

.gala-hightlight_article2-text_desc{
    font-size:17px;
    color: black;
    font-weight: 800;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    margin-top: 10px;
    line-height: 26px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}




.gala-hightlight_article3{
   
    display: flex;
    width: 100%;
    max-height: 181px;
    height: 100%;
}


.gala-hightlight_article3_container{
    grid-area: 1 / 3;
    display: flex;
    flex-direction: column;
  
    align-items: center;
    justify-content: space-between;
}


.gala-hightlight_article3-img_container{

    width: 100%;
    max-width: 181px;
    max-height: 181px;
    height: 100%;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.gala-hightlight_article3-text_container{
    width: 50%;
    height: 100%;
    margin-left: 20px;
}

.gala-hightlight_article3-text_title{
    font-size: 12px;
    color: rgb(178, 128, 75);
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
   
}

.gala-hightlight_article3-text_desc{
    font-size: 17px;
    color: black;
    font-weight: 800;
    font-family: "TT Chocolates", sans-serif;
    margin-top: 10px;
    line-height: 24px;
    margin-block-start: 5px;
    margin-block-end: 0;
}





// teaser items


.gala-artikel_teaser-items_container{
    width: 100%;   
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    
 

}

.gala-artikel_teaser-items-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
   // margin-top: 60px;
  
}



.gala-article_teaser-items-desk-head{
    font-size: 12px;
    color: rgb(178, 128, 75);
    text-align: center;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
}

.gala-article_teaser-items-desk-text{
    font-size: 17px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
    margin-block-start: 5px;
    margin-block-end: 0;
}



.gala-article_teaser-items{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 278px;
    margin-top: 40px;
    margin-left: 20px;
}


.stern-article-medrec{
    display: none;
}

.gala-article_teaser-items-img-container{
    max-width: 278px;
    height: 278px;
    width: 100%;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 94%);

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}



//block

.gala-artikel_teaser-block_container{
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #b2804b;
    border-bottom: 1px solid #b2804b;
    padding-bottom: 50px;
}

.gala-artikel_teaser-block-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
}



.gala-article_teaser-block{
    display: flex;
    margin: 25px 0;
    max-width: 177px;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.gala-article_teaser-block-text-container{
    width: 100%;
 
}

.gala-article_teaser-block-desk-head{
    font-size: 12px;
    color: rgb(178, 128, 75);
    text-align: center;
    text-transform: uppercase;
}

.gala-article_teaser-block-desk-text{
    font-size: 16px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
   
}


.gala-article_teaser-block-text_author{
    color: #545454;
    font-size: 12px;
    margin-top: 10px;
}

.gala-article_teaser-block-img-container{
    max-width: 177px;
    width: 100%;
    height: 177px;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 94%);

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.gala-article_teaser-block-stars{
    display: flex;
    margin: 25px 0;
    max-width: 177px;
    width: 85%;
    flex-direction: column;
    align-items: center;
}

.gala-article_teaser-block-text-container-stars{
    width: 100%;
    margin-left: 15px;
}

.gala-article_teaser-block-desk-head-stars{
    font-size: 12px;
    color: rgb(178, 128, 75);
    text-align: center;
    text-transform: uppercase;
}

.gala-article_teaser-block-desk-text-stars{
    font-size: 16px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
   
}


.gala-article_teaser-block-text_author-stars{
    color: #545454;
    font-size: 12px;
    margin-top: 10px;
}

.gala-article_teaser-block-img-container-stars{
    max-width: 177px;
    width: 100%;
    height: 177px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// artikel


.gala-article-container{
    
    padding: 0 30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
}

.gala-article-container-main{
    width: 100%;
    margin-top: 20px;
    max-width: 378px;
    
    
}


.gala-article-img-container-main{
    max-width: 378px;
    width: 100%;
    height: 378px;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 94%);

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.gala-article-text-container-main{
   
    line-height: 25px;
    max-width: 405px;
    width: 100%;
   }
   
   
   
   .gala-article-text-head{
   color: #dc0028;
  
   font-weight: 700;
   font-size: 13px;
   text-transform: uppercase;
   }
   
   .gala-article-text{
       color: black;
       font-size: 26px;
       font-weight: 700;
      
   
   }
   
   .gala-article-img{
       display: block;
   }


   .gala-article-main-theme{
   
    font-size: 13px;
    color: #697882;
    font-weight: 600;
    text-transform: uppercase;

}




.gala-article-main-desk-head{
    font-size: 15px;
    color: rgb(178, 128, 75);
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
}

.gala-article-main-desk-text{
    font-size: 18px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
   
}

.gala-article-main-desk-desc-main{
font-size: 15px;
margin-top: 10px;
}

.gala-article-text_author{
    color: #545454;
    font-size: 15px;
    margin-top: 10px;
}








.stern-artikel_teaser-items_container{
    width: 100%;   
    background: white;
    display: flex;
    flex-wrap: wrap;

}

.stern-artikel_teaser-items-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 80px;
    justify-content: space-between;
}




.gala-stars_container{
    margin-top: 130px;
    border-top: 1px solid #b2804b;
    border-bottom: 1px solid #b2804b;
    height: 400px;
  
    
}

#gala-stars_container-header{
    font-weight: bold;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-family: "TT Chocolates", sans-serif;
    margin-block-end: 0;
}


.gala-stars_container-header-container{
    display: flex;
    align-items: end;
}

.gala-stars_container-header_svg-container{
    margin-left: 20px;
}

#gala-artikel-swiper::part(button-next) {

    background: #b2804b;
    width: 0px;
    height: 0px;
    padding: 25px;
    border-radius: 50px;
    background-image:url("../assets/logos/arrow.svg");
    background-size: 100%;      
   
    
   }

#gala-artikel-swiper::part(button-prev) {

    background: #b2804b;
    width: 0px;
    height: 0px;
    padding: 25px;
    border-radius: 50px;
    background-image:url("../assets/logos/arrow.svg");
    transform: rotate(180deg);
    background-size: 100%;      
   
    
   }

   .gala-newsticker-header_container{
   
    display: flex;
    align-items: start;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-top: 15px;

    p{
        margin-block-start: 0;
    }
   }

   .gala-newsticker-img_container{
    margin-left: 20px;
   }

   .gala-newsticker-container{
    
    border-top: 1px solid #b2804b;
    border-bottom: 1px solid #b2804b;
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 100px;
   }

   .gala-newsticker{

    width: 60%;
    margin: 15px 0;

    p{
        font-size: 18px;
        font-family: "TT Chocolates", sans-serif;
        font-weight: 600;
        margin-block-start: 5px;
        margin-block-end: 0;
    }
   }

   .gala-newsticker_time{
    color: #696363;
    font-size: 10px;
    font-family: "TT Chocolates", sans-serif;
   }

   .gala-inhalte_items{
    margin: 15px 0;
   }

   .gala-inhalte_items-header{
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: inline;
    margin-right: 35px;
   }
   .gala-inhalte{
    color: #b2804b;
    text-transform: uppercase;
    font-size: 12px;
    width: fit-content;
    display: inline-block;
    margin-right: 35px;;
   }

.gala-inhalte-list{
    padding: 0;
    word-break: normal;
    display: inline;
}

.gala-container-header{
    display: flex;
    align-items: center;
    margin-top: 15px;

}

.gala-container_teaser-footer-header{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 40px;
}


.gala-container-header-text{
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-family: "TT Chocolates", sans-serif;
    margin-block-end: 0;
    margin-block-start: 0;
}


.gala-container_teaser-footer-header-text{
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-family: "TT Chocolates", sans-serif;
    margin-block-end: 0;
    margin-block-start: 0;
}


.gala_container-header{
    font-weight: bold;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-family: "TT Chocolates", sans-serif;
    margin-block-end: 0;
  
    
  
}

.gala_container-header_container{
    width: 100%;
    margin: 0px 55px;
    border-top: 1px solid #b2804b;
   
}



.gala_container-header-img_container{
    width: 100%;
    margin: 0px 20px;
    display: flex;
    align-items: end;

    svg{
        margin-left: 20px;
    }
}

.none{
    display: none;
}



.gala-newsticker-container-news{
    display: flex;
    flex-direction: column-reverse;
}



@media screen and (max-width: 1865px){

    .gala-article_container{
        max-width: 1090px !important;
    }

    .gala-hightlight_article2-text_desc{
        font-size: 16px;
        line-height: 20px;
    }

    .gala-hightlight_article3-text_desc{
        font-size: 15px;
        line-height: 21px;
    }

    .gala-article_teaser-block-desk-text{
        font-size: 14px;
    }

    .gala-article-container-main{
        max-width: 305px;
    }

    .stern-ad-sidebar{
        //margin-left: 25px;
    }

    .gala-artikel_teaser-items-body{
        justify-content: space-between;
    }

    .gala-article_teaser-block{
        max-width: 150px;
    }

    .gala-article_teaser-block-img-container{
       max-width: 150px; 
    }
}

@media screen and (max-width: 1700px){

    .gala-article_container{
        max-width: 980px !important;
    }

    .gala-article-container-main {
        max-width: 245px;
      }

      .gala-article-img-container-main {
       
        height: 330px;
      }

      .gala-hightlight_article2-text_desc{
        font-size: 13px;
      }

      .gala-hightlight_article3-text_desc{
        font-size: 12px;
        line-height: 17px;
      }  

}






@media screen and (min-width: 1320px) and (max-width: 1580px){

    .gala-article_container{
        max-width: 900px !important;
    }

    .gala-hightlight_article3-text_desc{
        font-size: 13px;
        color: black;
        font-weight: 800;
        font-family: "TT Chocolates", sans-serif;
        margin-top: 10px;
        line-height: 19px;
    }

    .gala-article-img-container-main{
        max-width: 315px;
      
        height: 300px;
    
       
    }

    .gala-hightlight_article3-text_desc{
        font-size: 13px;
        line-height: 18px;
    }
    
    .gala-hightlight_article3-img_container{
        max-width: 140px;
        max-height: 160px;
    }

    .gala_container-header_container{
        margin: 0;
    }


    .gala-article_teaser-block{
        max-width: 125px;
     }

     .gala-article_teaser-block-img-container{
        height: 150px;
     }

     .gala-article_teaser-block-desk-text{
        font-size: 14px;
     }

     
}

@media screen and (max-width: 1319px){

    .gala-hightlight_article3-text_desc{
        font-size: 12px;
        color: black;
        font-weight: 800;
        font-family: "TT Chocolates", sans-serif;
        margin-top: 10px;
        line-height: 18px;
    }

    .gala-article-img-container-main{
        max-width: 215px;
        height: 215px;
    
       
    }

    .gala-hightlight_article2-text_desc{
        font-size: 11px;
        line-height: 20px;
    }

    .gala-hightlight_article3-img_container{
        width: 70%;
    }

    .gala-hightlight_article3-text_desc{

        font-size: 10px;   
    }

    .gala-article-container-main{
        max-width: 200px;
    }

    .gala-article_teaser-items-desk-text{
        font-size: 14px;
    }

    .gala-article_teaser-block-desk-text{
        font-size: 14px;
    }

    .gala-article-main-desk-text{
        font-size: 15px;
    }

    .gala-article-container{
        justify-content: space-between;
        padding: 0;
    }

    .gala_container-header-img_container{
 
        margin: 0px;
     }

     .gala-artikel_teaser-items-body{
        justify-content: space-between;
     }

     .gala-article_container {
        max-width: 750px !important;
      }

  
      .gala-article_teaser-block{
        max-width: 130px;
      }
}

@media  (max-width: 1120px){
    .gala-article_container {
        max-width: 635px !important;
      }

      .gala-hightlight_article3-img_container{
        max-height: 115px;
        width: 55%;
      }

      .gala-hightlight_article3-text_desc{
        line-height: 15px;
      }

      .gala-article_teaser-items{
        max-width: 250px;
      }
}


@media  (max-width: 576px){

   
 
    .stern-main_article-container{
     
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }


    

    .stern-article-container{
        
        padding: 10px 15px;
    
    }

    .stern-article-container-main{
        flex-direction: column;
    }

    .stern-article-text-container-main{
        margin-left: 0;
    }


    .stern-artikel_teaser-block-body{
        padding: 10px 15px;
    }

    .stern-article_teaser-block{
        flex-basis: 100%;
    }
    
    .stern-artikel_teaser-items-body{
        padding: 0px 15px;
        flex-direction: column;
        margin-top: 30px;
        flex-wrap: nowrap;
    }
    
    .stern-article_teaser-items{
        margin-bottom: 25px;
    }

    .gala-article-medrec{
        display: block;
        margin-top: 30px;
   
    }


    .gala-hightlight_article{
       display: flex;
       flex-direction: column;
       
    }

    .gala-article-container{
    
       flex-direction: column;
       padding: 0;
    }


    .gala-article-img-container-main{
        max-width: 378px;
        width: 100%;
        height: 300px;
    
    
    }

    .gala-hightlight_article2{
        height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    .gala-hightlight_article2-img_container{

 
        width: 100%;
        max-width: none;
        max-height: none;
        height: 100%;
    
    }
    

    .gala-hightlight_article3{
   
        display: flex;
        width: 100%;
        max-height: none;
        height: 120px;
        margin: 20px 0;
    }
    

    
.gala-hightlight_article3-img_container{

    width: 40%;
    max-width: none;
    max-height: none;
    height: 100%;



}


.gala-hightlight_article3-text_container{
    width: 60%;
    height: 100%;
    margin-left: 20px;
}


.gala-artikel_teaser-items-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0px;
    justify-content: center;
  
}


.gala-artikel_teaser-block-body{
    width: 100%;
    display: flex;
    padding: 0;
    flex-wrap: nowrap;
    justify-content: none;
    flex-direction: column;
  
}



.gala-article_teaser-block{
    display: flex;
    margin: 25px 0;
     max-width: none;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 165px;
}


.gala-article_teaser-block-img-container{
    max-width: none;
    width: 40%;
    height: 100%;

    
}


.gala-article_teaser-block-text-container{
    width: 60%;
    margin-left: 15px;
}

.gala-hightlight_article1_container{
  height: 400px;
}


.gala-hightlight_article3_container{
   margin-top: 30px;


}

.gala-article_teaser-items{

    margin-left: 0px;
    max-width: none;
    align-items: center;
}


.gala-article_teaser-items-img-container{
    max-width: none;
    height: 300px;
    width: 100%;


}

#gala-stars_container-header{

    font-size: 25px;
  
}

.gala_container-header{
    font-size: 20px;
}


.gala_container-header_container{
    width: 100%;
    margin: 0px 0px;
    border-top: 1px solid #b2804b;
   
}

.gala_container-header-img_container{
 
   margin: 0 0px;
}


.gala-hightlight_article3-text_desc{
    font-size: 16px;
   
}

.gala-container-header-text{
 
    font-size: 20px;
    
}

.gala-article_teaser-block-desk-text{
    font-size: 14px;
    text-align: start;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
   
}

.gala-article_teaser-block-desk-head{
    text-align: start;
}

.gala-container_teaser-footer-header{
    margin-left: 0;
   
}

.gala-container_teaser-footer-header-text{
    font-size: 20px;
}


#gala-artikel-swiper::part(button-next) {

    display: none;
    
   }
#gala-artikel-swiper::part(button-prev) {

    display: none;
    
   }

   .gala-article_teaser-block-img-container{
    max-width: none;
    width: 80%;
    height: 100%;

    
}


.gala-article-container-main{
    max-width: none;
    width: 100%;
}

.gala-article_teaser-items-desk-text{
    font-size: 16px;
}

.gala-hightlight_article2-text_desc{
    font-size: 16px;
    
}


.gala-newsticker{

    width: 100%;
   
   }

}

