@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.gala-head{

    height: 100px;
    display: flex;
    justify-content: center;
    background: white;
    width: 100%;
    padding: 16px 0;
    
}

.gala-head_container{
  
    height: 100%;
    width: 100%;
    max-width: 1280px;
    display: flex;
    
}

.gala-head-logo-container{
    height: 100%;
    width: fit-content;
  

    img{
        height: 100%;
    }
}



.gala-head-nav-links_abo{
    color: rgb(105, 99, 99);
    font-family: "TT Chocolates", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.gala-head-nav-links_container{
  
    height: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 30px;
    justify-content: end;
    width: 100%;

   
}

.gala-head-nav-links{
    display: flex;
    list-style: none;
    padding-inline-start: 0;

}


.gala-head-nav-links-item{
    margin-right: 20px;
    color: black;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 400;
    font-family:  "TT Chocolates", sans-serif;
   
}

.gala-head-nav-links_container-mobile{
  display: none;
   
}

.gala-head-nav-links-mobile{
    display: none;

}


.gala-head-nav-links-item-mobile{
    display: none;
   
}

.gala-head-menu-container{
    display: flex;
    align-items: center;

    img{
        width: 25px;
    }
}


@media screen and (min-width: 1536px) and (max-width: 1919px){

  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

   

}



@media screen and (max-width: 1319px){

   

}




@media  (max-width: 576px){

    .gala-head{

        height: 60px;
        padding: 0 0;
        
    }

    .gala-head-nav-links{
       display: none;
    
    }

    .gala-head-nav-links_container{
  
       justify-content: center;
       
    }

    .gala-head-menu-container{
        margin-right: 20px;
    }



    .gala-head-nav-links_container-mobile{
        height: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 10px;
    justify-content: end;


         
      }
      
      .gala-head-nav-links-mobile{
        display: flex;
        list-style: none;
        padding-inline-start: 0;
      
      }
      
      
      .gala-head-nav-links-item-mobile{
        display: block;
        margin-right: 20px;
        color: black;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 400;
        font-family:  "TT Chocolates", sans-serif;
         
      }


    }