


.prime-slide-menu-btns{
  display: flex;
  margin-top: 10px;
}







.prime-slide-menu-btns-btn{

  width: 1.4vw;
  height: 1.4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}

.primeplay{
  background: white;
  margin-left: 5px;
  svg{
    width: 60%;
  }
}

.netlfinfo{
  margin-right: 0;
  position: absolute;
  right: 5px;
}


.prime-slide-menu-text{
  span{
    color: white;
    font-size: .60vw;
    font-weight: 700;
    margin-left: 5px;
  }
}


.prime-slide-menu-timeline{
  width: 80%;
  margin: 15px 0 0 5px;
  height: 1.5px;
  background: gray;
}



//Video


.prime-video-container{
  background-color: black;
  height: 100vh;
  width: 100%;
  position: relative;

  
}
.primead-video-container{
  background-color: black;
  height: 100vh;
  width: 100%;
  position: relative;

  
}

/*
.prime-video-container:hover{
 
  .prime-video-controlls{
    transition-delay: 0s;
    opacity: 1;
  }
  
  .prime-video-controlls-top{
    transition-delay: 0s;
    opacity: 1;
  }
  
  .prime-timeline-controls-block{
    transition-delay: 0s;
    opacity: 1;
  }

  .prime-filter-effect{
    transition-delay: 0s;
    opacity: 1;
  }
  
}
*/


.prime-main-vid{
  width: 100%;
  height: 100vh;
}


.prime-video-controlls{

  height: 135px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  transition-delay: 0s;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

svg{
  color: gray;
  width: 4vw;
  height: 4vw;
  margin: 0vw 5vw 0 5vw;
  
}
}


/*

.prime-video-controlls{

  position: absolute;
  display: flex;
  justify-content: center;
  opacity: 0;
  top: 50%;
  left: 50%;
  transition-delay: 3s;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);

svg{
  color: gray;
  width: 4vw;
  height: 4vw;
  margin: 0vw 5vw 0 5vw;
  
}
}
*/

#primeLogohoverback:hover{
 svg{
  color: white;
 }
}

#primeLogohoverplaypause:hover{
  svg{
    color: white;
   }
}

#primeLogohoverforw:hover{
  svg{
    color: white;
   }
}

.prime-video-controlls-player{
  display: flex;
  margin-left: 20px;
  align-items:center;
  cursor: pointer;
}

.ad-prime-video-controlls-player{
  display: flex;
  margin-left: 20px;
  align-items:center;
  svg{
    color: white;
   
  
  }
}

.prime-video-controlls-setting{
  display: flex;
  
  align-items:center;

}


.prime-video-controlls-top{
  position: absolute;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  transition: 0.2s all;
  transition-delay: 0s;
  opacity: 0;
 // background: linear-gradient(to bottom,rgba(0, 0, 0, 0.6) 80%, transparent);
 background:linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.6) 60%, transparent);


  svg{
    color: white;
    width: 2.3vw;
  height: 2.3vw;
  }
}
.primead-video-controlls-top{
  position: absolute;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  transition: 0.2s all;
  transition-delay: 0s;
  opacity: 0;
 // background: linear-gradient(to bottom,rgba(0, 0, 0, 0.6) 80%, transparent);
 background:linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.6) 60%, transparent);


  svg{
    color: white;
    width: 2.3vw;
  height: 2.3vw;
  }
}

#AdMultiblock{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

#prime-video-controlls-top-mark{
  margin-right: 2vw;

  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
    height: 80px;
    margin-right: 3%;
  position: relative;

  img{
    width: 100%;
    height: 100%;
  }
}

#primead-video-controlls-top-mark{
  margin-right: 2vw;

  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
    height: 80px;
    margin-right: 3%;
  position: relative;

  img{
    width: 100%;
    height: 100%;
  }
}

#prime-volume-logo_container{
cursor: pointer;
}

#primead-volume-logo_container{
cursor: pointer;
}

.prime-volume_container{
position: absolute;
background: #616161;
width: 40px;
height: 150px;
top: 80%;
left: 50%;
transform: translateX(-50%);
border-radius: 6px;
display: none;
justify-content: center;
padding: 10px 0 10px 0;
}

.primead-volume_container{
position: absolute;
background: #616161;
width: 40px;
height: 150px;
top: 80%;
left: 50%;
transform: translateX(-50%);
border-radius: 6px;
display: none;
justify-content: center;
padding: 10px 0 10px 0;
}

#prime-video-controlls-top-back{
  margin-left: 30px;
}

.prime-videoTrack{
  height: 4px;
  width: 90%;
  margin-left:2%;
  background-color: gray;
}
#primead-video-controlls-top-back{
  margin-left: 30px;
}

.prime-videoTrack{
  height: 4px;
  width: 90%;
  margin-left:2%;
  background-color: gray;
}

.prime-timeline-controls-block{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
 
  bottom: 0;
  transition-delay: 0s;
opacity: 0;
}


.prime-duration-container{
  width: 100%;
 justify-content: center;
  display: flex;
  align-items: center;

  span{
    color: white;
    margin-left: 20px;
  }
}

.prime-timeline{
  width: 0%;
  background-color: white;
  height: 4px;
  transition: all 1s;
  position: relative;
  display: flex;
  align-items: center;
}




#video-container-adblock{
  z-index: 1;
  position: absolute;
}



.notest{
  display: none;
}

.VideoDurationTime{
  color: white;
  margin-left: 5%;
  margin-top: 1%;
  font-size: 1.3vw;

  span{
    margin-left: 1%;
    font-weight: bold;
    font-size: 1.5vw;

  }
}

.prime-filter-effect{
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 0.2s all;
  transition-delay: 0s;
}


.primeAd-time-count_container{
  width: 240px;
  height: 45px;
  //background: #9b9797;
  z-index: 4;
  display: flex;
  position: absolute;
  top: 25px;
  left: 10%;
  border-radius: 30px;
  justify-content: space-evenly;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    //background: linear-gradient(#131313,#0000);
    background: rgba(0,0,0,0.5);
    text-shadow: 0px 0px 3px black;
    opacity: 0;
}

.prime-circle{
  width: 7px;
    height: 7px;
    background: white;
    border-radius: 50%;
}

.primeAd-time-count-adText{
  color: #ffff6f;
}

.vidIntro{
  position: absolute;
  top: 0;
}

// loader

.prime_video_loader{
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 

}
.primead_video_loader{
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 

}

.primeAd-Ad_time{
  opacity: 1;
}