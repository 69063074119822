.Reweka-Main{
    width: 100%;
    min-height: 100vh;
    transition: 1s opacity;
    
}

.reweka-firstContainer{
   
    width: 100%;
    display: flex;
    height: 300px;
    
    flex-direction: column;
    align-items: center;
}

.reweka-greeting-container{
  max-width: 1260px;
  width: 100%;

  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reweka-greeting{
    font-size: 45px;
    font-weight: bold;
    color: #4a4a4a;
   

}


.reweka-icons{
    
    display: flex;
    max-width: 500px;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 40px;

}


.RewekaIcon{
    width: 100%;
 
  display: flex;
  flex-direction: column;
  align-items: center;

    span{
        font-weight: 600;
        
        font-size: 14px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
       
    }
}

.reweka-icons-item{
    display: block;
   max-width: 50px;
   width: 100%;
   
   
   

}

#reweka-payback-icon{
  max-width: 50px;
  width: 100%;
}


.reweka-dropdown{
    background: #f6f6f6;
    max-width: 500px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 40px;
  border: 1px solid;

  img{
    width: 5%;
    margin-left: 2%;
  }
  #reweka-dropdown-text{
    font-size: 15px;
    font-weight: 600;
    color: #4a4a4a;
    margin-left: 3%;
  }

  #v{
    position: absolute;
    right: 3%;
  }
}


.reweka-secondContainer{

  width: 100%;
  display: flex;
  justify-content: center;
  
  margin-top: 7%;
}

.reweka-category-secondContainer{

  max-width: 1260px;
  width: 100%;
  display: flex;
 justify-content: center;
 
}


.reweka-secondContainer-category{

  max-width: 600px;
  width: 100%;
  margin: 0 1vw 0 1vw;

  display: flex;
  flex-direction: column;
  

}

.reweka-category-img{
  background-image: url('./Images/1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
   height: 100px;
  position: relative;
}

.Veg{
  background-image: url('./Images/2.jpeg');
  position: relative;
}

#reweka-worker-img{
  width: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
  
}

#reweka-veg-img{
  width: 30%;
  position: absolute;
  right: 0;
  top: 0;
}


#reweka-box-img{
  width: 25%;
  position: absolute;
  right: 25%;
  bottom: 30%;
}

.reweka-category-headText{
  color: #4a4a4a;
  font-size: 25px;
  font-weight: bold;
  margin-top: 3%;
}

.reweka-category-text{
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3%;
}


.reweka-secondContainer-category-btns{
  display: flex;
  width: 100%;
  margin-top: 8%;
}

.reweka-secondContainer-category-btn{
  border: 1px solid gray;
  width: 25%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  color: #4c4c4c;
  font-size: 15px;
  font-weight: bold;

}


.rewekaBtnFill{
  background: #007f3f;
  color: white;
  border: 1px solid #007f3f;
  margin-left: 5%;
  text-decoration: none;
}

#rewekaBtnFill2{
  margin-left: 0;
}





.reweka-thrirdContainer{
  
  width: 100%;
  height: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.reweka-container-RKJZS{

  max-width: 1260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reweka-thrirdContainer-text{
  font-size: 45px;
  font-weight: bold;
  color: #4a4a4a;
  
}

.reweka-thrirdContainer-icons{
  display: flex;
  margin-top: 3%;
  width: 50%;
  justify-content: space-between;
 
}

.reweka-thrirdContainer-icon{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
 

  img{
    width: 25%;
  }
}


.reweka-fourthContainer{
  
  width: 100%;
  margin-top: 300px;
  display: flex;
  justify-content: center;
  align-items: end;
  
}

.reweka-fourthContainer-img{
  
  display: flex;
  justify-content: center;
  img{
    width: 80%
  }
}


.reweka-Footer{
  width: 100%;
  height: 350px;
  margin-top: 7%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.reweka-footer-container{

  max-width: 1260px;
  width: 100%;
  display: flex;
  justify-content: center;
  
}

.reweka-footer-column{

  color: #4c4c4c;
  margin: 0 5% 0 0;
  display: inline-block;

  span{
    font-weight: bold;
    font-size: 17px;
  }
}

.reweka-footer-text{
  font-size: 15px;
  font-weight: 600;
  margin-top: 5%;
}


.reweka-Banner-long{
  position: absolute;
  max-width: 700px;
  width: 100%;
  height: 70%;
  background: white;
  border: 1px solid gray;
  border-radius: 10px;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  outline: 1px solid blue;
 overflow: scroll;
}

.BannerDetails{
  display: none;
}


.reweka-Banner-long-headText{
  font-weight: bold;
  font-size: 16px;
  margin: 4% 0 0 4%;
}

.reweka-Banner-long-text_btns{
  
  margin: 4% 0 0 4%;
  width: 93%;
 
  font-size: 14px;
  
  
  
}

.reweka-Banner-long-btns{
  display: flex;
  justify-content: space-evenly;

 padding-bottom: 20px;
  align-items: center;
}

.reweka-btn-options{
  color: #007f3f;
  font-weight: bold;
  cursor: pointer;
}

.reweka-btn{
  background: #007f3f;
  color: white;
  font-weight: bold;
  max-width: 150px;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.reweka-btnout{
  background: none;
  border: 1px solid #007f3f;
  color: #007f3f;
}


.reweka-details-switchers{
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 2vw;
  
}


.reweka-details-switcher{
  width: fit-content;
  margin: 1vw 0 1vw 0;
  
}

.reweka-Banner-long-details-btns{
  display: flex;
  justify-content: space-evenly;
 margin-top: 2.5vw;
  height: 3vw;
  align-items: center;
}

.reweka-details-close{
  position: absolute;
  right: 1.5vw;
  top: 0.5vw;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.reweka-switch-label{
  font-size: 14px;
}



.reweka-Banner-short{
  position: absolute;
  max-width: 700px;
  width: 100%;
  height: 65%;
  background: white;
  border: 1px solid gray;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  outline: 1px solid blue;
  overflow: scroll;

}


.reweka-banner-short-head{
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid gray;
  cursor: pointer;
  height: 20px;
  overflow: hidden;
  transition: 1s all;
  margin-top: 1vw;

}

.reweka-banner-short-head-text{

  white-space: normal;
  transition: 1s all;
  overflow: hidden;
  height: inherit;
  font-weight: normal;
  font-size: 14px;
  
  padding-bottom: 25px;
  margin-top: 1%;
}



.reweka-Banner-short-text_btns{
  margin: 4% 0 0 4%;
  width: 93%;
 
  font-size: 14px;

}

.reweka-Banner-short-btns{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid black;
  align-items: center;
  padding-top: 20px;
}



@media screen and (min-width: 1200px) and (max-width: 1600px) {

 
  .reweka-Banner-short{
 
    height: 90%;
  
  
  }
  
  
  

}


@media screen and (width: 1024px){

}



@media  (max-width: 576px){


  .Reweka-Main{
    width: 100%;
    min-height: 100vh;
    transition: 1s opacity;
    
}

.reweka-firstContainer{
   
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
}

.reweka-greeting{
    font-size: 25px;
    font-weight: bold;
    color: #4a4a4a;
   
    margin-top: 5vw;
    

}


.reweka-icons{
    
    display: flex;
    width: 90%;
    justify-content: space-evenly;
    margin-top: 5vw;

    height: 60px;
}


.RewekaIcon{
    

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

    span{
        font-weight: 600;
        width: 100%;
        font-size: 12px;
        white-space: wrap;
        display: flex;
        justify-content: center;
   
        text-align: center;
       
    }
}

.reweka-icons-item{
    display: block;
    width: 60%;
    height: 90%;
    
    
   
   

}

#reweka-payback-icon{
  width: 100%;
  height: 90%;
}


.reweka-dropdown{
    background: #f6f6f6;
    width: 85%;
    height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 13vw;

  img{
    width: 10%;
    margin-left: 2%;
  }
  #reweka-dropdown-text{
    font-size: 16px;
    font-weight: 600;
    color: #4a4a4a;
    margin-left: 3%;
  }

  #v{
    position: absolute;
    right: 3%;
  }
}


.reweka-secondContainer{

  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 0vw;
  flex-wrap: wrap;

}

.reweka-secondContainer-category{

  width: 30vw;
  margin: 10vw 1vw 0 1vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.reweka-category-img{
  background-image: url('./Images/1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vw;
  width: 90%;
  height: 100px;
  position: relative;
}

.Veg{
  background-image: url('./Images/2.jpeg');
  position: relative;
}

#reweka-worker-img{
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

#reweka-veg-img{
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
}


#reweka-box-img{
  width: 40%;
  position: absolute;
  right: 35%;
  bottom: 30%;
}

.reweka-category-container{
   
    width: 90%;
}

.reweka-category-headText{
  color: #4a4a4a;
  font-size: 22px;
  font-weight: bold;
  margin-top: 3%;
}

.reweka-category-text{
  color: #4a4a4a;
    font-size: 14px;
    font-weight: normal;
    margin-top: 3%;
    width: 95%;
}


.reweka-secondContainer-category-btns{
  display: flex;
  width: 100%;
  margin-top: 8%;
  justify-content: space-evenly;
}

.reweka-secondContainer-category-btn{
  border: 1px solid gray;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 600;

}

.rewekaBtnsContainer2{
  justify-content: start;
}

.rewekaBtnFill{
  background: #007f3f;
  color: white;
  border: 1px solid #007f3f;
  margin-left: 5%;
}

#rewekaBtnFill2{
  margin-left: 0;
}





.reweka-thrirdContainer{
  
  width: 100%;
  margin-top: 10vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.reweka-thrirdContainer-text{
  font-size: 22px;
  font-weight: bold;
  color: #4a4a4a;
  
}

.reweka-thrirdContainer-icons{
    display: flex;
    margin-top: 5%;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.reweka-thrirdContainer-icon{
  width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5%;
  border: 1px solid transparent;

  img{
    width: 25%;
  }
}


.reweka-fourthContainer{
  
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  margin-top: 15vw;
}

.reweka-fourthContainer-img{
  img{
   width: 100%;
  }
}


.reweka-Footer{
  
  width: 100%;
  height: auto;
  margin-top: 7%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.reweka-footer-column{

  margin-bottom: 15vw;
    height: 100%;
    color: #4c4c4c;
    width: 100%;
  
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

  span{
    font-weight: bold;
    font-size: 13px;
  }
}

.reweka-footer-text{
  font-size: 10px;
  font-weight: normal;
  margin-top: 5%;
}

//Banner

.reweka-Banner-long{
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid gray;
  border-radius: 0;
  left:0;
  top:50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: 1px solid black;
}


.reweka-Banner-long-headText{
  font-weight: bold;
  font-size: 16px;
  margin: 5% 0 0 0;

  display: flex;
  justify-content: center;
}

.reweka-Banner-long-text_btns{
  overflow-y: scroll;
  margin: 4% 0 0 0;
  width: 100%;
  height: 90%;
  font-size: 14px;
  

  display: flex;
    flex-direction: column;
    align-items: center;
  
}

.reweka-Banner-long-text{
  width: 90%;
}

.reweka-Banner-long-btns{
  display: flex;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.reweka-btn-options{
  color: #007f3f;
  font-weight: bold;

  cursor: pointer;
  border: 1px solid black;
  width: 75%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px; 
}

.reweka-btn{
  background: #007f3f;
  color: white;
  font-weight: bold;
  width: 75%;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5vw;
  max-width: 100%;
}

.reweka-btnout{
  background: none;
  border: 1px solid #007f3f;
  color: #007f3f;
}


.reweka-details-switchers{
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 7vw;
  
}

.reweka-details-switcher{
  width: fit-content;
  margin: 1vw 0 1vw 0;
  
}

.reweka-Banner-long-details-btns{
  display: flex;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 6vw;
}

.reweka-details-close{
  font-weight: bold;
  font-size: 23px;
  cursor: pointer;
  display: flex;
  justify-content: end;
  margin-right: 3vw;
  position: relative;
}

.reweka-switch-label{
  font-size: 14px;
}


.reweka-banner-short-head{
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid gray;
  cursor: pointer;
  height: 20px;
  overflow: hidden;
  transition: 1s all;
  margin-top: 4vw;

}

.reweka-Banner-short-textchapter{
margin-top: 10vw;
}

.reweka-banner-short-head-text{

  font-weight: normal;
  font-size: 14px;
  margin: 5% 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}



.reweka-Banner-short{
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid gray;
  border-radius: 0;
  left:0;
  top:50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: 1px solid black;
}




.reweka-Banner-short-text_btns{
  overflow-y: scroll;
  margin: 4% 0 0 0;
  width: 100%;
  height: 90%;
  font-size: 14px;
  

  display: flex;
    flex-direction: column;
    align-items: center;
}

.reweka-Banner-short-btns{
  display: flex;
  justify-content: space-evenly;
  height: auto;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 6vw;
}

}