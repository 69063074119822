


body{
    overflow-x: hidden;
}

.PrimeMain{
   background: #00050d;

   min-height: 100vh;
   width: 100%;
}

.PrimeMain_container{
   position: relative;
}


.prime-head-video-nav{
        
  
   background-color: #00050d;
   //background: linear-gradient( #00050d, #0000,);
   display: flex;
   justify-content: center;
   position: sticky;
   top: 1%;
   height: 60px;
   width: 100%;
   transition: 0.5s all;
   z-index: 2;
  

}




.gg{
   width: 100%;
    height: 695px;
}


.prime-head-video-nav-links{
   height: inherit;
   cursor: pointer;
   width: 95%;
   display: flex;
   color: white;
   justify-content: start;
   position: sticky;
   top: 0px;
   border-radius: 15px;
   align-items: center;

   backdrop-filter: blur(16px);

  border-radius: 0 0 12px 12px;
   
   
}

#primelogo{
  max-width: 95px;
  width: 100%;
   margin-left: 20px;
   display: flex;
   align-items: center;

   img{
       width: inherit;
   }
}

.prime-video-nav-links{

   font-size: 16px;
   display: flex;
   align-items: center;
   font-weight: 600;

   margin-left: 10px;
   
   img{
       height: 40px;
       width: 40px;
      
   }

   span{
       color: white;
   }
}

.prime-video-nav-links_container{

   display: flex;
   justify-content: space-between;
   width: 35%;
   margin-left: 20px;
}

.primeNavChecked{
   border-bottom: 2px solid white;
}

.primeAcc{
   position: absolute;
   right: 20px;
   width: 135px;
   display: flex;
   justify-content: space-around;
}



@media  (max-width: 1600px){

   .gg{
      
       height: 500px;
   }

   .prime-main-pic{
      height: inherit;
   }

}

