.ntv-panel{
    width: 100%;
    border-bottom: 1px solid #e1e6eb;
    padding-bottom: 20px;
    height: 145px;
}

.ntv-panel-container{
    width: 100%;
    height: 100%;
    display: flex;
}

.ntv-panel-stock-container{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
 
    
    h3{
     
        font-size: 24px;
        font-weight: bold;
        color: #46555f;
        text-transform: uppercase;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-left: 15px;
    }
}


.ntv-panel-stock-item_container{
    display: grid;
    grid-template-columns: 80px 150px;
    grid-template-rows: repeat(3, minmax(auto, 1fr));
   
  
    border-right: 1px solid #e1e6eb;
    
    height: 100%;

    div{
        display: flex;
        align-items: center;
        justify-content: space-around;
       
    }
  
}

.ntv-panel-stock-item_name{
    font-size: 15px;
    font-weight: bold;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

.ntv-panel-stock-item_value{
color: #697882;
font-size: 15px;
font-weight: 700;
font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;

span{

}
}


.ntv-panel-stock-items_container{
    display: flex;
    height: 100%;
    margin-top: 10px;
}



.ntv-panel-wetter-container{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
 
    
    h3{
     
        font-size: 24px;
        font-weight: bold;
        color: #46555f;
        text-transform: uppercase;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-left: 15px;
    }
}

.ntv-panel-wetter-item_container{
    border-right: 1px solid #e1e6eb;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex-basis: 65%;
}

.ntv-panel-wetter-items_container{
    display: flex;
    height: 100%;
    margin-top: 10px;
}


.ntv-panel-wetter-img_container{
    height: 45px;

    img{
        height: 100%;
    }
}

.ntv-panel-wetter-item-temp{
    font-size: 35px;
    color: #46555f;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

.ntv-panel-wetter-item_day{
 font-weight: bold;


}

.ntvWetterImg-container{
    flex-basis: 35%;
}

.ntv-panel-wetter-item_temp1{
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: 600;
   
    span{
        color: red;

     }
}


.ntv-panel-stock-item_container_mobile{
    display: none;
}


.ntv-panel-news{
    border-bottom: 1px solid #e1e6eb;
    height: 190px;
    max-width: 872px;
    width: 95%;
    
}

.ntv-article-panel_news-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.ntv-panel-news-headText_container{
    color: #46555f;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 15px;

    p{
        margin-block-end: 5px;
    }
}

.ntv-panel-news-date{
    color: #697882;
    font-size: 12px;
    font-weight: bold;
   margin-left: 5px;
}

.ntv-panel-news-dateBlock{

}

.ntv-panel-news-date_container{
    width: 100%;
    height: 20px;
    display: flex;
  
}

#ntv-hightlight-swiper{
    position: relative;
    width: 100%;
  
}


#ntv-hightlight-swiper::part(button-next) {
  
    position: absolute;
    top: 100%; 
    margin-top: -10px;
    color: black;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    
    //right: calc(50% - 32px - 7px - 20px);

    padding: 6px;
    box-shadow: 1px 2px 5px gray;

  }



#ntv-hightlight-swiper::part(button-prev) {
   
    position: absolute;
    top: 100%; 
    margin-top: -10px;
    height: 20px;
    width: 20px;
    color: black;
    padding: 6px;
    box-shadow: 1px 2px 5px gray;
    border-radius: 25px;

  }



#ntv-hightlight-swiper::part(pagination) {

    top: 100%;
    display: flex;
    margin-top: 5px;
    width: fit-content;
    transform: translateX(-50%);
    left: 50%;
    
  }





.ntv-hightlight-swiper-item{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.ntv-hightlight-swiper-item-news_container{
    display: flex;
   
}

.ntv-hightlight-swiper-item-header{
    color: #dc0028;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-block-start: 5px;

}

.ntv-hightlight-swiper-item-text{
    color: black;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-left: 10px;
    margin-block-start: 5px;

}

@media screen and (min-width: 1536px) and (max-width: 1919px){

    .ntv-panel-stock-item_container{
   grid-template-columns: 80px 100px;
    }

    .ntv-panel-stock-item_name{
        font-size: 14px;
       
    }
    
    .ntv-panel-stock-item_value{
        font-size: 13px;
    }

    .ntv-panel-wetter-itemDesktop{
        display: none;
     }

}


@media screen and (max-width: 1790px){

    .ntv-panel-news{
       
        height: 180px;
  
        
    }
}


@media screen and (min-width: 1320px) and (max-width: 1536px){

    .ntv-panel{
        height: auto;
    }

    .ntv-panel-container{
        flex-direction: column;
    }

    .ntv-panel-stock-item_container {
        grid-template-columns: 80px 1fr;
        width: 100%;
    }

    .ntv-panel-stock-item_name{
        font-size: 13px;
       
    }
    
    .ntv-panel-stock-item_value{
        font-size: 13px;
    }

    .ntv-panel-stock-item_value{
        span{
            display: none;
        }
    }

    .ntv-panel-wetter-container{
        margin-top: 20px;
    }

    .ntv-hightlight-swiper-item-news_container{
       flex-direction: column;

       p{
        margin-block-start: 0;
        margin-block-end: 0;
       }
       
       .ntv-hightlight-swiper-item-text{
        margin-left: 0;
       }
    }

}


@media screen and (max-width: 1319px){

    .ntv-panel{
        height: auto;
    }

    .ntv-panel-container{
        flex-direction: column;
    }

    .ntv-panel-stock-item_container {
        grid-template-columns: 80px 1fr;
        width: 100%;
    }

    .ntv-panel-stock-item_name{
        font-size: 13px;
       
    }
    
    .ntv-panel-stock-item_value{
        font-size: 13px;
    }

    .ntv-panel-stock-item_value{
        span{
            display: none;
        }
    }

    .ntv-panel-wetter-container{
        margin-top: 20px;
    }


    .ntv-hightlight-swiper-item-news_container{
        flex-direction: column;
 
        p{
         margin-block-start: 0;
         margin-block-end: 0;
        }
        
        .ntv-hightlight-swiper-item-text{
         margin-left: 0;
        }
     }


     #ntv-hightlight-swiper::part(pagination) {

        height: 30%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 25px;

      }


      #ntv-hightlight-swiper::part(button-next) {
  
        margin-top: 10px;
       
      }
    
    
    
    #ntv-hightlight-swiper::part(button-prev) {
        
        margin-top: 10px;
    
      }

}


@media  (max-width: 1100px){
    #ntv-hightlight-swiper::part(pagination) {

        height: 30%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0px;

      }


      #ntv-hightlight-swiper::part(button-next) {
  
        margin-top: -10px;
       
      }
    
    
    
    #ntv-hightlight-swiper::part(button-prev) {
        
        margin-top: -10px;
    
      }
}


@media  (max-width: 576px){

    .ntv-panel-stock-container{
   
        
        h3{
         
            padding-left: 15px;
            background: #AAB9C3;
            font-size: 15px;
            height: 35px;
            display: flex;
            align-items: center;
            margin-left: 0;
            font-weight: bold;
            color: #46555f;
            text-transform: uppercase;
            margin-block-start: 0px;
            margin-block-end: 0px;
       
        }
    }

    .ntv-panel-wetter-container{
        display: none;
    }

    .ntv-panel{
        margin-top: 10px;
    }


    .ntv-panel-stock-item_container{
        display: none;
    }

    .ntv-panel-stock-item_container_mobile{
    
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(2, minmax(auto, 1fr));
        width: 100%;
        height: 100%;
        align-items: center;
    
        div{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
           
        }
    }

    .ntv-panel-stock-item_value{

        span{
            display: block;
        }
    } 

    .ntv-panel-stock-item_name{
        font-size: 11px;
    }

    .ntv-panel-stock-item_value{
        font-size: 11px;
    }


    #ntv-hightlight-swiper::part(pagination) {

        top: 100%;
        display: flex;
        margin-top: 5px;
        width: fit-content;
        transform: translateX(0%);
        left: 0%;

      }

      #ntv-hightlight-swiper::part(button-next) {
  
        display: none;
    
      }
    
    
    
    #ntv-hightlight-swiper::part(button-prev) {
       
        display: none;
    
      }
}