body{
   // background: #f4f2ee;
}

.LinkedBody{
 
   display: flex;
   justify-content: space-around;
   max-width: 1200px;
    margin: 30px auto auto auto;
    min-height: 100vh;

}



.LinkedFeed{
   
    width: 555px;
    height: auto; //auto
    border-radius: 5px;
    background: white;
    box-shadow: 0px 0px 2px 0px gray;
    margin-bottom: 10px;

 
}


.FeedHead{
 
    background: white;
    height: 120px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}


.FeedHeadShareBoxEntry{
    
    color: black;
    height: 50%;
    display: flex;
    align-items: center;
    
}

.FeedHeAudi1AdImg{
    margin-left: 10px;
}

.FeedHeadTextField{
    border: 1px solid gray;
    height: 30px;
    width: 450px;
    margin-left: 15px;
    border-radius: 20px;
    font-size: 14px;
    color: gray;
    font-weight: 500;
    padding: 10px 0 0 10px;
}


.FeedHeadShareBox{
 
    height: 50%;
    display: flex;
    justify-content: space-around;
 
    img{
        width: 21px;
        height: 21px;
    }

    span{
        margin-left: 10px;
    }
}

.FeedHeadIcon{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 500;
    
    
    
}

.LinkedNews, .LinkedProfile{

    width: 280px;
    border-radius: 5px;
}

.LinkedProfile{
    background: white;
    width: 224px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 2px 0px gray;
    border: none;
    
}

.LinkedProfileDiv{
    width: 100%;

    img{
        width: 100%;
    }
}

.ProfileText{
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}

.ProfileLine{
    border: none;
    height: 1px;
    background: gray; 
    margin-top: 30px;
}

.ProfileText2{
    margin: 20px 0 0 10px;
    font-size: 12px;
    color: gray;
    position: relative;

    span{
        display: block;
        font-weight: bold;
        color: black;
        
    }
}

.ProfileElemente{
    margin: 20px 0 0 5px;
    font-weight: bold;
    font-size: 11px;
   
    display: flex;
    align-items: center;
}


.LinkedProfile2{
box-shadow: 0px 0px 2px 0px gray;
margin-top: 10px;
border-radius: 5px;
background: white;
position: relative;
}

.LinkedProfile2Text{
    font-size: 12px;
    color: #0a66c2;
    font-weight: bold;
    margin: 15px 0 10px 5px;
    
}

.LinkedProfile2Add{
    position: absolute;
    right: 10px;
    top: 30%;
    transform: translateY(-30%);
}


#rk800{
    color: gray;
font-size: 14px;
font-weight: 500;
text-align: center;
padding: 10px;
}

.Special{
    margin-top: 15px;
}

.NewsBlock1{
    background: white;
    border-radius:5px;
    box-shadow: 0px 0px 2px 0px gray;
}


.NewsBlock1Head{
    font-weight: 600;
    font-size: 15px;
    padding: 10px 0 0 10px;
}

.NewsBlock1List{
    font-size: 14px;
    font-weight: 500;
    line-height: 45px;
    margin-left: -12px;
    list-style-type: circle;
   

    li{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

}


#rx220{
    color: gray;
    font-size: 14px;
    font-weight: 500;
   position: relative;
    padding: 10px 0 10px 30px;
}

.NewsBlockImpressum{
    margin-top: 10px;
    color: gray;


    ul{
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        justify-content: center;
        padding: 0;
        font-size: 12px;


        li{
            margin: 5px;
        }
    }
}


#ax400{
    margin: 20px 0 20px 0;
    width: 50%;
    margin-right: 20px;

    border: none;
    height: 1px;
    background: #b9b9b9;
   

}
//{display:'flex', alignItems:'center', fontSize:12
#ax300{
display: flex;
align-items: center;
font-size: 12px;
color: gray;

span{
    font-weight: 600;
    color: black;
    margin-left: 5px;
}
}


.LinkedFeedHead{
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    height: 15px;
}

.FeedLogo{
 
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;

  span{
    margin-left: 5px;
    font-weight: 600;
    display: flex;
  }

  img{
    width: 25px;
  }
}

.FeedName{
    
}
.FeedMore{
    margin-top: 10px;
    position: absolute;
  right: 5px;
}
.FeedClose{
   
}

.FeedBody{
   
    height: auto;
}

.FeedBodyHead{
   
    width: 100%;
    height: 15%;
    display: flex;
    cursor: pointer;
}

.Icon{
   
    height: 100%;
    width: 53px;

    img{
        height: inherit;
        width: inherit;
        object-fit: contain;
    }
}

.IconandName{
  
    width: 70%;
    display: flex;
    margin-left: 10px;
  
}

.FeedBodyMainText{
    margin-left: 15px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 95%;

    p{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
    }
   
    span{
        color: gray;
        cursor: pointer;
    }

    span:hover{
        color: blue;
        text-decoration: underline;
    }

}

.FeedHeadTextHead{
    font-weight: 600;
  font-size: 13px;
  margin-top: 5px;

  p{
    margin-block-start: 0;
    margin-block-end: 0;
  }


}

.FeedHeadTextText{
    font-size: 11px;
    color: gray;  
}

.FeedHeadText{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.FeedFollow{
    
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 10px;
    color: #0a66c2;
    font-weight: 600;
    font-size: 14px;
   
    margin-top: 17px;

    span{
        font-size: 25px;
  padding-bottom: 5px;
    }
}


.FeedLine{
    border: none;
  height: 1px;
  background: #b9b9b9;
  width: 90%;
}

.FeedBodyTextandPic{
    margin-top: 5px;

  
  

}

.FeedBodyTextandPicGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px;
    
}

.FeedBodyMainPic{
  
    height: auto;
    margin-top: 12px;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
    }
}


.FeedBodyMainPicGrid{
  
    //height: auto;
   
   
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
}

.Three{
    grid-column: span 2;
}

.FeedBodyMainPicGridThreePic{
    grid-column: span 2;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
}

.FeedFooter{
 
    display: flex;
  justify-content: space-around;
  cursor: pointer;
}


.FooterIconDiv{
    width: 20%;
    font-size: 13px;
    color: #666666;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0 20px 0;

}

.FooterIcon{
    width: 22%;
   
    
}

.LineFeedBody{
    margin-top: 10px;
}


.Linked-Like-Comment{
    display: flex;
  justify-content: space-between;
}

.Linked-Like{
    
   
    width: 50px;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin:5px 0 0 10px;

  img{
    width: 38%;
  }

  span{
    color: #666666;
    margin-left: 3px;
  }
}

.Linked-Comment{
   
  font-size: 12px;
  color: #666;
  margin:5px 10px 0 0;
}


.LinkedAd{
    margin-top: 15px;
}


.FooterIconDivMobil{
    display: none;
}

.FooterIconMobil{
    display: none;
  
}

.LinkedFooterMobile{
    display: none;
}


#adspan{
    white-space: pre;
}

.linked-carousel_container{
    width: 100%;
    //height: 600px;
    position: relative;

}

.feed-carousel-slide{
    height: 100%;
    width: 100%;
    position: relative;
}

.feed-carousel-slide-item{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


#linked-carousel::part(button-prev) {
    color: #fff;
    mix-blend-mode: difference;
       
}
#linked-carousel::part(button-next) {
    color: #fff;
    mix-blend-mode: difference;
       
}

.feed-linked-hpa_container{
    height: 600px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    video{
        width: 100%;
    }
}
  

@media  (max-width: 1024px){
    .LinkedProfile{
     
        width: 190px;
    }

    .LinkedProfile2{
        width: 190px;
    }
    
    .FeedBlock{
        width: 500px;
    }

    .LinkedFeed{
        width: 100%;
    }

    .LinkedNews{
        width: 250px;
    }
}


@media  (max-width: 480px){

    #linked-carousel::part(button-prev) {
      display:none
           
    }
    #linked-carousel::part(button-next) {
   display: none;
           
    }

    .FeedBlock{
        width: 100%;
    }

    .FeedHead{
        display: none;
    }

    #adspan{
        white-space: inherit;
    }

    .LinkedProfileBody{
        display: none;
    }

    .LinkedNews{
       display: none;
    }

    #ax300{
        display: none;
    }

    .LinkedBody{
 
    
         margin: 0px auto auto auto;
        
     
     }

     .LinkedFeed{
        border-radius: 0px;
     }
     
     .FeedLogo{
        display: none;
      }
      
      .FeedMore{
         //display: none;
      }
     
     .FeedLine{
       width: 100%;
      
     }


     .FooterIconDiv{
      
        display: none;
    }

    .FooterIcon{
       
        display: none;
        
    }

    .FooterIconDivMobil{
        width: 30%;
        font-size: 13px;
        color: #666666;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px 0 20px 0;
       
    }

    .FooterIconMobil{
        width: 22%;
        display: block;
    }

    .LinkedFooterMobile{
        width: 100%;
        background-color: white;
        height: 60px;
        position: fixed;
        bottom: 0px;
        display: flex;
        transition: 0.5s all;
        z-index: 2;
    }


    .FeedBodyMainText{
        width: 95%;
        margin-left: 7px;
        margin-top: 20px;
    }

    .FeedFollow{

       // display: none;
        span{
           
            margin-right: 5px;
        }
    }

}

