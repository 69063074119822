.AdcheckHead-landingGrill{
    width: 100%;
    height: 80px;
    background:#f1ece0;
    display: flex;
    position: relative;
    align-items: center;
    
}

.HeadLogo-landingGrill{
    width: 65px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
   

  
    img{
        max-width: 230px;
        width: 100%;
        height: 50px;
       
    }
}

.AdcheckHead-landingGrill-ctabtn{
  border-radius: 5px;
    max-width: 110px;
    width: 100%;
    height: 40px;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    background:#e42f13;
    color: white;
    
}

// Smile head landingpage

.AdcheckHead-landingSmile{
    width: 100%;
    height: 80px;
    background:white;
    display: flex;
    position: relative;
    align-items: center;
}

.HeadLogo-landingSmile{
    width: 65px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
   

  
    div{
       
        font-weight: bold;
       
        font-size: 20px;
       
    }
}

.AdcheckHead-landingSmile-ctabtn{
  border-radius: 5px;
    max-width: 110px;
    width: 100%;
    height: 40px;
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    background:#DCE5E9;
    color: black;
    
}