@media screen and (min-width: 2144px){

        .chef-container_body{
   
                justify-content: center !important;
            
            
        }

        .chef-ad-sidebar-main_container{
                margin-right: 20px;
        }
}


.chef-container{
 background: white;   
}

.chef-container_body{
   
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
    
    
}

.chef-article_container{
max-width: 1015px;
width: 100%;
background: white;

}


 

@media screen and (min-width: 1600px){

 
   
}



@media screen and (min-width: 1536px) and (max-width: 1919px){





}

@media screen and (min-width: 1320px) and (max-width: 1536px){
        .chef-article_container{
                max-width: 875px;
                width: 100%;
                background: white;
                
                }
                
    
}


@media screen and (max-width: 1319px){

        .chef-article_container{
                max-width: 1000px;
                width: 100%;
                background: white;
                
                }
                
  

}

@media screen and (max-width: 1280px){

        .chef-article_container{
                max-width: 850px;
                width: 100%;
                background: white;
                
                }
                
  

}
@media screen and (max-width: 1100px){

        .chef-article_container{
                max-width: 700px;
                width: 100%;
                background: white;
                
                }
                
  

}

.ntv-ad-mca-main_container{
        z-index: 1;
}


@media  (max-width: 576px){
   


}
