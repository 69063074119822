@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.ntv-head{
    width: 100%;   
    background: #2d3c46;
    height: 85px;
   }

.ntv-head_container{
    
    width: 100%;
    height: 100%;

    display: flex;
}


.ntv-head-logo-container{
    height: 100%;
    width: 136px;

    img{
        width: inherit;
        height: inherit;
    }
}

.ntv-head-nav-container{
  
    max-width: 85%;
    display: flex;
    align-items: end;
    
}

.ntv-head-nav-list{
    display: flex;
    list-style: none;
    color: white;
    text-transform: uppercase;
    padding-inline-start: 15px;
}

.ntv-head-nav-list-item{
    padding: 0px 15px;
    font-size: 17px;
    font-family: IBM Plex Sans, Arial, Helvetica, sans-serif;
    font-weight: 700;
    position: relative;

}

#ntv-live{
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
}

.ntv-head-nav-action-container{
    
    display: flex;
    max-width: 15%;
    margin-left: auto;
    align-items: end;
    margin-right: 10px;

}


.ntv-head-nav-action-icon_container{
    padding: 0 10px;
}

.ntv-mobile-hamburger_container{
    display: none;
}

.ntv-head-nav-desktop_nav{
    display: flex;
}

.ntv-head-nav-action-mobile_container{
    display: none;
}



@media screen and (min-width: 1536px) and (max-width: 1919px){

    .ntv-head{

        height: 60px;

       }

       .ntv-head-logo-container{
        
        width: 96px;
    
    }

    .ntv-head-nav{
        height: 100%;
        display: flex;
        align-items: end;
    }

    .ntv-head-nav-list{
        margin-block-end: 10px;
        padding-inline-start: 0px;
    }

    .ntv-head-nav-list-item{
        font-size: 12px;
    }

  
  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

    .ntv-head{

        height: 60px;

       }

       .ntv-head-logo-container{
        
        width: 96px;
    
    }

    .ntv-head-nav{
        height: 100%;
        display: flex;
        align-items: end;
    }

    .ntv-head-nav-list{
        margin-block-end: 10px;
    }

    .ntv-head-nav-list-item{
        font-size: 12px;
    }

    .ntv-head-nav-action-icon_container{
        padding: 0 5px;
    }

}



@media screen and (max-width: 1319px){

    .ntv-mobile-hamburger_container{
        display: block;
    }

    .ntv-ad-billboard-main_container{
        margin: 20px 0px !important;
    }

    .ntv-head{

        height: 60px;

       }

       .ntv-head-logo-container{
        
        width: 96px;
    
    }

    .ntv-head-nav{
        height: 100%;
        display: flex;
        align-items: end;
    }

    .ntv-head-nav-list{
        margin-block-end: 10px;
    }

    .ntv-head-nav-list-item{
        font-size: 12px;
    }

    .ntv-head-nav-action-container{
        max-width: none;
    }

    .ntv-head-nav-action-icon_container{
            svg{
                width: 12px;
                height: 12px;
            }
    }

    .ntv-head-nav-container{
  
      
        display: none;
        
    }


    .ntv-head-nav-action-mobile_container{
        display: flex;
        align-items: center;
      
        font-size: 12px;
        font-family: IBM Plex Sans, Arial, Helvetica, sans-serif;
        font-weight: 700;
        position: relative;
    }

}




@media  (max-width: 576px){


    .ntv-head-nav-action-icon_container{
        svg{
            width: 20px;
            height: 20px;
        }
}

    .ntv-mobile-hamburger_container{
        display: block;
    }

    .ntv-head-nav-container{
  
      
        display: none;
        
    }


    .ntv-head{
        width: 100%;
        height: 40px;   
        background: #2d3c46;
       }



       .ntv-head-logo-container{
        height: 100%;
        width: 64px;
    }


   
    
    .ntv-head-nav-list-item{
        padding: 0px 15px;
        font-size: 12px;
      // padding-inline-start: 0px;
        font-weight: 700;
        position: relative;
    
    }


    .ntv-head-nav-action-container{
    
        display: flex;
        max-width: none;
        margin-right: 0;
        align-items: center;
        
    
    }

    .ntv-head-nav-action-mobile_container{
        display: flex;
        align-items: center;
      
        font-size: 12px;
        font-family: IBM Plex Sans, Arial, Helvetica, sans-serif;
        font-weight: 700;
        position: relative;
    }


    }