

.adcheck-container-headText{
   
 

    .adcheck-headText-category{
        font-size: 1.1em;
        font-weight: bold;
        margin: 10px 0 15px 0;

        span{
        display: block;
        font-weight: 400;
        }
    }

    .adcheck-headText-header{
        font-size: 1.4em;
        font-weight: bold;

        span{
        display: block;
        font-weight: 400;
        font-size: 0.8em;
        }
    }
}


.adcheck-headText-header_date{
    color: gray;
    margin-top: 10px;
}

    .adcheck-container-mainPic{
        position: relative;
    
        max-width: 940px;
        width: 100%;
        margin-top: 25px;
        img{
            width: 100%;
        }
    }

    .adcheck-maiPic-adLabel{
        position: absolute;
        top: -6px;
        left: -5px;
        background: #FFC000;
        color: white;
        font-weight: bold;
        font-size: 1.2em;
        padding: 0 15px 0 15px;
        text-transform: uppercase;
        

    }

    .adcheck-container-textcontainer{
     
        max-width: 910px;
        width: 100%;
        
        .adcheck-textcontainer-text{
            width: 100%;
            font-size: 0.9em;
        }
    }


    .adcheck-textcontainer-textitem{
      
   
        width: 100%;
    }   


    .adcheck-breadcrumb-container{
        margin-top: 20px;
        height: 40px;
        display: flex;
        align-items: center;
       
        
    }

    .adcheck-breadcrumb_img-container{
       
        max-width: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        
        align-items: center;

        img{
            width: 65%;
        }
    }
    .adcheck-breadcrumb_text-container{
       
       
        white-space: nowrap;
        overflow: scroll;
        font-size: 14px;
        word-spacing: 2px;
       
        height: 100%;
        display: flex;
        align-items: center;
        scrollbar-color: transparent transparent;
        
    }
    
    

    .adcheck-textitem-head{
        font-weight: bold;
        font-size: 1.2em;
        margin-top: 15px;
    }

    .adcheck-textitem-text{
        font-size: 1.05em;
        margin-top: 15px;
    }


    .adcheck-textcontainer-ad{
       
        width: 100%;
        text-align: center;

        img{
            width: 30%;
        }

    }

    .adcheck-social-links{
    
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        
       
    }

    .adcheck-social-links-icon-container{
    
        flex-basis: 25%;
      
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 30%;
        }
    }
    
    #adcheck-social-links-icon_twitter{
        width: 25%;
    }

    .adcheck-main_3artikel{
        margin-top: 40px;
    }

    .adcheck-main_3artikel_headText{
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 16px;
    }

    .adcheck-main_3artikel-container{
      
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        line-height: 18px;
        
    }

    .adcheck-main_3artikel_img-container{

        width: 50%;

        img{
            width: 100%;
        }
    }

    .adcheck-main_3artikel_text-head{
        color: #bd2109;
        font-weight: 600;
        font-size: 14px;
    }

    .adcheck-main_3artikel_text-container{
       
        width: 55%;
        margin-left: 20px;

    }

    .adcheck-main_3artikel_text{
        font-size: 13px;
        font-weight: 500;
    }

    //Footer

    .adcheck-footer{
        background: #F5F5F6;
        width: 100%;
        height: 100px;
     
        position: relative;
       
    }

        
.adcheck-footer-text{
    font-size: 0.95em;
 
    width: 100%;
    max-width: 80%;
    position: absolute;
    
    left: 50%;
    transform: translateX(-50%);
    padding-top: 15px;

    span{
        color: #0000EE;
        display: block;
        margin-top: 15px;

    }
}


//Footer landing Grill

.adcheck-footer-landGrill{
    background: white;
    width: 100%;
    height: 180px;

}

.adcheck-footertext-container-landGrill{
    
    height: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #912b1b;
    align-items: center;
    line-height: 50px;
    font-weight: bold;
    font-size: 21px;
}

.adcheck-footertext-block-landGrill{
    background: #912b1b;
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 8px 1px gray;
}


//advertorial Head

.advr-head{
    background: #F5F5F6;
    position: relative;
    height: 50px;
}

.adcheck-advr-head-logo{

    height: inherit;
    max-width: 65px;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0;

    img{
        width: 100%;
        height: 100%;
    }
}

.adcheck-list-header{
    font-weight: bold;
    font-size: 1.2em;
}

.adcheck-textcontainer-list{
    line-height: 2;
    font-size: 1.05em;
}

// Grill Landingpage

.AdcheckBody-landing-MainPic{

    
    width: 100%;
   // max-width: 1980px;
    background: #F4694D;
    height: auto
    

    img{
        max-width: 200px;
        width: 100%;
        margin-top: 10px;
    }
}


@media  (max-width: 576px){

    //Body
    .adcheck-main-container{
     
        min-height: 100vh;
        max-width: 95%;
        width: 100%;
    }

    .adcheck-container-mainPic{
        
        width: 100%;
        img{
            width: 100%;
        }
    }

    .adcheck-textcontainer-ad{
   
        width: 100%;
        

        img{
            width: 100%;
            max-width: 320px;
        }

    }

    #nativeadgrill_std{
        width: 100%;
        max-width: 320px;
    }

    #nativecontainergrill_nat{
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        display: flex;
        justify-content: center;
        padding: 15px 0 50px 0;
    }

    #adcheck-adTextContainergrill_nat{
       
        height: 130px;
        text-align: left;
    }

    .adcheck-adTextContainer-Header{
        font-weight: bold;
        font-size: 1em;
    }

    .adcheck-adTextContainer-Text{
        font-size: 1.05em;
        margin-top: 15px;
    }

    .nativeadnat{
        width: 85%;
    }

    #adcheck-adTextContainergrill_std{
        display: none;
    }

    #adcheck-adTextContainerno_ad{
        display: none;
    }

    .nativeadLabelnat{
        text-align: right;
        color: gray;
        padding-bottom: 5px;
    }

    .nativeadLabelstd{
        display: none;
    }

    .nativeadLabel{
        display: none;
    }

    .adcheck-textcontainer-ad-konkurenz{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .konkurenzLabel{
        display: flex;
        justify-content: end;
        padding-bottom: 5px; 
        color: gray;
    }

    .konkurenzLabelno_ad{
        display: none;
    }
  

   //Footer 
    .adcheck-footer{
        background: #F5F5F6;
        width: 100%;
        height: 100px;
     
        position: relative;
       
    }


    
.adcheck-footer-text{
    font-size: 0.95em;
   position: relative;
    max-width: 95%;
    padding: 15px 10px 0px 10px;

    span{
        color: #0000EE;
        display: block;

    }
}


//advertorial Head

.advr-head{
    background: #F5F5F6;
   
    height: 50px;
}


// Grill Landingpage

.AdcheckBody-grill-landing{
    width: 100%;
    min-height: 100vh;
   background: #F1ECE0;
  

}

.AdcheckBody-landing-MainPic-container{
    
 
    width: 100%;
    background: #F4694D;
    height: 440px;
   

    //clip-path: polygon(0 calc(2px + 17.6327vw),100% 2px,100% calc(100% - 17.6327vw - 2px),0 calc(100% - 2px));
    clip-path: polygon(0 5%, 100% 16%, 100% 95%, 0 85%);
   
}

.AdcheckBody-landing-MainPic{

    width: 100%;
  background: #F4694D;
  height: auto;
  display: flex;
  justify-content: center;

   
   

    img{
        max-width: 200px;
        width: 100%;
        margin-top: 70px;
        height: 300px;
    }
}

.AdcheckBody-landing-Descr{
    background:#F1ECE0;
    display: flex;
    justify-content: center;
   // height: 400px;
}

.landing-Descr-container{
    //text-align: center;
    width: 90%;
    //height: inherit;
    
}

#AdcheckBody-landing-second-MainPic{
    height: 440px;
    width: 100%;
    margin-top: 0;
    max-width: none;
    clip-path: polygon(0 5%, 100% 16%, 100% 95%, 0 85%);
    object-fit: cover;
}

.Headgrilllanding{
    color: #912b1b;
    //text-align: center;
}


.Textgrilllanding{
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0 40px 0;
}

.Descr-container-list{
line-height: 25px;
font-size: 15px;
margin: 40px 0 40px 0;
}



// Zahn Body

#nativecontainerzahn_nat{
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    display: flex;
    justify-content: center;
    padding: 15px 0 50px 0;
    text-align: left;
}


 #adcheck-adTextContainerzahn_std{
 display: none;
 }



 // Smile Landingpage


.AdcheckBody-smile-landing{
    width: 100%;
    min-height: 100vh;
   background: white;

  

}

.AdcheckBodySmile-landing-MainPic-container{
    
 
    width: 100%;
    background: #DCE5E9;
    height: 440px;
   margin-top: 20px;
   // clip-path: polygon(0 5%, 100% 16%, 100% 95%, 0 85%);
    clip-path: polygon(0px 0%, 100% 0%, 100% 95%, 0 85%);   


}

.AdcheckBodySmile-landing-MainPic{

    width: 100%;
  background: #DCE5E9;
  height: auto;
  display: flex;
  justify-content: center;

   
   

    img{
        max-width: 200px;
        width: 100%;
        margin-top: 70px;
        height: 300px;
    }
}

.AdcheckBodySmile-landing-Descr{
    background:white;
    display: flex;
    justify-content: center;
   // height: 400px;
   color: black;
}

.Headsmilelanding{
    color: #5d7fa1;
    //text-align: center;
    //5d7fa1
}


//Footer landing Smile

.adcheck-footer-landSmile{
    background: white;
    width: 100%;
    height: 180px;

}

.adcheck-footertext-block-landSmile{
    background: #DCE5E9;
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 8px 1px gray;
}


.adcheck-footertext-container-landSmile{
    
    height: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    align-items: center;
    line-height: 50px;
    font-weight: bold;
    font-size: 21px;
}


.AdcheckBodySmile-landing-MainPic-smile{

    width: 100%;
  background: #DCE5E9;
  height: 440px;
  display: flex;
  justify-content: center;

   
   

    img{
        width: 100%;
        height: inherit;
        object-fit: cover;
        object-position: 30% 30%;
        
    }
}

#AdcheckBodySmile-landing-MainPic-first{
    object-position: 55% 30% !important;
}

}

#nonklickad{
    width: 100%;
    max-width: 320px;
}

#nonklickadsmile{
    width: 100%;
    max-width: 320px;
}

.konkurenzAd{
    text-align: center;
  width: 100%;

  img{
    width: 100%;
    max-width: 320px;
  }
}
