.tiktok_head{

    height: 50px;
    width: 100%;
    position: absolute;
    top: 0px;
    pointer-events: none;
    z-index: 2;
    transition: 0.5s all;
}

.tiktok_head-container{
    
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.tiktok_head-icon_live-container{
    max-width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
   
    
    

    img{
        width: 100%;
        filter: invert(1) brightness(2) drop-shadow(0px 0px 3px black);

    }
}

.tiktok_head-switch-container{
   
    display: flex;
    max-width: 150px;
    width: 100%;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    color: white;
    text-shadow: 0px 0px 3px black
    
}

.tiktok_head-icon_search-container{
 
    margin-right: 15px;
}
