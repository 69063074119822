// Head

.AdcheckHead{
    width: 100%;
    height: 80px;
    background: #c0392b;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 3;
    
}

.HeadLogo{
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    

  
    img{
        max-width: 230px;
        width: 100%;
        height: 50px;
       
    }

}

//Body

.AdcheckBody{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;


}

.gos-linkItem-container{
    width: 65%;
  display: flex;
  justify-content: space-evenly;
  }


.adcheck-main-container{

    min-height: 100vh;
    max-width: 65%;
    width: 100%;

}


@media  (max-width: 576px){

    .AdcheckHead{
        width: 100%;
        height: 80px;
        background: #c0392b;
        display: block;
        position: sticky;
        top: 0;
        z-index: 3;
        
    }

    
    .adcheck-main-container{

        min-height: 100vh;
        max-width: 90%;
        width: 100%;
    }

    .gos-linkItem-container{
        width: 100%;
  flex-direction: column;
  align-items: center;
      }

}