.tv_etv_index-container{
  
    width: 100%;
    height: 100%;
}

.tv_etv-launchBtn-container{
    background: black;
    height: 100%;
    display: flex; //flex
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;

    width: 100%;
    

}

.tv_etv-launchBtn-btn-text-container{
    max-width: 600px;
    width: 100%;
    max-height: 450px;
    height: 100%;
   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tv_etv-launchBtn-btn-container{
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5), inset 0px 3px 3px rgba(255, 255, 255, 0.2);
    background: #2d2d30;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all;

    img{
        width: 70%;
        height: 70%;
       
    }

}

.tv_etv-launchBtn-btn-container:hover{
  
    background: #65656b;
}

.tv_etv-launchBtn-text-container{
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.tv_etv_content-container{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.tv_etv_content_grid{
   
    width: 100%;
    height: 100%;
    display: grid;

    grid-template-rows: 70% 30% 
    
}
.tv-etv-click-contntent-container{
    display: flex;
    width: 100%;
    height: 100%;
    
    align-items: center;

}

.tv-etv-container-layout{
    width: 100%;
  display: flex;
  height: 85%;
 
  justify-content: space-evenly;
}

.tv-etv-main-contntent{
   
    grid-row: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}





.tv-etv-click-contntent{

display: flex;
justify-content: center;
align-items: center;
border: 1px solid;
flex-basis: 20%;
video{
    width: 100%;
    height: 100%;
    
}
}




.tv-etv-main-contntent-video{
    position: relative;
    width: 60%;
  height: 100%;

  
}
.tv-etv-click-contntent-video{
    width: 95%;
 
  height: 100%;

  
}

.tv-tv-click-contntent-video{
    width: 95%;
  height: 100%;

  
  video{
    object-fit: cover;
  }
}



//Spot


.tv_etv_spot_content-container{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    

}

.tv_etv_spot_video-container{
   
    width: 100%; //80 //100
    height: 100%; //80 //100
    float: right;
    transition: 0.5s all ease-in-out;
    z-index: 1;

    video{
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        //object-fit: cover;
    }
}
.tv_etv_spot_videoad-container{
    position: absolute;
    top: 0;
    right: 0;
    width: inherit;
    height: inherit;
    transition: 1s all;
    z-index: 2;
    display: none;

    video{
        width: 100%;
        height: 100%;
        //object-fit: cover;
    }
}

.tv_etv_spot_side-bumper{
    
    width: 0%; //20  /0
    height: 100%;
    position: absolute;
    transition: 0.5s all ease-in-out;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
        
    }
}
.tv_etv_spot_foot-bumper{
   
    width: 100%; //80 //100
    height: 0%; //20 /0
    position: absolute;
    bottom: 0;
    right: 0;
    transition: 0.5s all ease-in-out;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
    }
}

.tv_etv_spot_side-bumper_text{
   
    color: black;
    font-size: 26px;
    z-index: 2;
    margin-left: 15%;
    margin-top: 20px;
    font-weight: 500;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
  

    
}

.tv_etv_spot_side-bumper_ad-contdown{
    position: absolute;
    font-size: 80px;
    z-index: 2;
    right: 5%;
    font-weight:bold;
    opacity: 0;
    top: 115px;

}

//BlackScreen


.tv_etv_spot-blackscreen_container{
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
}

.tv_etv_blackscreen{
    background: black;
    width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: end;
}

.tv_etv_blackscreen-kanalInfo-container{
    background: gray;
    margin-bottom: 2%;
    max-width: 850px;
    width: 100%;
    height: 140px;
    display: grid;
    grid-template-columns: repeat(3, minmax(20%, 1fr));
    grid-template-rows: 70% 30%;
    visibility: visible;
    opacity: 0;
    transition: 0.5s all;
}

.tv_etv_blackscreen-kanalInfo-kanalName-container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid white;
    
    
}

.tv_etv_blackscreen-kanalInfo-kanalName{
    background: black;
    display: flex;
    flex-direction: column;
    color: rgb(20, 102, 255);
    font-size: 19px;
    font-weight: 600;
    padding-left: 3%;
    grid-row: 1;
    width: 85%;
    height: 65%;
    line-height: 27px;
    display: flex;
    justify-content: center;

    span{
        font-size: 27px;
        font-weight: bold;
    }
    
}

.tv_etv_blackscreen-kanalInfo{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, minmax(20%, 1fr));
    align-items: center;
    color: white;
    border-bottom: 1px solid white;
}

.tv_etv_blackscreen-kanalInfo-Tv_name{
    grid-area: 1 / 1 / 2 / 3;
    font-weight: 500;
    font-size: 16px;
   
    

}

.tv_etv_blackscreen-kanalInfo-time{
    font-weight: 500;
    font-size: 16px;
    grid-column: 1;
    grid-row: 2;
}

.tv_etv_blackscreen-kanalInfo-now{
    font-weight: 500;
    font-size: 13px;
    grid-column: 1;
    grid-row: 3;
}

.tv_etv_blackscreen-kanalInfo-timeline-container{
    font-weight: 500;
    font-size: 13px;
    grid-column: 2;
    grid-row: 2;
}

.tv_etv_blackscreen-kanalInfo-info{
  
    font-size: 14px;
    grid-column: 2;
    grid-row: 3;
    display: flex;
    align-items: center;
    
    span{
        margin-left: 3%;
    }
}

.tv_etv_blackscreen-kanalInfo-timeline{
    width: 70%;
    height: 4px;
    background: white;
    position: relative;
}

.tv_etv_blackscreen-kanalInfo-timeline-time{
    position: absolute;
    top: 0;
    width: 40%;
    height: inherit;
    background: rgb(20, 102, 255);
}

.tv_etv_blackscreen-kanalInfo-infobtn{
    width: 25px;
    height: 15px;
    background: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.tv_etv_blackscreen-kanalInfo-settings{
    display: grid;
    border-bottom: 1px solid white;
    grid-template-rows: repeat(3, minmax(20%, 1fr));
    align-items: center;
    color: white;
}

.tv_etv_blackscreen-kanalInfo-settings-time{
display: flex;
justify-content: end;
font-size: 19px;
font-weight: 700;
}

.tv_etv_blackscreen-kanalInfo-settings-channel{
    display: flex;
justify-content: center;
font-size: 14px;
font-weight: 500;
}

.tv_etv_blackscreen-kanalInfo-settings-catagory{
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
font-weight: 500;


.tv_etv_blackscreen-kanalInfo-settings-blue{
    width: 25px;
    height: 15px;
    background: rgb(20, 102, 255);
}

span{
   margin-left: 5px;
}

}

// loader

.tv_video_loader{
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
  
  }



  // tv


  .tv-tv-main-contntent{
   
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: end;


    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

  .tv_content{
   
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}

.tv_head-text{
    color: white;
    font-size: 27px;
    font-weight: bold;
}


.tv-tv-click-contntent{

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    flex-basis: 20%;
    max-height: 210px;
    height: 100%;
    
    video{
        width: 100%;
        height: 100%;
        
    }
    }


    //bumper rtl
    .rtl-sidebumper_container{
        background: linear-gradient(145deg, white 55%, black 55%);
        width: 100%;
        height: 100%;
    }

    .rtl-bottombumper_container{
        width: 100%;
        height: 100%;
        background: linear-gradient(230deg, black 52%, white 52%, white 53%, black 53%);
    
    }

   
    .rtl-bottombumper-text_container{
       
      

        color: white;
        font-size: 35px;
        position: absolute;
        left: 0px;
        font-weight: 500;
    
      
        p{
            margin-block-start: 0;
            margin-block-end: 0;
        }

    }
    
    .rtl-bottombumper-logo_container{
     
        position: absolute;
        height: 100%;
        right: 30px;
        display: flex;
        color: white;
        align-items: center;

    }

    .rtl-bottombumper-logo_item{
        width: 120px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 600;
        margin: 0 7px;

        span{
            transform: scale(2, 1)
          }
    }

    #rtl-bottombumper-logo_itemR{
       background: #F2DA00;
     
    }

    #rtl-bottombumper-logo_itemT{
        background: #FB0000;
    }

    #rtl-bottombumper-logo_itemL{
        background: #FE8800;
    }
    
    .tv_etv_spot_side-bumper-AdHeadText_container{
  
       // position: absolute;
        width: inherit;
       // top: 50px;
       padding-top: 70px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }

    .tv_etv_spot_side-bumper-AdHeadText{
  
        background: #C8C8C8;
        color: #595958;
        font-size: 18px;
        font-weight: 700;
        height: 30px;
        padding-left: 20%;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        span{
            margin-left: 10px;
        }
        
    }

    .tv_etv_spot_side-bumper-blue{
        width: 25px;
        height: 15px;
        background: rgb(20, 102, 255);
    }




    // bumper vox

    .vox-sidebumper_container{

        background: 
        linear-gradient(215deg, white 50%, transparent 50%), 
        linear-gradient(127deg, #C31032 35%, #C31032 30%, #D61932 35%, #D61932 80%, #C31032 80%);
   
        width: 100%;
        height: 100%;
  
    }


    .tv_etv_spot_side-bumper_text_vox{
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
    }


    .vox-bottombumper_container{
        background: 
        linear-gradient(315deg, white 30%, transparent 30%), 
        linear-gradient(240deg, #C31032 30%, #C31032 30%, #D61932 30%, #D61932 100%, #C31032 80%);
   
        width: 100%;
        height: 100%;
    }

   .vox-bottombumper-text_container{

   

    p{
        margin-block-start: 0;
        margin-block-end: 0;
        width: fit-content;
        background: white;
        padding: 6px 20px;

    }
   }

   #vox-bottombumper-text1{
    font-size: 26px;
    color: #B6002A;
   }

   #vox-bottombumper-text2{
    font-size: 28px;
    text-transform: uppercase;
    color: #B6002A;
    font-weight: 700;
   }

   .vox-bottombumper-logo_container{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3%;
    width: 189px;
   }