.ntv-article-day{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.ntv-article-day-container{
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e6eb;
    padding: 20px 0;
    
    
}

.ntv-article-day-container-main{
    width: 95%;
    height: 100%;
    display: flex;
    align-items: start;
    border-bottom: 1px solid #e1e6eb;
    padding: 20px 0 20px 20px;
    
    
}

.ntv-article-main-desk-desc{
 
font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
line-height: 21px;
font-size: 17px;
margin-top: 14px;
margin-block-start: 5px;
margin-block-end: 0;
}

.ntv-article-main-desk-desc-main{
 
font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
line-height: 21px;
font-size: 17px;
margin-top: 14px;
margin-block-start: 0;
margin-block-end: 0;
}

.ntv-article-day-img-container{
    margin-right: 15px;
    max-width: 280px;
    width: 100%;

    img{
       width: 100%;
    }
}

.ntv-article-day-img-container-main{


   
}

.ntv-article-day-text-container-main{
 margin-left: 20px;
}



.ntv-article-day-text-head{
color: #dc0028;
font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
font-weight: 700;
font-size: 18px;
text-transform: uppercase;

margin-block-start: 0;
margin-block-end: 0;
}

.ntv-article-day-text{
    color: black;
    font-size: 26px;
    font-weight: 700;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    margin-block-start: 0;
    margin-block-end: 0;

}

.ntv-article-day-img{
    display: block;
}

.ntv-article-day-img_mobile{
    display: none;
}

.ntv-article-head{
    text-transform: uppercase;
    color: #46555f;
    font-size: 24px;
    font-weight: 700;
    padding-left: 20px;
    margin-top: 50px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}


.ntv-article-main_container{
    width: 95%;
  
    margin: 20px 20px;

}

.ntv-article-main_img-container{
    width: 100%;
   //height: 330px;

    img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: 50% 0%;
    }
}

.ntv-article-main-theme{
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #697882;
    font-weight: 600;
    text-transform: uppercase;
    margin-block-start: 0px;
    margin-block-end: 5px;

}

.ntv-article-main-desk-head{
    color: #dc0028;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
}

.ntv-article-main-desk-text{
    color: black;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
}


.ntv-article-day-img_mobile-main{
    display: none;
}


.ntv-article-side-videos_container{
  
    width: 75%;
    margin: 70px auto;
    


}


.ntv-article-side-theme{
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #697882;
    font-weight: 600;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 5px;

}

.ntv-article-side-desk-head{
    color: #dc0028;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 5px;
}

.ntv-article-side-desk-text{
    color: black;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
}

.ntv-article-side-videos-video_icon{
    background: #dc0028;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ntv-article-side-videos-img-container{
    width: 100%;
    position: relative;
  
    

    img{
        width: 100%;
        
    }
}

.ntv-article-side-videos-video_icon-play{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #FFFFFF;
    transform: rotate(0deg);
}


.ntv-article-side-article-videos_container{
    border: 1px solid red;
}


.ntv-article-box-videos_container{
    
    margin: 0 20px;
    max-width: 300px;
    width: 100%;
}

.ntv-article-box-videos-img-container{
    position: relative;
    width: 100%;
    img{
        width: 100%;
    }
}


.ntv-ad-medrec_container{
    display: none;
}



@media screen and (min-width: 1536px) and (max-width: 1919px){


    .ntv-article-day-text-head{
     
        font-size: 17px;
      
        }

    .ntv-article-day-text{
      
        font-size: 22px;
    
    }

    .ntv-article-main-desk-desc-main{
 
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        line-height: 21px;
        font-size: 15px;
        margin-top: 14px;
        }


        .ntv-article-side-desk-head{
          
            font-size: 16px;
       
        }

        .ntv-article-side-desk-text{
           
            font-size: 18px;
          
        }

        .ntv-article-main-desk-head{
       
            font-size: 15px;
          
        }
        
        .ntv-article-main-desk-text{
        
            font-size: 25px;
            
        }


        .ntv-article-main-desk-desc{
 
          
            font-size: 15px;
         
            }
            
}







@media screen and (min-width: 1320px) and (max-width: 1535px){

  
    

}

@media screen and (max-width: 1319px){

    .ntv-article-day-text-head{
     
        font-size: 15px;
      
        }

    .ntv-article-day-text{
      
        font-size: 20px;
    
    }

    .ntv-article-main-desk-desc-main{
 
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        line-height: 17px;
        font-size: 14px;
        margin-top: 14px;
        }

        .ntv-article-day-img-container{
            margin-right: 15px;
            max-width: 250px;
            width: 100%;
        
            img{
               width: 100%;
            }
        }


        .ntv-article-side-desk-head{
          
            font-size: 12px;
       
        }

        .ntv-article-side-desk-text{
           
            font-size: 13px;
          
        }



        .ntv-article-main-desk-head{
       
            font-size: 13px;
          
        }
        
        .ntv-article-main-desk-text{
        
            font-size: 20px;
            
        }


        .ntv-article-main-desk-desc{
 
          
            font-size: 12px;
         
            }
        

}


@media  (max-width: 576px){

    .ntv-article-day-img-container{
        max-width: 150px;
       width: 100%;
      
      
    
        img{
            width: 100%;
            height: 100%;
            
        }
    }


    .ntv-article-day-text-head{
     
        font-size: 13px;
      
        }

    .ntv-article-day-text{
      
        font-size: 16px;
        
    
    }

    .ntv-article-day-img{
        display: none;
    }
    .ntv-article-day-img_mobile{
        display: block;
    }

    .ntv-article-head{
        text-transform: uppercase;
        background: #aab9c3;
        color: #46555f;
        font-size: 16px;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        font-weight: 700;
        padding-left: 20px;
        margin-top: 25px;
        height: 35px;
    display: flex;
    align-items: center;
    }


    .ntv-article-main_container{
        width: 100%;
     
        margin: 0px 0px 20px 0;
    
    }


    .ntv-article-main_img-container{
        width: 100%;
        height: 120px;
    
       
    }

    .ntv-article-main-theme{
       margin-left: 8px;
        font-size: 11px;
        margin-top: 5px;
     
    
    }

    .ntv-article-main-desk-head{

        font-size: 14px;
        margin-left: 8px;
    }

    .ntv-article-main-desk-text{
  
    font-size: 15px;
    width: 85%;
   
    margin-left: 8px;

    }

    .ntv-article-main-desk-desc{
 
        display: none;
        }


        .ntv-article-main-desk-desc-main{
 
           display: none;
            }
    

            .ntv-article-day-img_mobile-main{
              display: block;
              width: 100%;
                width: 150px;
                
            }
            

            .ntv-article-day-container-main{
               
                padding: 20px 0 20px 8px;
                
                
            }

            
.ntv-article-day-img-container-main{
    width: auto;
    max-width: none;
    min-width: auto;

   
}

.ntv-article-day-text-container-main{
    margin-left: 5px;
   }



   .ntv-article-box-videos_container{
    
    margin: 20px 20px;
}


.ntv-ad-medrec_container{
    display: flex;
    border: 1px solid;
    width: 100%;
   
   height: 100%;
   max-width: 300px;
   max-height: 250px;

   img{
    width: 100%;
    height: 100%;
   }
   video{
    width: 100%;
    height: 100%;
   }
}




}

