.prime-head{
    width: 100%;
    height: 100px;
    background-color: #131921;
    display: flex;
    flex-direction: column;
    
    
    }
.prime-head-main{
    width: 100%;
    height: 60px;
    background-color: #131921;
    display: flex;
    cursor: pointer;
    

  
    }
    
    .prime-nav-left{
        display: flex;
        align-items: center;

        height: 100%;
        max-width: 350px;
        width: 100%;
        margin-left: 20px;
     
    }
    
    .prime-logo{
     
        max-width: 130px;
        width: 100%;
       
        img{
            width: 100%;
            height: 100%;
           
        }
    }
    
    .prime-location{
      
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #898b8e;
        width: 100%;
       

        span{
            color: white;
            
            font-weight: bold;
        }
    
    }
    
    
    .prime-nav-search{
       
      
        display: flex;
        align-items: center;
        flex-basis:80%;
      
        
        
    }
    
    .prime-nav-search-left{
        width: 100%;
        max-width: 100px;
        background: #e6e6e6;
        height: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-size: 12px;
        color: rgb(65, 65, 65);
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    
    .prime-arow{
        border-color: rgb(65, 65, 65) transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        height: 0;
        margin-left: 10px;
      
    }
    
    
    .prime-nav-search-center{
       background:  white;
       width: 85%;
       height: 40px;
       font-size: 14px;
        color: rgb(65, 65, 65);
        display: flex;
        align-items: center;
       padding-left: 10px;
       position: relative;
      
    
       img{
        height: 70%;
        position: absolute;
        right: 10px;
       }
    }
    
    .prime-nav-search-btn{
        background: #febd69;
        width: 50px;
        height: 40px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    
    
    .prime-nav-right{
        
    
      
    
        max-width: 550px;
        width: 100%;
        display: flex;
       
   
       
    }
    .prime-nav-right_container{
        
    
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        width: 100%;
   
       
    }

    .prime-nav-right-language{
        
        display: flex;
        max-width: 70px;
        width: 100%;
        height: 100%;
        color: white;
        font-weight: bold;
        align-items: center;
        justify-content: center;
 
        font-size: 14px;
       
       

        img{
            width: 30%;
            height: 30%;
            
            padding-right: 5px;
            
        }
    }

    .primeLanguage{
        border-color: rgb(255, 255, 255) transparent transparent;
        margin-left: 5px;
        
    }

    .prime-nav-right-login{
        color: white;
        font-size: 14px; 
    
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items:start;
        justify-content: center;
 
        white-space: nowrap;
        

        span{
            font-weight: bold;
            font-size: 15px;
            
        }
    }

    .options{
     
    }

    .prime-shoppingcart{
    

        font-size: 15px;
        color: white;
        font-weight: bold;
        hyphens: auto;
        display: flex;
        align-items: end;
        margin-right: 15px;
       
        

        span{
            margin-bottom: 5px;
            word-break: break-word;
             white-space: normal;
             
        }

        img{
          
            width: 50px;
        }
       
    }

    .prime-head-nav{
        background: #232f3e;
        width: 100%;
        height: 40px;
        color: white;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        justify-content: start;
        align-items: center;
       
    }

    .prime-head-nav_container{
        
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        height: 100%;
        margin-left: 20px;
    }

    .prime-head-nav-element{
    
       margin-left: 10px;
       
    }

    #prime-head-nav-all{
        display: flex;
      align-items: center;
        font-weight: bold;
      
        span{
            margin-left: 10px;
        }
       
    }

    @media (max-width: 1600px) {
        .prime-head-nav-element:nth-last-child(-n+2) {  //вот это удаляе последние 2 элемента родителя!!!
            display: none;
        }
    }
    
    @media  (max-width: 1280px){
        .prime-shoppingcart{
            span{
                display: none;
            }
        }


        @media (max-width: 1280px) {
            .prime-head-nav-element:nth-last-child(-n+4) {
                display: none;
            }
        }

    

    }