
#reweka-menu{
    display: none;
}

#reweka-wagen{
    display: none;
}

#reweka-user{
    display: none;
}

#reweka-mobileLogos{
    display: none;
}

.reweka-Head{
    width: 100%;
  
    transition: 1s opacity;
 
  
}


.reweka-FirstLayer{
    width: 100%;
 
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
    
  
}

.reweka-Content{
    
    
    display: flex;
    align-items: end;
    position: relative;
    max-width: 1400px;
    width: 100%;


}

.reweka-HeadButtons{
   
    display: flex;
    align-items: end;
    
    
   
   
}

.reweka-LogoImg{
 max-width: 150px;
 width: 100%;

display: flex;
 cursor: pointer;

 img{
    width: inherit;

 }

}


.reweka-Button{
    border: 1px solid rgb(156, 156, 156);
    box-shadow: 3px 3px 5px rgb(162, 162, 162);
    margin-left: 10px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 17px;
    background-color: white;
    width: 60%;
    height: 40px;
    white-space: nowrap;
    padding: 0 15px 0 15px;
}

.reweka-Button:hover{
   color: #007f3f;
   cursor: pointer;
   height: 45px;
}


.reweka-Panel{
 
    display: flex;
  justify-content: space-evenly;
  position: absolute;
  right: 0%;
  width: 40%;
  top: 1%;


}

.reweka-PanelItem{
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    
    
    white-space: nowrap;
   
   
    span{
        margin-left:10px;
        margin-right:10px;
    }
    

}

.reweka-PanelItem:hover{
    color: #007f3f;

}



.reweka-PayBackImg{
    width: 60px;
    height: auto;
   
  
}

.reweka-Secondlayer{
    width: 100%;
  height: 70px;
  box-shadow: 0px -1px 5px rgb(162, 162, 162);
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  position: relative;
  z-index: 0;
  justify-content: center;
  display: flex;
  align-items: center;
    
    
}

.reweka-Reweka-Content2{
  
    width: 84%;
    height: 80px;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;
    
    justify-content: center;
 
}

.reweka-SearchField{
    max-width: 600px;
    width: 100%;
    height: 40px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
  

    span{
       position: absolute;
       left: 1%;
       color: gray;
       font-size: 15px;
       
    }

    img{
        width: 4%;
        margin-right: 0.5vw;
    }
   
}

.reweka-SearchField:hover{
    background-color: #f1f1f1;
}


.reweka-OptionenBtn{
    width: 500px;
    display: block;
    height: 75px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.reweka-OptionButton{
    border: 1px solid #cccccc;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.9em;
}


.reweka-OptionButton:hover{
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid #1c1c1c;
    box-shadow: inset 0 0 0 1px #1c1c1c;
}


.reweka-Thirdlayer{
    background-color: #fbfaf9;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    
}

.reweka-Content3{
   
    max-width: 1260px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: end;
   
}


#rewekaLeftbutton{
   
    border-bottom: transparent;
    width: 9%;
    height: 50px;
    font-size: 16px;
     font-weight: 600;
     color: #4c4c4c;
     display: flex;
     align-items: center;
     justify-content: center;
     
   
}

.reweka-FourButtons{
 
    border-left: transparent;
    width: 9%;
    height: 50px;
    border-bottom: transparent;
    font-size: 16px;
    font-weight: 600;
    color: #4c4c4c;
    display: flex;
    align-items: center;
    justify-content: center;

 
   
}




.reweka-FourButtons::after{
 
 content: "";
 background: gray;
 width: 1px;
 height: 70%;
 margin-left: 15%;
      
   
}

.reweka-FourButtons:hover{
    color: #007f3f;
    cursor: pointer;
}


#rewekaLeftbutton::after{
     
    content: "";
    background: gray;
    width: 1px;
    height: 70%;
    margin-left: 15%;
  
   
}

#rewekaLeftbutton:hover{
    color: #007f3f;
    cursor: pointer;
}


@media screen and (max-width: 1400px){


    .reweka-Panel{
 
        display: flex;
      justify-content: space-evenly;
      position: absolute;
      right: 0%;
      width: 50%;
      top: 1%;

    
    }
    
    .reweka-PanelItem{
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        
        
        white-space: nowrap;
       
       
        span{
            margin-left:10px;
            margin-right:10px;
        }
        
    
    }


}


@media screen and (width: 1024px){




}



    @media  (max-width: 576px){


        #reweka-menu{
            display: block;
            position: absolute;
            left: 2vw;
            top: 1vw;
        }
        
        #reweka-wagen{
            display: none;
        }
        
        #reweka-user{
            display: block;
            position: absolute;
            right: 2vw;
            top: 2vw;
            width: 6%;
            
        }
        
        #reweka-mobileLogos{
            display: none;
        }
        
        .reweka-Head{
            width: 100%;
          
            transition: 1s opacity;
         
          
        }
        
        
        .reweka-FirstLayer{
            width: 100%;
            position: relative;
            background-color: #007f3f;
            
          
        }
        
        .reweka-Content{
            
            
            display: flex;
            justify-content: center;
           
        
        
        }
        
        .reweka-HeadButtons{
           
           display: none;
           
           
        }
        
        .reweka-LogoImg{
         margin-left:0;
         max-width: 110px;
         width: 100%;
        display: flex;
         cursor: pointer;
        
         img{
            width: inherit;
        
         }
        
        }
        
        
        .reweka-Button{
            border: 1px solid rgb(156, 156, 156);
            box-shadow: 3px 3px 5px rgb(162, 162, 162);
            margin-left: 10px;
            font-weight: bold;
            justify-content: center;
            display: flex;
            align-items: center;
            font-size: 1vw;
            background-color: white;
            width: 11vw;
            height: 2.5vw;
            white-space: nowrap;
        }
        
        .reweka-Button:hover{
           color: #007f3f;
           cursor: pointer;
           height: 45px;
        }
        
        
        .reweka-Panel{
         
           display: none;
        
        }
        
        .reweka-PanelItem{
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 0.75vw;
            cursor: pointer;
            width: 5vw;
            white-space: nowrap;
           
            span{
                margin-left:10px;
                margin-right:10px;
            }
            
        
        }
        
        .reweka-PanelItem:hover{
            color: #007f3f;
        
        }
        
        
        
        .reweka-PayBackImg{
            width: 3.5vw;
            height: auto;
            border-radius: 5px;
            margin-left: 30%;
        }
        
        .reweka-Secondlayer{
          width: 100%;
          height: 60px;
          box-shadow: none;
          background-color: #ffffff;
          border-bottom: none;
          border-top: none;
          border-bottom: none;
          position: relative;
          z-index: 0;
          justify-content: center;
          display: flex;
          background: #007f3f;  
          align-items: start;
            
        }
        
        .reweka-Reweka-Content2{
          
            width: 84%;
            height: 80px;
            margin: 0 auto;
            display: flex;
            position: relative;
            align-items: center;
            
            justify-content: center;
         
        }
        
        .reweka-SearchField{
            width: 90%;
            height: 80%;
            border: 1px solid gray;
            display: flex;
            align-items: center;
            justify-content: end;
            cursor: pointer;
            border-radius: 2px;
            position: relative;
            background:white ;
        
            span{
               position: absolute;
               left: 1%;
               color: gray;
               font-size: 16px;
               
            }

            img{
                width: 7%;
                margin-right: 2vw;
            }
           
        }
        
        .reweka-SearchField:hover{
            background-color: #f1f1f1;
        }
        
        
        .reweka-OptionenBtn{
            width: 500px;
            display: block;
            height: 75px;
            
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        
        .reweka-OptionButton{
            border: 1px solid #cccccc;
            width: 160px;
            height: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 0.9em;
        }
        
        
        .reweka-OptionButton:hover{
            cursor: pointer;
            background-color: #f1f1f1;
            border: 1px solid #1c1c1c;
            box-shadow: inset 0 0 0 1px #1c1c1c;
        }
        
        
        .reweka-Thirdlayer{
           display: none;
        }
        
        .reweka-Content3{
           
            width: 84%;
            height: 4.5vw;
            display: flex;
            align-items: end;
        }
        
        .reweka-FourButtons{
         
            border-left: transparent;
            width: 6vw;
            height: 2.5vw;
            border-bottom: transparent;
            font-size: 0.9vw;
            font-weight: 600;
            color: #4c4c4c;
            display: flex;
            align-items: center;
            justify-content: center;
        
            img{
                width: 0.8vw;
                margin-right: 10%;
            }
           
        }
        
        
        #rewekaLeftbutton{
           
            border-bottom: transparent;
            width: 6vw;
            height: 2.5vw;
             font-size: 0.90vw;
             font-weight: 600;
             color: #4c4c4c;
             display: flex;
             align-items: center;
             justify-content: center;
             
           
        }
        
        .reweka-FourButtons::after{
         
         content: "";
         background: gray;
         width: 1px;
         height: 70%;
         margin-left: 15%;
              
           
        }
        
        .reweka-FourButtons:hover{
            color: #007f3f;
            cursor: pointer;
        }
        
        
        #rewekaLeftbutton::after{
             
            content: "";
            background: gray;
            width: 1px;
            height: 70%;
            margin-left: 15%;
          
           
        }
        
        #rewekaLeftbutton:hover{
            color: #007f3f;
            cursor: pointer;
        }

    }