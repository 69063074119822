@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.stern-footer{

  
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    height: 500px;
}

.stern-footer_container{
    width: 100%;
    height: 100%;
 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.stern-footer-nav_container{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    margin-top: 3%;
    justify-content: center;
    line-height: 30px;

    li{
        margin-left: 30px;
        color: rgb(84, 84, 84);
        font-size: 14px;
        font-weight: 500;

    }
}

@media screen and (min-width: 1536px) and (max-width: 1919px){

   
  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

 
    
}



@media screen and (max-width: 1319px){

   
}




@media  (max-width: 576px){

 
    .stern-footer-nav_container{
        padding: 0;
        width: 85%;
        justify-content: space-evenly;
    
        li{
           margin-left: 0;
        }
    }

    

}