.tiktok-footer{
  width: 100%;
  height: 60px;
  background: black;
  position: sticky;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  transition: 0.5s all;
 
}

.tik-tok-footer-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  
}

.tik-tok-footer_icon-container{
  flex-basis: 20%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #5e5e5e;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  max-width: 85px;
  width: 100%;

  img{
    width: 28%;
    
  }
}

#tik-tok-footer_icon-home{
  color: white;
}

.tik-tok-footer_add-icon{
  background: white;
  width: 55%;
  height: 70%;
  border-radius: 10px;
}

.tik-tok-footer_add-icon{
  display: flex;
  justify-content: center;
  max-width: 40px;
  width: 100%;
  border-left: 4px solid #00f2ea;
  border-right: 4px solid #ff0050;
  height: 100%;
  max-height: 32px;

}

#tik-tok-footer_plus-icon{
  font-weight: 600;
  font-size: 31px;
  color: black;
  margin-top: 4px;

}

