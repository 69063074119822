@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.ntv-footer{

    
}

.ntv-footer-container{
  
    width: 95%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    line-height: 27px;
}

.ntv-footer-items-head{
    font-weight: 700;
    color: #0a1924;
    font-size: 17px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif; 
}

.ntv-footer-items{
    color: #0a1924;
    font-size: 17px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    height: fit-content;
}

.ntv-footer-items-text{
    color: #0a1924;
    font-size: 14px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

.ntv-footer-items-text_field-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ntv-footer-items-text_field{
    width: 70%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e1e6eb;
}

.ntv-footer-items-text_field-btn{
    width: 20%;
    background: #46555f;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 17px;
    font-weight: 700;
    border-radius: 5px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}


.ntvTextField{
    width: 40%;
}


.ntv-footer2-container{
    background: #2d3c46;
    height: 140px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 14px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

.ntv-footer2-icons{
    display: flex;
}

.ntv-footer2-icon{
    margin: 0 15px;
}


@media screen and (min-width: 1536px) and (max-width: 1919px){

    .ntv-footer-items-head{
        font-weight: 700;
        color: #0a1924;
        font-size: 15px;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif; 
    }
    
    .ntv-footer-items{
        color: #0a1924;
        font-size: 15px;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        height: fit-content;
    }

    .ntv-footer-items-text_field-btn{
        width: 25%;
        font-size: 14px;
    }
  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

    .ntv-footer-items-text_field-btn{
        width: 30%;
      
      
    }

    .ntv-footer-items-text_field{
        width: 60%;
        
    }
    
}



@media screen and (max-width: 1319px){

    .ntv-footer-items-text_field-btn{
        width: 30%;
      
    }

    .ntv-footer-items-text_field{
        width: 60%;
        
    }


    .ntv-footer-items-head{
        font-weight: 700;
 
        font-size: 12px;
       
    }
    
    .ntv-footer-items{
    
        font-size: 12px;
      
    }
    
    .ntv-footer-items-text{
       
        font-size: 10px;
    
    }
    
    .ntv-footer-items-text_field-btn{
       font-size: 12px;
       height: 30px;
    }
  
    .ntv-footer-items-text_field{
        width: 60%;
        height: 30px;
        
    }

}




@media  (max-width: 576px){


    .ntv-footer-container{
       display: none;
    }


    .ntv-footer2-container{
       
        font-size: 11px;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
    }

    .ntv-footer2-menu{
    
        text-align: center;
    }

    }