
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


@media  (max-width: 576px){
    .neurexan_ad{
       
        width: 100%;
        background: #f5f5f5;

    
    }

    .neurexan_ad_container{

        padding: 0 9px;
       
    }

    .neurexan_ad_top{
        color: #adadad;
        font-family: Montserrat, sans-serif;
        font-size: 12.6px;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: end;
        z-index: 1;
        position: relative;
        background: #f5f5f5;
    }

    .neurexan_ad-img_container{
        transition: 1s all;
        

        img{
            width: 100%;
            height: 100%;
        }
    }

    .neurexan_ad_container-title{
       margin-top: 8px;
       font-size: 19px;
       line-height: 1.195;
       font-weight: 500;
       font-family: DMCustomFontText, sans-serif;
    }

    .neurexan_ad-img_container_expand{
        background: #164194;
        color: white;
        padding-bottom: 30px;
    }

    .neurexan_ad_container-title_expand{
        margin-top: 8px;
        font-size: 18px;
        margin-left: 12px;
        width: 90%;
    }


    .neurexan_ad_container-text_expand{
        font-size: 14px;
        margin: 0px 0 0 16px !important;
        width: 90%;
    }

    .neurexan_ad_container-text{
        margin: 0px 0 0 5px;
        line-height: 1.5em;
       font-size: 14px;
        font-family: DMCustomFontText, sans-serif;
        font-weight: 400;
       
    }


    .neurexan_ad_cta_container{
       
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .neurexan_ad_cta_cta{
        background: #164194;
        text-align: center;
        width: 100%;
       font-family:  DMCustomFontText, sans-serif;
        align-self: flex-end;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 17px;
        font-weight: 700;
        max-width: 210px;
        padding: 8px 0;
    }

    .neurexan_ad_container-text_cta{

        display: flex;
        flex-direction: column;
        gap: 10px;
        
    }

    .neurexan_ad-footer_container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .neurexan_ad-footer{
        
        display: flex;
        color: #adadad;
        font-size: 11px;
        padding: 10px 0;
        flex-wrap: wrap;

    img{
        width: 50px;
        height: 10px;
        align-self: end;
        margin-left: 5px;
    }
    }

    .neurexan_ad_cta-container{
        width: 100%;
        display: flex;
        justify-content: end;
      
    }


    .neurexan_ad_container_expand{
     
        display: none;

    }

    .neurexan_ad_expand-block{
       
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background: #164194;
    }


    .neurexan_ad_expand-block_img-container{
        
        

        img{
            width: 100%;
            height: 100%;
        }
    }

    .neurexan_ad_expand-block-text_container{
        color: white;
        
        width: 100%;
     
    }

    .neurexan_ad_expand-block_title{
        font-size: 14px;
        font-family: DMCustomFontTitle, sans-serif;
        line-height: 19.6px;
        overflow-wrap: break-word;
        text-align: left;
        font-weight: 400;
        padding-left: 8px;
        margin-top: 5px;
    }

    .neurexan_ad_expand-block_list{
        font-size: 12px;
        font-family: DMCustomFontText, sans-serif;
        font-weight: 400;
        line-height: 20px;
        overflow-wrap: break-word;

        ul{
            padding-inline-start: 20px;
            width: 90%;
        }
    }

    .neurexan_ad_expand-block-cta{
        color: white;
        border: 2px solid;
        border-radius: 8px;
        font-weight: 600;
        padding: 4px 16px;
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
       

        img{
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }

    }

    .neurexan_ad_expand-video_poster{
        position: relative;
        background: #164194;
          
        img{
            width: 100%;
            height: 100%;
            filter: brightness(80%); 
        }
    }

    .neurexan_ad_expand-video_poster-img_container{
     padding: 0 18px;
    }

    .neurexan_ad_expand-video_poster-playIcon{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        
        
    }

    .neurexan_ad_expand-card_block{
        background: #164194;
    }

    .neurexan_ad_expand-block_text{
        font-size: 12px;
        font-family: DMCustomFontText, sans-serif;
        font-weight: 400;
        line-height: 20px;
        overflow-wrap: break-word;
        padding: 8px;
        margin-top: 10px;
    }


    .neurexan_ad-footer-closeBtn_expand{
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 14px;
        display: none;
    }






//////////////////////////////// NRW Bank Style



.nrwbank_ad{
       
    width: 100%;
    background: #f5f5f5;
   

}

.nrwbank_ad_container{

    padding: 0 20px;
   
}

.nrwbank_container_top{
    display: flex;
    justify-content: space-between;
}


.nrwbank_container_top-title{
    color: #7e3e60;
    font-family: 'Libre Bodoni', serif;
    font-weight: 500;
    font-size: 18px;

}

.nrwbank_container_top-ad{
   color:  #adadad;
   font-size: 13.5px;

}

.nrwbank_ad_block_contianer{
    display: flex;
    align-items: start;
    height: 110px;
    
}


.nrwbank_ad_block-img_container{
    width: 38%;

    img{
        width: 100%;
        height: 100%;
    }
}

.nrwbank_ad_block-text_container{
    width: 55%;
    height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    font-size: 15px;
    color: #4b4b4b;
    line-height: 22.5px;
    font-family: "Nunito Sans", sans-serif;
    margin-left: 5px;
}


.nrwbank_ad-ctaBtn_contianer{
    text-align: end;
    border-bottom: 1px solid;
    margin-top: 20px;
    display: flex;
    align-items: end;

    img{
        width: 18px;
        height: 18px;
    }
}

.nrwbank_ad-ctaBtn{
color: #c83f69 ;
font-size: 15px;
font-family: "Nunito Sans", sans-serif;
text-transform: uppercase;
}



.nrwbank_ad-footer_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nrwbank_ad-footer{
    
    display: flex;
    color: #adadad;
    font-size: 11px;
    padding: 10px 0;
    flex-wrap: wrap;

img{
    width: 75px;
    height: 11px;
    align-self: end;
    margin-left: 2px;
}
}

/////////////// EXPAND NRW BANK


.nrwbank_ad_block_contianer-expand{
    
    flex-direction: column;
    align-items: center;
    height: auto;
}

.nrwbank_ad_block-img_container_expand{
    width: 100%;

    img{
        height: 100%;
        width: 100%;
    }
}

.nrwbank_ad_block-text_container-expand{
    width: 100%;
    font-size: 17px;
    display: block;
    padding-bottom: 30px;
    color: #4b4b4b;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 5px;

    ul{
        padding-inline-start: 35px;
    }
}

.nrwbank_ad_block-text_container-expand-link{
    color: #7e3e60;
    font-size: 17px;
    padding-bottom: 30px;
    font-family: "Nunito Sans", sans-serif;
    /*
     text-decoration: underline;
    text-decoration-style: double;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    */
}

.nrwbank_ad_block_contianer_expand{
display: none;
flex-direction: column;


}


.nrwbank_rect_cta-container{
   
    //height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.nrwbank_rect_cta{
    padding: 20px 15px;
 background: #7e3e60;
 display: flex;
 align-items: center;
 justify-content: center;
 color: #fff;
 font-size: 19px;

 width: 90%;
 text-align: center;

 div{
    min-width: 40%;
    max-width: 70%;
 }
}

.nrwbank_ad-footer-closeBtn_expand{
    color: #4b4b4b;
    font-family: 'Nunito Sans', sans-serif;
    text-transform: uppercase;
    border-bottom: 1px solid #7e3e60;
    display: none;
}

}
