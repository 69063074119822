@import url('https://fonts.googleapis.com/css2?family=Anton&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.chef-head{
    height: 85px;
    width: 100%;

    padding: 16px 0;
    box-shadow: 0 2px 8px #21201826;
}

.chef-head_container{
  
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
}

.chef-head-search-nav_container{
   
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chef-head-img_container{
    width: 70%;
}

.chef-head-search_container{
    border: 1px solid #a7c6ac;
    height: 44px;
    border-radius: 20px;
    max-width: 545px;
    width: 100%;
    display: flex;
    align-items: center;

  
}

.chef-head-search{
   
    height: 28px;
    max-width: 545px;
    width: 100%;
    display: flex;
    align-items: center;
}

#chef-head-search-icon{
    margin-left: 10px;
    height: 65%;
}

#chef-head-search-text{
    margin-left: 15px;
    color: #595852;
    font-size: 16px;
    font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;

}

.chef-head-nav_container{
   
    display: flex;
}

.chef-head-nav{
    display: flex;
    list-style: none;
    padding-inline-start: 0;
    margin-block-end: 0;
    color: #595852;
    font-weight: 550;
    font-size: 16px;
    font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
}

.chef-head-nav-item{
    margin-right: 20px;
}

.chef-head-actions_container{
   
    display: flex;
    width: 100%;
    justify-content: end;
    
}

.chef-head-actions_login{
    color: #3b8047;
    font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}

.chef-head-actions_icon{
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    font-family: "Source Sans Pro", "Source Sans Pro-fallback", sans-serif;
    color:'#595852';
}

.chef-head-mobile_container{
    display: none;
}

@media screen and (min-width: 1536px) and (max-width: 1919px){

  

}



@media screen and (min-width: 1320px) and (max-width: 1535px){

   

}



@media screen and (max-width: 1319px){

    .chef-head_container{
  
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
    }

}

@media screen and (max-width: 1100px){

    .chef-head_container{
  
        max-width: 1000px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
    }

}




@media  (max-width: 576px){


    .chef-head-search_container{
        margin-top: 10px;
        height: 35px;
    
        width: 90%;
       
    
      
    }


    .chef-head-img_container{
        width: 100%;
    display: flex;
    justify-content: space-between;

    img{
        margin-left: 10px;
    }
    }
    

    .chef-head_container{
        flex-direction: column;
    }


    .chef-head-nav_container{
        display: none;
    }

    .chef-head-actions_container{
        display: none;
    }

    .chef-head-mobile_container{
        display: flex;
        width: 35%;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    }

    .chef-head-mobile-login{
        font-weight: 500;
        color: #3b8047;
    }

    .chef-head-mobile_menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span{
            font-size: 12px;
        }
    }

    }