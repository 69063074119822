.define-index_container{
    width: 100%;
    height: auto;
}

.adcheck-define{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
}




.adcheck-define_container{


    min-height: 100vh;
   max-width: 970px;
   width: 100%;
   padding-bottom: 100px;
}

.adcheck-define_container-bbContainer{
  
    max-height: 250px;
    height: 100%;
    width: 100%;
    margin-top: 20px;

    img{
        width: 100%;
        height: 100%;
    }
}

.adcheck-define_container-subarticle{

    max-width: 260px;
    width: 100%;
    margin-top: 30px;

    img{
        width: 100%;
    }
}


.adcheck-define-skyscr-container{
   
    display: flex;
    justify-content: center;
  }

  .define-mainarticle-miniacticle-container{

    padding: 0 15px;
}


.define-mainArticle-container{

    
    margin-top: 30px;
    height: 200px;
    width: 100%; //65
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
  

.defineArticle{
   
    height: auto;
    display: flex;
    flex-direction: column;
}


.define-mainArticle-text-container-header{
    font-size: 27px;
    font-weight: 700;
    margin-top: 10px;
    padding-bottom: 15px;
   
   
  }

  .define-mainArticle-text-container-label{
    font-size: 13px;
    text-transform: uppercase;
   }

   .defineTextType{
    color: gray;
    text-transform: none;
    padding-bottom: 15px;
}


.define-mainArticle-img-container-article{
   
    width: 100%;
    height: fit-content;
   
    position: relative;

    img{
        width: 100%;
    }
}

.define-remark{
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
}

.define-remark-mobile{
    display: none;
}

.define-skyScrapper-container-mobile{
    display: none;
}

.define-remark-mobile{
    display: none;
}


.define-miniArticle-container-mobile{
    display: none;
}

.main-container-define-articleContainer{
  
    width: 100%;  //65
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }


  .define-miniArticle-container{
    display: block;
      width: 45%;
     margin-left: 25px;
      margin-top: 25px;
      
      
  }


  .define-miniArticle{

    background: #f5f5f5;
   padding-bottom: 10px;
    margin-bottom: 10px;


    img{
        width: 90%;
    }
}


.define-skyScrapper-container{

    width: 200px;
    text-align: end;
   
  
    img{
      position: sticky;
      top: 150px;
    }
  }


  .define-mainArticle-text-container-text{
    font-size: .910em;
    margin-top: 10px;
  

  
}

.adcheck-main-gos-subarticle-img{
    img{
        height: 146px;
        object-fit: cover;
    }
}


.adcheck-main-define-subarticle-text{
    font-size: .9em;
    font-weight: 600;
}


@media  (max-width: 576px){

    .adcheck-define_container{
        
        width: 100%;
        min-height: 100vh;
       // padding-bottom: 700px;
        display: flex;
    flex-direction: column;
    align-items: center;
    }

    .adcheck-define_container-bbContainer{
        
        max-height: 100px;
        height: 100%;
        width: 100%;
        margin-top: 20px;
    
        img{
            width: 100%;
            height: 100%;
        }
    }

    .adcheck-define_container-subarticle{
       
        max-width: none;
        width: 100%;
        margin-top: 30px;
    
        img{
            width: 100%;
        }
    }

    .define-mainarticle-miniacticle-container{
       
        padding: 0 15px;
    }


    .define-mainArticle-container{
       
        margin-top: 30px;
        height: auto;
        width: 100%;
        display: block;
        
    }
    
    .defineArticle{
        height: auto;
    }

    .define-mainArticle-text-container-header{
        font-size: 17px;
        font-weight: 700;
        margin-top: 10px;

      }

      
    .define-mainArticle-text-container-label{
        font-size: 13px;
     
       }


       .define-mainArticle-img-container-article{
   
        width: 100%;
        height: fit-content;
    
        img{
            width: 100%;
        }
    }


    .define-remark{
        display: none;
       }
   
       .define-remark-mobile{
           display: block;
           font-size: 20px;
           font-weight: bold;
           text-transform: uppercase;
           margin: 40px 0 10px 0;
       }


       .define-skyScrapper-container-mobile{
        display: flex;
       
        width: 100%;
        max-height: 600px;
        height: 100%;
        margin-top: 40px;
        justify-content: center;
       
      }


      .define-remark-mobile{
        display: block;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 40px 0 10px 0;
    }

    .define-miniArticle-container-mobile{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 30px;

        img{
            width: 100%;
        }
    }


    
    .define-miniArticle-mobile{

        background: #f5f5f5;
       padding-bottom: 10px;
        margin-bottom: 15px;
       
    
    
        img{
            width: 90%;
        }
    }


    .define-miniarticle-line-mobile{
        height: 8px;
        width: 95%;
        
        background: #b3b3b3;
    }


    
    .main-container-define-articleContainer{
       
        width: 100%;
        display: flex;
        margin-top: 30px;
    
    }

    .define-miniArticle-container{
        display: none;
    }

    .define-skyScrapper-container{
        display: none;
      }


      .define-mainArticle-text-container-text{
        font-size: .950em;
        text-align: none;
        
    }


    .adcheck-main-gos-subarticle-text{
        font-size: .95em;
        font-weight: 600;
    }



    .define-ad_container{
        position: relative;

        width: 100%;
       
    }


}