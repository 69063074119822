.MobileMainBody{
    background-color: #edeef0;
    min-height: 100vh;
    width: 100%;

}

.MobileFilter{
    max-width: 1000px;
    width: 100%;
  //margin: 400px auto;
  margin:  auto;
  height: 250px;
  box-shadow: 0px 0px 6px -1px black;
  background: white;
  display: flex;
  border-radius: 5px;
}

.MobileCategory{
   
    width: 10%;
    margin-top: -1px;
}

.MobileFilterMenu{
    
    width: 93%;
   
}

#backbtn{

  width: 80px;
  height: 35px;
  position: absolute;
  margin: 10px 0 0 20px;
  border-radius: 5px;
  background-color: #f60;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span{
    color: white;
    font-weight: bold;
  }
}


#articleBody{
    margin: auto;
    padding: 20px 0 20px 0;
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#articleImgBlock{
    height: 90%;
}


.Menus{
    width: 100%;
    height: 80%;

    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    
}

.CategoryIcon{
    height: 61px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
}

.Selector{
    width: 170px;
  height: 35px;
  font-weight: bold;
  margin-top: 5px;
  
}



.SelectorDiv{
    width: 170px;
    height: 35px;
    font-weight: bold;
    font-size: 14px;
    
    margin-left: 20px;
    margin-top: 20px;
  
}

.Special{
    border: 1px solid gray;
    border-radius:5px;
}

.BuyLeasing{
    display: inline-block;

    width: 49%;
  height: 27px;
  
  text-align: center;
  padding-top: 6px;

}

#buy{
    border: 1px solid #ffbd8a;
    background-color: #fff5eb;
    border-radius:5px;
}

.SelectorBtn{
   // background-color: #ff6600;
    background-color: gray;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    border: 1px solid gray;
    margin-left: 0;
    margin-top: 0;
    transition: 0.3s all;
    
   
}

.MoreFilter{

    height: 20%;
    display: flex;
  justify-content: space-between;
}

.CheckBox{
    transform: scale(1.5);
    margin-left: 20px;
    margin-top: 15px;
}

.CheckBoxLabel{
    margin-left: 10px;
    font-size: 13px;
}

.Filter{
    margin-top: 10px;
    margin-right: 35px;
    font-size: 14px;
    color: gray;
    font-weight: bold;
    height: 20px;
    display: flex;
    align-items: center;
    width: 120px;
    justify-content: space-around;
}

#carIcon{
    
    fill: #ff6600;
}

#CategoryIconCar{
    background-color: white;
    border-right: none;
}

#article{

  
    margin: auto;
    height: auto;

    img{
        width: 100%;
    }

}

.Ad1Textblock{
    position: absolute;
    top: 67%;
    width: 100%;
    height: auto;
   
}

.Ad1Text1{
    height: 20%;
  width: 65%;
 
  margin: auto;

}

.lefttext1small{
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    opacity: 0;
    transition: 1s all;
    margin: 10px auto auto auto;
}

.lefttext1cta{
    width: 60% !important;
  margin-top: 15px;
  opacity: 0;
  transition: 1s all;
}

.FootText{
    color: white;
    font-size: 16px;
    text-decoration: underline;
    margin-top: 40px;
}

.Ad1Cta{
    height: 20%;
  width: 40%;
}

.lefttext1{
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    opacity: 0;
    transition: 1s all;
}

.Audi1AdImg{
    width: 100%;
    height: 93vh;
    object-fit: cover;
    transition: 3s all;
    transform: scale(1.2);
}

.Audi4AdImg{
    width: 100%;
    height: 93vh;
    object-fit: cover;
    transition: 0.5s all;
    
}


.MobileOptions{
    max-width: 1000px;
  width: 100%;
  
  margin: auto;
    margin-top: auto;
  height: 200px;
  margin-top: 20px;

  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.Option{
  display: flex;

  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 6px -1px black;
}

.OptionBlock{

max-width: 155px;
width: 100%;
height: 100%;

position: relative;
margin-left: 30px;

img{
    max-width: 136px;
    max-height: 136px;
    width: 100%;
    height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
    
}
}

.OptionText{

    margin-left: 10px;
    margin-top: 25px;

    span{
        font-weight: bold;
        font-size: 20px;
        
       
    }
}

.OptionText2{
    margin-top: 10px;
}

.OptionBtn{
    border: 1px solid gray;
    border-radius: 5px;
    color: #094559;
    max-width: 200px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-top: 20px;
}


.MobileMagazin{
    max-width: 1000px;
    width: 100%;
  margin: 20px auto 0 auto;
  height: auto;
  box-shadow: 0px 0px 6px -1px black;
  background: white;
  display: flex;
  border-radius: 5px;
  position: relative;
  
}

.Article1{

    width: 50%;
}

.Subarticle{
    
 
  display: flex;
  
}

.Subfirst{
    margin: 50px auto 0 auto;
}

.SubarticleText{
    width: 65%;
}

.SubarticleImg{
    width: 35%;

    img{
        width: 90%;
        object-fit: cover;
        height: 90%;
    }
}


.ArticleText{
    
    margin: 15px auto 0 auto;
  width: 90%;
}

.Article1Block{
    width: 90%;
  height: 210px;
  margin: 50px auto 0 auto;

  img{
    width: 100%;
  height: 100%;
  object-fit: cover;
  }
}

.BlueText{
    color: #066578;
    font-weight: bold;
   
}

.MagazinHeadText{
    font-weight: bold;
    margin:0px 0 0 0px;
    
}

.MagazinText{
    font-size: 14px;
    font-weight: 600;
    margin:15px 0 10px 0px;
    width: 95%;
}

.MagazinTitle{
    position: absolute;
    font-weight: bold;
    margin: 5px auto 0 20px;
    border-bottom: 1px solid black;
  width: 95%;
  height: 30px;
}

.MobileDealer{
    max-width: 1000px;
    width: 100%;
    
    margin: 20px auto 0 auto;
    height: 250px;
    box-shadow: 0px 0px 6px -1px black;
    background: white;
    position: relative;
    border-radius: 5px;
}

.Dealer{
    position: relative;
    height: 12%;
    line-height: 30px;
}

.MobileDealerBlock{
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    height: 88%;
    width: 95%;
    margin: auto;
}




.DealerBlock{
    
    align-items: center;
  display: flex;
}

.DealerBlockImg{
    width: 25%;
  border: 1px solid gray;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 85%;
  }
}

.DealerBlockText{
    margin-left: 10px;
}

.DealerBlockHead{
    font-weight: bold;
  font-size: 13px;
}

.DealerText{
    font-size: 11px;
}


.MobileBlock{
    max-width: 1000px;
    width: 100%;
    margin: 20px auto 0 auto;

    box-shadow: 0px 0px 6px -1px black;
    background: white;
    position: relative;
    border-radius: 5px;
}

.Block{
    position: relative;
    padding-top: 20px;
}


.BlockArticleLarge{
    
    display: flex;
    height: 350px;
}

.BlockArticleLargeImg{
 
    width: 50%;


   
}


.BlockArticleBlockText{
    display: flex;
  flex-direction: column;
  width: 47%;

}

.BlockHead{
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 20px 0;
}

.BlockText{
    font-size: 13px;
    width: 90%;

    span{
        color: #ff6600;
        display: block;
        font-weight:700;
        text-decoration: underline;
    }
}


#BlockTextDesc{
    font-size: 13px;
  font-weight: 600;
  margin-top: 10px
}


.BlockArticleSmall{

display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    margin-top: 20px;
}


.BlockAricle{

    display: flex;
    padding-bottom: 50px;

}

.BlockArticleSmallImg{
    
    width: 30%;
  height: auto;

    img{
        width: 100%;
        height: auto;
    }
}

.BlockArticleSmalltext{

  width: 70%;
  height: auto;
  margin-left: 30px;
}


.X21{
    font-size: 19px;
}


.X20{
    font-size: 16px;
    margin: 10px auto 20px auto;
}

.BlockEndText{
    text-align: center;
    font-size: 14px;
}


.X19{
  margin-top: 20px;
  font-weight: bold;
}

#blockEndtextlast{
    padding-bottom: 50px;
    font-weight: bold;
}


.MobileMarken{
    max-width: 1000px;
    width: 100%;
  margin: 20px auto 0 auto;
  height: auto;
  box-shadow: 0px 0px 6px -1px black;
  background: white;
  
  border-radius: 5px;
  position: relative;

 
}

.MarkLists{
    display: grid;
 
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)auto;
  width: 100%;
  font-size: 14px;
  padding-bottom: 20px;
 
}

.MarkeBlock{

    
   // display: flex;
   // flex-direction: column;
   // align-items: start;
    margin: 20px 0 0 20px;
    width: 155px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
}


.MarkeHead{
    font-weight: bold;
    font-size: 15px;
}

.Check{
    padding-right: 10px;
}


.MobileFooter{
  
  width: 100%;
  background-color: #101114;
  margin: auto;
    margin-top: auto;
  height: auto;

  z-index: 1;
    position: relative;

}

.FooterBlock{
  
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 1000px;
  display: flex;
  padding-bottom: 100px;
}

.FooterIconsSelector{
    display: flex;
  flex-direction: column;
  margin-top: 80px;
 
  width: 30%;

}

.Impressum{
    
  width: 70%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
}



.SelectorDivFooter{
    width: 170px;
    
    font-weight: bold;
    font-size: 14px;
    
    
    margin-top: 10px;
}


.SelectorFooter{
    max-width: 250px;
    width: 100%;
    height: 35px;
    font-weight: bold;
    margin-top: 5px;
    color: white;
    background-color: #35373d;
    border-radius: 5px;
    border: 1px solid #35373d;
}


.z_K9l{
    margin-left:5px;
}



.FooterListBlock{

    
     margin: 20px 0 0 20px;
     max-width: 155px;
     width: 100%;
     color: #83888f;
     line-height: 30px;
     font-size: 14px;
     

 
     ul {
         list-style-type: none;
         margin: 0;
         padding: 0;
       }
 }

 .FooterListHead{
    color: white;
    font-size: 15px;
    font-weight: bold;
 }


 .MobileCarFilter{
    max-width: 800px;
    width: 99%;
 position: relative;
  //margin:  auto;
  height: 200px;
 // box-shadow: 0px 0px 6px -1px black;
 border: 1px solid #c1c2c3;
  background: white;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  
 }

 .MobileCarFilterHead{
   
    font-weight: bold;
    font-size: 19px;
    height: 20%;
    display: flex;
  align-items: center;
  padding-left: 20px;
 }

 .MobileCarFilterPanel{
 
    height: 80%;
    padding-left: 20px;
    position: relative;
 }

 .MobileCarFilterSelector{
    width: 170px;
    height: 35px;
   
   
 }

 .Panel1{
    background: #edeef0;
    max-width: 275px;
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 15px;

    img{
        width: 4%;
        margin-left: 15px;
    }
 }

 .Sort{

  width: 280px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 25px;

  label{
    font-size: 14px;
  font-weight: bold;
  }
 }


 .MobileCarFilterPanelBtn{
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 45px;
    background: #f60;
    width: 165px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
 }

 .MobileCarSidePanel{
    height: auto;
    padding-bottom: 40px;
  width: 230px;
  background: white;
  pointer-events: none;
 }

 .Components{
   
    width: 1150px;
    margin: auto;
    display: flex;
   
    flex-direction: column;
 
 }

 .Panel-Filter-Item{
    width: 1070px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #edeef0;
    
   
 }



 .ComponentsDiv{
    
    display: flex;
  
 }

 .SideAd{
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    height: 100%;
    //margin-top: -100px;


}



.LogoDiv {
    height: 20%;
    position: absolute;
    width: 100%;
    background-image: linear-gradient(to top, rgba(102, 150, 186, 0) 0%, rgb(102, 150, 186) 50%);
    z-index: 1;
    //margin-top: 75px;
  }

  .Audi1Container-img{
    overflow: hidden;
  }

#Logo{
    width: 40%;
  height: 100%;
  position: absolute;
  top: 25%;
  object-fit: cover;
}

#Logo2{
    width: 40%;
    height: 100%;
    position: absolute;
    top: 25%;
    right: 0;
    object-fit: cover;
}


 
 .MobileCarItem{
    width: 99%;
    background: white;
    height: 320px;
    display: grid;
    margin-top: 5px;
    grid-template-columns: 25% 1fr;
    border: 1px solid #c1c2c3;

 }

 .Audi3MobileAd-container-mobile{
    display: none;
 }

 .Items{
    max-width: 800px;
  width: 100%;

  grid-area: items;
 }

 .ImgDivItem{
    
    display: flex;
    justify-content: center;
 }

 .ItemInfo{
  
    display: flex;
    flex-direction: column;

}



.Info{

 
    display: flex;
   
    height: 40%;
   
}

.DispayNoneClass{
    display: none;
}


.ImgAd{
    margin-top: 20px;
    width: 80%;

    img{
        width: 100%;
    }
}


.ItemHead{
    font-weight: bold;
    margin-top: 15px;
}

.ItemHeadDiv{
    width: 70%;
}

.ItemText{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}

.Price{
    width: 30%;

    div{
        font-weight: bold;
  font-size: 15px;
  margin-top: 15px;
  float: right;
  margin-right: 10px;
    }
}

.InfoBtnKontakt{
    background: #094559;
}

.InfoBtnPark{
    background: white;
}

.Second{
    display: flex;
    flex-direction: column;
   
    height: 60%;
}

.InfoBtns{
    display: flex;
    //margin-bottom: 20px;
    width: 100%;
   
    justify-content: end;
    height: 30%;
}

.DealerDiv{
 
    height: 70%;
    display: flex;
    align-items: center;
}


.InfoBtnKontakt{
    
    color: white;
    width: 100px;
    
}

.InfoBtnPark{
   
    border: 1px solid black;
    width: 80px;
    color: #094559;
    
    
}

.InfoBtn{
   
    border-radius: 5px;
    height: 35px;
    font-weight: 700;
    font-size: 14px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Details{
    
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
  

   
}

.YgmFC{
   margin-right: 10px;
}

.KR24{
    display: flex;
    align-items: center;
}

.XK6GY{
    margin-left: 20px;
    display: flex;
    align-items: center;
}


.DealerTexts{
  font-size: 14px;
  margin-left: 15px;
  line-height: 25px;
}

.DealerTextsHead{
font-weight: bold;
}



.DealerImg{
    width: 10%;

    img{
        width: 100%;
    }
}

.Line{
    width: 85%;

  border: none;
  height: 1px;
  background: gray;            
}

.NewLabel{

    font-size: 14px;
  font-weight: bold;

    span{
        background: #0996b3;
  border-radius: 5px;
  font-size: 11px;
  color: white;
  font-weight: bold;
  width: 35px;
  display: inline-block;
  height: 20px;
  text-align: center;
  font-style: italic;
  padding-top: 4px;
  margin: 10px 10px 0 20px;
    }

    ul{

        font-size: 14px;
        font-weight: 600
    }
}

.SideMenu1Btn2{
    border-radius: 5px;
    margin: auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    width: 90%;
    height: 40px;
    background: #ff6600;
    margin-top: 20px;
}


.SideMenu1Btn{
    border-radius: 5px;
    margin: auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    width: 90%;
    height: 30px;
    background: #094559;
}


.SideMenu2Head{
    font-weight: bold;
    font-size: 14px;
    margin: 25px 0 0 15px;
}

.SideMenu2Text{
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 20px 15px;
    position: relative;

    span{
        color: #ff6600;
        text-decoration: underline;
        position: absolute;
        right: 15px;
    }
}


.SideMenu1SelectorDiv{
    margin-top: 20px;
}

.SideMenu1SelectorDivLabel{
    margin-left: 15px;
    font-size: 14px;
  font-weight: bold;
}

.SideMenu1Selector{
    width: 80px;
  height: 30px;
  margin-top: 10px;
  background-color: white;
  color: gray;
  border: 1px solid gray;
  border-radius: 5px;
  
}

.SideMenu1Selectors{
  
    display: flex;
    justify-content: space-around;
}

.SuchSP{
    margin: 10px 0 0 65px;
    font-size: 14px;
    text-decoration: underline;
    color: #f60;
}

.Colors{
    width: 100%;
 
    
    margin-top: 15px;
   
}

.Color{
    width: 40px;
    height: 40px;
 
    display: inline-block;
    margin-left:5px;
    border-radius: 5px;
}
.col0{
    background-color: rgb(51, 51, 51);
    }
.col1{
    background-image: linear-gradient(to bottom right,#000,#fff,#000)
}
.col2{
    background-color: #dbb67f
}
.col3{
    background-color: #ababab
}
.col4{
    background-color: #855e1b
}
.col5{
    background-color: white;
    border: 1px solid gray;
}
.col6{
    background-color: #ff7f23
}
.col7{
    background-color: #376edb
}
.col8{

    background-color: #f5d300
}
.col9{
    background-color: #ff4040
}
.col10{
    background-color: #7eba30
}
.col11{
    background-color: #bebebe
}
.col12{
    background-color: #9361ff
}

.SideMenuRadio{
    margin-top: 10px;
}

.SideMenu2Check{
    display: block;
}


.Link{
    text-decoration: none;
  
  width: 170px;
  height: 35px;
  margin-top: 43px;
  margin-left: 20px;
  border-radius:5px ;
}


.Ad{
   // border: 1px solid black;
    height: 100vh;
    position: absolute;
    width: 100%;
}

.AdLay{
  //  border: 1px solid red;
    height: 100vh;
    width: 100%;

}

.topDivAd{
   // border: 1px solid red;
    height: 100vh;
    width: 100%;
    
}
.leftDivAd{
   // border: 1px solid red;
    height: 100vh;
    width: 100%;
    
}
.rightDivAd{
    //border: 1px solid red;
    height: 100vh;
    width: 100%;
    
}

// Audi Ad 2

@font-face{
    font-family: 'Audi';
    src: url('../../data/308-font.ttf');
    font-weight: bold;
    font-style: normal;
}

#articleBlock{
    height: 300px;
   
    z-index: 1;
}

#articleBlockAudi3{
    height: 300px;
    z-index: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
}


.Audi3MobileAd-container{
    display: block;
  
    width: 70%;
    height: 90%;
}

.Audi2-Main-Container{
    position: fixed;
    width: 100%;
    
}

#containerImg{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    margin-top: -30px;
    transform: scale(1.1);
}

#containerImg-audi3{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    margin-top: -60px;
}



.Panel-Filter-Item-Audi2{
    width: 1070px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #edeef0;
    z-index: 1;
   
 }

 .Audi2TopText{
    
    color: white;
    font-family: "Audi";
    position: relative;
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    align-items: end;
 }



 #audi2arrow{
    width: 50px;
    height: 15px;
    
 }
 #audi2arrow2{
    width: 15px;
    height: 15px;
    display: block;
    margin: auto;
    transform: rotate(90deg);
 }
 #audi2arrow3{
    width: 50px;
    height: 15px;
    transform: rotate(180deg);
 }


 .Audi2TopTextRight{

    color: white;
    font-family: "Audi";
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 15px;
    position: absolute;
    width: 100%;
    margin-top: 20%;
    align-items: center;
    height: 100px;
}


 #audi2arrow-right{
    width: 30px;
    height: 15px;
    
 }
 #audi2arrow2-right{
    width: 30px;
    height: 15px;
  
    transform: rotate(90deg);
 }
 #audi2arrow3-right{
    width: 30px;
    height: 15px;
    transform: rotate(180deg);
 }

 /*
 .Audi2Logo{
    z-index: 1;
    width: 13%;
    position: fixed;
    margin: 30px 0 0 3%;
    height: 100%;
 
 }*/

 .Audi2Logo{
    z-index: 1;
    width: 55%;
    position: absolute;
    margin: 27% 0 0 50%;
    height: 100%;
    transform: translateX(-50%);
 
 }


 #audi2logo{
    width: 100%;
 }

 .SideAdAudi2{
    z-index: 1;
    position: sticky;
    height: 100vh;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transition: 0.5s all;
 }

 #Audi2text1{
    margin-top: 20vh;
    color: white;
    font-size: 20px;
    font-family: "Audi";
    transition: 0.5s all;
 }

 #Audi2text2{
    margin-top: 20px;
    color: white;
    font-size: 12px;
    font-family: "Audi";
    width: 80%;
    transition: 0.5s all;
 }

 #Audi2text3{
    margin-top: 35px;
    color: white;
    font-size: 16px;
    font-family: "Audi";
    transition: 0.5s all;
 }
 #Audi2text4{
    width: 70%;
    margin: auto;
    font-size: .800rem;
    color: white;
    margin-top: 30%;
    position: sticky;
    top: 40%;
 }

 #Audi2text-special{
   margin-top: 20px;
    color: white;
    font-size: 20px;
    font-family: "Audi";
    opacity: 0;
  position: absolute;
    transition: 0.5s all;
 }

 #Audi2text4-container{
    margin-top: 20vh;
    z-index: 1;
    width: 100%;
    transition: 1s all;
 }

 #Audi2text4-container-special{
    position: absolute;
    z-index: 1;
    width: 60%;
    transition: 1s all;
    font-size: .800rem;
    color: white;
    bottom: 10vh;
    margin-left: 50%;
    transform: translateX(-50%);
 }

 #Audi2cta{
    margin-top: 45px;
    transition: 0.5s all;
 }

 #Audi2-container-Img-right{
    display: none;
 }

 .Audi2MobileAd-container{
   display: none;
}

.Audi3MobileAd-Img{
    width: 100%;
}


.Audi2-container-Img-Left{
    transition: 0.5s all;
    opacity: 0;
}

.Audi2-side-img{
    object-fit: cover;
    height: 100vh;
    width: 100%;
 }

@media  (max-width: 1750px){
.MobileLogoText{
    font-size: 9px;
}

.Items{
    max-width: 690px;
  width: 100%;

  grid-area: items;
 }

 .Panel-Filter-Item{
    width: 935px;
  
 }
 .Panel-Filter-Item-Audi2{
    width: 935px;
  
 }

 .Audi2-container-Img{
    overflow: hidden;
    width: 100%;
   
 }

 .Audi2-side-img{
    object-fit: cover;
    height: 100vh;
    width: 100%;
 }

 #Audi2text1 {
   
    margin-top: 13vh;
    
}


}

@media  (max-width: 1600px){
    #Audi2text1{
        font-size: 14px;
        margin-top: 30vh
    }

    #Audi2text2 {
     
        font-size: 9px;
      
    }

    #Audi2text3 {
       
        font-size: 11px;
     
    }

    #Audi2text-special {
        margin-top: -80px;
    }

    #Audi2text4{
        width: 85%;
        font-size: 0.7rem;
        top:55%
    }
}


@media  (max-width: 1280px){
    .Panel-Filter-Item {
        width: 775px;
    }

    .MobileLogoText{
        display: none;
    }

    .Panel-Filter-Item-Audi2 {
        width: 775px;
    }

    .itemAudi2{
        padding-left: 25px;
    }

    .MobileCarSidePanel {
        height: auto;
        padding-bottom: 40px;
        max-width: 168px;
        width: 100%;
        background: white;
    }

    .Selector {
        width: 125px;
        height: 35px;
        font-weight: bold;
        margin-top: 5px;
    }

    .Components {
        width: 770px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .Items {
        max-width: 690px;
        width: 100%;
        padding-left: 25px;
 
    }

    .MobileCarFilter {
        max-width: 800px;
        width: 99%;
        position: relative;
        height: 200px;
        border: 1px solid #c1c2c3;
        background: white;
        display: flex;
        border-radius: 5px;
        flex-direction: column;
    }

    .MobileCarItem {
        width: 99%;
        background: white;
        height: 320px;
        display: grid;
        margin-top: 5px;
        grid-template-columns: 25% 1fr;
        border: 1px solid #c1c2c3;
    }

    .ImgAd{
        img{
            width: 80%;
            margin-left: 15px;
        }
    }

    .ItemHeadDiv {
        width: 80%;
    }

    .DealerTexts{
font-size: 12px;
    }

    .Price {
        width: 20%;
    }

 

    .NewLabel span {
      
        margin: 10px 10px 0 8px;
    }

    .NewLabel {
        font-size: 10px;
      
    }


    #Audi2text1 {
   
        font-size: 14px;
        margin-top: 24vh;
        
    }

    #Audi2cta {
        margin-top: 15px;
        
    }

    #Audi2text2 {
       
        font-size: 8px;
        
    }

    #Audi2text-special {
       
        font-size: 14px;
        
    }

    #Audi2text3 {
       
        font-size: 11px;
        
    }

    #Audi2text4-container-special {
  
        font-size: 0.7rem;
    }


    #Audi2text4 {
    
        font-size: 0.6rem;
        
    }

    .componentsAudi3{
        width: 890px;
    }

    .itemsAudi3{
        max-width: 690px;
    }

}


@media  (max-width: 480px){


    .MobileLogoText{
        display: block;
        }
        


    #lupe{
        margin-right: 15px;
        
    }

    .MoreFilter{
        display: none;
    }

    .Filter{
        
        display: none;
       
    }

.CheckBox{
    margin-left: 10px;
} 
   
    #articleBody{
        width: 100%;
    }

    .Panel-Filter-Item{
        max-width: 1070px;
        width: 100%;    
       
     }
     .Panel-Filter-Item-Audi2{
        max-width: 1070px;
        width: 100%;    
       
     }

     .Audi2-Main-Container{
        display: none;
     }

     .Audi2Logo{
        display: none;
     }

     .SideAdAudi2{
        display: none !important;
     }
     #articleBlock{
        display: none !important;
     }

    .SideAd{
      display: none;
    
    }

    .Audi2MobileAd-container{
        display: block;
        width: 100%;
        height: auto;
    }

    .Audi2MobileAd-Img{
        width: inherit;
    }

    .SelectorDiv{
       width: auto;
       display: inherit;
    }

    #articleBlockAudi3{
       display: none;
    }

    .Audi3MobileAd-container-mobile{
       display: block;
       width: 100%;
        height: auto;
        padding: 20px 0 20px 0;
     }
    .Audi3MobileAd-Img-mobile{
        width: inherit;
        height: inherit;
     }

    .MobileFilter{
        width: 100%;
      
      height: 475px;
      box-shadow: 0px 0px 6px -1px black;
      background: white;
      flex-direction: column;
      align-items: center;
  
    }

    .Items{
        padding: 0;
    }

    .MobileCategory{
   
        width: 90%;
      
        display: flex;
    }

    .CategoryIcon{
      width: 100%;
      height: 35px;
      margin-top: 20px;
    }

    .MobileFilterMenu{
    
        height: 100%;
        width: 100%;
       
    }


    .MagazinTitle{
        margin-left: 50%;
        transform: translateX(-50%);
        width: 90%;
        margin-top: 5px;
        font-size: 15px;
        line-height: 25px;
    }


    .Menus{
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        position: relative;
    }

    .Selector{
        width: 140px;
     
    }

    .SelectorBtn{
       width: 100%;
       justify-content: center;
       
    }

    .CheckBoxLabel{
        font-size: 10px;
    }

    #article{
        height: 0px;
    }



    .FooterListHead{
        margin-left: 10px;
     }

     .FooterListBlock{

   
    
        ul {
            margin-left: 10px;
          }
    }


    .MobileOptions{
        max-width: 1000px;
      width: 100%;
      
      margin: auto;
        margin-top: auto;
      height: 200px;
      margin-top: 20px;
    
      display: grid;
      column-gap: 20px;
      grid-template-columns: none;
      height: auto;
    grid-template-rows: 1fr 1fr;
    }
    
    .Option{
      display: flex;
      border: 0px solid black;
      border-radius: 0px;
      background: white;
      height: 245px;
      position: relative;
      box-shadow: 0px 0px 0px 0px;
    }
    
    .OptionBlock{
    
    max-width: 120px;
    width: 100%;
    height: 100%;
    
    position: relative;
    margin-left: 15px;

   
    
    img{
        max-width: 110px;
        max-height: 110px;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 10%;
        transform: translateY(0);
       
    }
    }
    
    .OptionText{
    
        margin-left: 10px;
        margin-top: 25px;
    
        span{
            font-weight: bold;
            font-size: 18px;
            
           
        }
    }
    
    .OptionText2{
        margin-top: 10px;
        font-size: 13px;
        width: 150px;
    }
    
    .OptionBtn{
        border: 1px solid gray;
        border-radius: 5px;
        color: #094559;
        max-width: 90%;
    
        height: 30px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-top: 20px;

        position: absolute;
        bottom: 10%;
        right: 50%;
        transform: translateX(50%);
    }


    .MobileMagazin{
     flex-direction: column;
      
    }

    .Article1{

        width: 100%;
    }

    .Subfirst {
        margin: 25px auto 0 auto;
    }


    .SubarticleImg{
        width: 85%;
    
    margin-left: 15px;
    }

    .MagazinText{
        font-size: 13px;
        font-weight: 400;
        margin: 15px 0 10px 0px;
        width: 95%;
    }

    .Subarticle{
    
 
        display: flex;
        margin: 15px auto 0 auto;
        
      }

      .MobileDealer{
        height: auto;
      }

      .MobileDealerBlock {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        height: 88%;
        width: 95%;
        margin: auto;
    }


    .DealerBlockImg {
        
       
        width: 70px;
    height: 70px;
        
    }

    .DealerBlock {
        margin-top: 10px;
    }


    .BlockArticleSmall{

        display: block;
          
        }

        .BlockArticleLarge{
    
            display: flex;
            height: auto;
            flex-direction: column-reverse;
        }

        .BlockArticleLargeImg{
 
            width: 100%;
        
           img{
            width: 100%;
           }
        }

        .BlockArticleBlockText{
            width: 90%;
            margin-top: 10px;
            padding: 0 15px 0 15px;
        
        }

        #BlockTextDesc {
            font-size: 13px;
         
            margin-top: 5px;
        }

        
            .Block {
                height: 50px;
            }
        
   

            .MarkLists{
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            }


            .FooterBlock{
                flex-direction: column-reverse;
             
            }

            .Impressum{
                width: 100%;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                
            }

            .FooterListBlock {
               
                 margin: 20px 0 0 0px;
              
            }

            .FooterIconsSelector {
                width: 90%;
                margin: 40px 0 0 20px;
            }


            .Components {
                width: 100%;
            }

            .MobileCarSidePanel{
                display: none;
            }

            .MobileCarItem {
             
                width: 100%;
                display: flex;
                position: relative;
                border: none;
                margin: 0px 0 20px 0;
                box-shadow: 0px 1px 3px black;
               
            }

            .ImgDivItem {
                width: 50%;
            }

            .ImgAd {
               width: 80%;

               img{
                width: 100%;
                margin-left: 0;
               }
            }

            .ItemText{
                font-weight: normal;
            }

            .ItemInfo{
                width: 60%;
            }

            .Second{
                position: absolute;
                left: 15px;
                width: 95%;
                bottom: 0;
                
                height: auto;

            }

            .InfoBtn{
                height: 30px;
                margin-top: 10px;
            }

            .DealerDiv{
                margin-bottom: 10px;
            }

            .ItemText{
                width: 90%;
                position: absolute;
                left: 15px;
                top: 40%;
            }

            .Details{
               display: none;
            }

            .Info{
                width: 95%;
            }

            .ItemHeadDiv{
                width: 100%;
            }

            .ItemHead{
                font-size: 15px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
            }

            .Price{
                position: absolute;
                top: 18%;

                div{
                    float: left;
                }
            }

            .InfoBtns{
                margin-bottom: 10px;
                border-top: 1px solid #a7a7a7;
            }

            .InfoBtnPark{
                margin-right: 10px;
            }

            .MobileCarFilter{
                height: 50px;
                border: none;
                border-top: 1px solid #c1c2c3;
                border-radius: 0px;
            }

            .MobileCarFilterHead{
                display: none;
            }

            .Sort{
                display: none;
            }

            .MobileCarFilterPanelBtn{
                display: none;
            }

            .Panel1{
               
                height: 25px;
              
                font-size: 12px;
                font-weight:normal;
                
            }

            .MobileHeadDiv{
                display: flex;
                align-items: center;
            }


            .Link {
                
                position: absolute;
                
                width: 90%;
                top: 95%;
            }
            
}  