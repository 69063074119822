.prime-footer{

  width: 100%;
  height: 800px;
  color: #6a6a6a;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #232f3e;
  flex-direction: column;
  
}

.prime-footer-backbtn{

width: 100%;
height: 45px;
color: white;
display: flex;
align-items: center;
justify-content: center;
font-size: 0.7vw;
font-weight: 600;
background: #37475a;
}


.prime-footer-block{
  width: 70%;
  height: 70%;
 margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;



}

.prime-footer-textblock{

  display: flex;

  justify-content: space-evenly;
}


.prime-footer-text_container{
  margin-top: 10px;
}

.prime-footer-text{
  margin: 20px 100px 0 0;

  color: white;
  width: 15%;
  font-size: 14px;
  line-height: 1.5vw;
}

.prime-footer-text-head{
  font-weight: bold;
  font-size: 17px;
  white-space: nowrap;
}

#prime-footer-service{
 border: 2px solid gray;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

}


.prime-footer-block-logo{
  border-top: 1px solid gray;
  width: 100%;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  

}

.prime-footer-block-logo-img{
width: 6%;
}

.prime-footer-block-logo-language{
   
  border: 1px solid white;
  width: 120px;
  display: flex;
  align-items: center;
  height: 35%;
  margin-left: 2%;
  color: white;
 
  img{
    width: 20%;
   
  }
}

#footer-logo-net{
  padding-right: 7%;
}

#footer-logo-flag{
  padding-right: 7%;
}



.prime-footer-block-end{
  background: #131a22;
  height: 25%;
  width: 100%;
 
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.prime-footer-text-head-block-end{
 
color: white;
white-space: nowrap;

}


.prime-footer-text-block-end{
 
  margin-top: 1%;
  width: 100px;
  margin-left: 2%;
  line-height: 20px;
  color: gray;
  
  font-size: 12px;
}

.prime-footer-icons{
  
  
 

  svg{
    margin-right: 25px;
  }
}