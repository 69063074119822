@font-face {
    font-family: "fact-variable";
    src: url("https://fonts.adobe.com/fonts/fact-variable");
  }



.chef-hightlight_article{

    width: 100%;
  
}

.chef-hightlight-article_slide{
 display: flex;
}

.chef-hightlight_article-slide{
    display:'flex';
    border:'1px solid red';
    justify-content: 'center';

}


.chef-hightlight_article-img_container{
    max-width: 620px;
    width: 100%;

    img{
        width: 100%;
    }
}

.chef-hightlight_article-text_container{
    width: 30%;
    margin-left: 25px;
    
}


.chef-hightlight_article-title{
    font-size: 32px;
    font-family: "Source Serif Pro", serif;
    font-weight: 600;
    margin-top: 15px;
    margin-block-start: 0;
    margin-block-end: 0;

}

.chef-hightlight_article-text{
    font-size: 16px;
    font-weight: "Source Sans Pro", Arial, sans-serif;
    font-weight: 400;
    margin-top: 15px;
    line-height: 25px;

}

.chef-hightlight_article-action{
    color: #3b8047;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-top: 35px;

    span{
        margin-left: 10px;
    }

}

#chef-hightlight-swiper{
   
    position: relative;
    display: flex;
}

#chef-hightlight-swiper::part(button-next) {
    color: #007aff;
    position: absolute;
    top: 100%; 
    right:calc(100% - 620px);
    margin-top: 15px;
    
    color: #a7c6ac;
   
    height: 25px;
    width: 25px;

   
    
  }


#chef-hightlight-swiper::part(button-prev) {
    color: #007aff;
    position: absolute;
    top: 100%; 
    margin-top: 15px;
    height: 25px;
    width: 25px;
    color: #a7c6ac;

  }



#chef-hightlight-swiper::part(pagination) {
    top: 100%;
    display: flex;
    margin-top: 15px;
    justify-content: space-evenly;
    width: 55%;
    background: transparent;

  }

  #chef-hightlight-swiper::part(bullet) {
    background: transparent;
    width: fit-content;
    color: black;
    text-decoration: none;
    opacity: 1;
    font-weight: 400;
   

  }
  #chef-hightlight-swiper::part(bullet-active) {
    background: transparent;
    width: fit-content;
    color: #3b8047;
    text-decoration: underline;
    font-weight: 500;

  }


  .chef-highlight2-container{
    display: flex;
    background: #fafaf8;
    width: 100%;
    margin-top: 150px;
    flex-direction: column;
    height: 560px;
  }

  .chef-highlight2-container_title{
    font-size: 30px;
    font-family: "Source Serif Pro", serif;
    font-weight: 600;
    padding: 25px 35px;
    position: relative;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
   

    span{
    position: absolute;
    right: 60px;
    color: #6ca075;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 16px;
    align-self: center;
    
    }
  }

  .chef-highlight2{
    display: flex;
    background: #fafaf8;
    width: 100%;
    justify-content: space-evenly;
    height: 100%;

  }
  .chef-highlight3{
    display: flex;
    background: #fafaf8;
    width: 100%;
    justify-content: space-evenly;
    height: 100%;

  }

  .chef-hightlight_article2_container{
    height: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: space-evenly;
}

.chef-hightlight_article2{
    height: 100%;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  
   
}

.chef-hightlight_article2-img_container{

    width: 100%;
    max-width: 300px;
    max-height: 375px;
    height: 100%;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}


.chef-hightlight_article2-text_title{
    font-size: 14px;
    color: rgb(178, 128, 75);
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    text-transform: uppercase;
}

.chef-hightlight_article2-text_desc{
    font-size:17px;
    color: black;
    font-weight: 550;
    font-family: "Source Sans Pro", Arial, sans-serif;
    margin-top: 5px;
    margin-left: 10px;
    line-height: 26px;
}

.chef-rating_container{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.chef-rating-value{
    margin-left: 5px;
    color: #595852;
    font-size: 13px;
    font-weight: 500;
}


.chef-hightlight_article2_container-block{
    height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  max-width: 300px;
  width: 100%;
  max-height: 375px;

}

.chef-hightlight_article2-left{
    background: #e2ece3;
    max-width: 204px;
    max-height: 143px;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 16px;
    
  
}
.chef-hightlight_article2-right{
    background: #e2ece3;
    max-width: 204px;
    max-height: 143px;
    width: 100%;
    height: 100%;
    align-self: end;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 16px;
    text-align: right;
    

  
}

.chef-hightlight_article2-text{
   font-family: "Source Serif Pro", serif;
   font-size: 32px;
   font-weight: 600;
  
   width: 80%;
   margin-top: 10px;
}

.chef-hightlight_article2Right{
float: right;
}

.chef-hightlight_article2-title{
   font-family: "Source Serif Pro", serif;
   font-size: 16px;
   font-weight: 400;
   text-transform: uppercase;
   
}





// Hihglight3



.chef-hightlight_article3_container{
    height: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: space-evenly;
}

.chef-hightlight_article3{
    height: 100%;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  
   
}

.chef-hightlight_article3-img_container{

    width: 100%;
    max-width: 300px;
    max-height: 375px;
    height: 100%;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}


.chef-hightlight_article3-text_title{
    font-size: 14px;
    color: rgb(178, 128, 75);
    font-weight: 700;
    font-family: "TT Chocolates", sans-serif;
    text-align: center;
    text-transform: uppercase;
}

.chef-hightlight_article3-text_desc{
    font-size:17px;
    color: black;
    font-weight: 550;
    font-family: "Source Sans Pro", Arial, sans-serif;
    margin-top: 5px;
    margin-left: 10px;
    line-height: 26px;
}




.chef-hightlight_article3_container-block{
    height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  max-width: 300px;
  width: 100%;
  max-height: 375px;

}

.chef-hightlight_article3-left{
    background: #e2ece3;
    max-width: 204px;
    max-height: 143px;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 16px;
    
  
}
.chef-hightlight_article3-right{
    background: #e2ece3;
    max-width: 204px;
    max-height: 143px;
    width: 100%;
    height: 100%;
    align-self: end;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 16px;
    text-align: right;
    

  
}

.chef-hightlight_article3-text{
   font-family: "Source Serif Pro", serif;
   font-size: 32px;
   font-weight: 600;
   margin-block-start: 0;
   margin-block-end: 0;
   width: 80%;
   margin-top: 10px;
}

.chef-hightlight_article3Right{
float: right;
}

.chef-hightlight_article3-title{
   font-family: "Source Serif Pro", serif;
   font-size: 16px;
   font-weight: 400;
   text-transform: uppercase;
   margin-block-start: 0;
   margin-block-end: 0;
}



.chef-article-main-desk-head{
    font-size: 15px;
    color: rgb(178, 128, 75);
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
}

.chef-article-main-desk-text{
    font-size: 18px;
    text-align: center;
    color: black;
    font-weight: 800;
    margin-top: 10px;
    line-height: 26px;
    margin-block-start: 0;
    margin-block-end: 0;
   
}





// teaser items


.chef-artikel_teaser-items_container{
    width: 100%;   
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    height: 370px;

}

.chef-artikel_teaser-items-title{
   
  margin-left: 25px;
  font: 600 32px/36px "Source Serif Pro",serif;
  letter-spacing: .008em;
  margin-block-start: 0;
  margin-block-end: 0;
  
}

.chef-artikel_teaser-items-body{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
   // margin-top: 60px;
  
}

.chef-artikel_teaser-items2-body{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
   // margin-top: 60px;
  
}

.chef-article_teaser-items-desk-head{
    font-size: 16px;
    font-family: "Source Sans Pro", Arial, sans-serif;
    margin-block-start: 0;
    margin-block-end: 0;
}

.chef-article_teaser-items-desk-text{
    font-size: 20px;
    color: black;
    font-weight: 600;
    margin-top: 5px;
    line-height: 26px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-block-start: 5px;
    margin-block-end: 0;
}





.chef-article_teaser-items{
    display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 300px;
        margin-top: 40px;
        max-height: 250px;
        height: 100%;
        width: 100%;
  
}


.chef-article_teaser-items-img-container{
    height: 100%;
    width: 100%;
    max-height: 195px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}


// teaser items2



.chef-article_teaser2-items-desk-text{
    font-size: 17px;
   margin-block-start: 0;
   margin-block-end: 0;
    color: black;
    font-weight: 600;
    margin-top: 5px;
    line-height: 26px;
}

.chef-article_teaser2-items-desk-head{
    margin-block-start: 0;
    margin-block-end: 0;
}


.chef-article_teaser2-items{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 220px;
    margin-top: 40px;
    height: 385px;
    width: 100%;
}


.chef-article_teaser2-items-img-container{
    height: 100%;
    width: 100%;
    max-height: 275px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}



//block

.chef-artikel_teaser-block_container{
    width: 100%;
    margin-top: 200px;
   display: block;
 
    
}

.chef-artikel_teaser-block-body{
    width: 100%;
    margin-top: 50px;

}


.chef-blockItem-article_slide{
    display: flex;
    max-width: 219px;
    max-height: 125px;
    width: 100%;
    height: 100%;
    
}


.chef-blockItem-img_container{
    max-width: 620px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    img{
        width: 100%;
        border-radius: 15px;
    }
}

.chef-blockItem-name_container{
    position: absolute;
    bottom: 0;
    background: white;
    color: black;
    width: 90%;
    height: 32px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
}


#chef-teaserblock-swiper{
   
    position: relative;
    display: flex;
}

#chef-teaserblock-swiper::part(button-next) {
    color: #007aff;
    position: absolute;
    top: 100%; 
    right:calc(100% - 620px);
    margin-top: 15px;
    
    color: #a7c6ac;
   
    height: 25px;
    width: 25px;

   
    
  }


#chef-teaserblock-swiper::part(button-prev) {
    color: #007aff;
    position: absolute;
    top: 100%; 
    margin-top: 15px;
    height: 25px;
    width: 25px;
    color: #a7c6ac;
    left:calc(100% - 620px);

  }


  .chef-highlight3-mobile{
    display: none;
  }


  .chef-article-medrec{
  display: none;
}

@media screen and (min-width: 1536px) and (max-width: 1919px){


   
            
}




@media screen and (max-width: 1700px){
    .chef-hightlight_article2{
       
        max-width: 255px;

    }

    .chef-article_teaser-items{
        max-width: 255px;
    }

    .chef-hightlight_article3-left{
       
    margin-left: 15px;
      
    }
    .chef-hightlight_article3-right{
       
      margin-right: 15px;
    }

    .chef-blockItem-article_slide{
        max-width: 195px;
    }
}

@media screen and (min-width: 1320px) and (max-width: 1536px){

    .chef-hightlight_article2{
        height: 100%;
        width: 100%;
        max-width: 240px;
        display: flex;
        flex-direction: column;
      
       
    }

    .chef-article_teaser-items{
     
        max-width: 255px;
       
}

.chef-blockItem-article_slide{
 
    max-width: 200px;
   
    
}

.chef-hightlight_article3{
    
    max-width: 255px;
 

}

    

}

@media screen and (max-width: 1319px){

    

}


@media screen and (max-width: 1280px){

    #chef-hightlight-swiper::part(button-next) {
    
        right:calc(100% - 500px);
    
       
        
      }

      .chef-hightlight_article-img_container{
        max-width: 500px;
      
  
    }


    .chef-hightlight_article-text_container{
        width: 40%;
   
        
    }


    .chef-hightlight_article2{
      
        max-width: 225px;
    
      
       
    }


    .chef-article_teaser-items{
     
            max-width: 225px;
           
    }

    .chef-article_teaser2-items{
     
        max-width: 190px;
  
    }

    .chef-blockItem-article_slide{
 
        max-width: 190px;
       
        
    }

 
      
    .chef-article_container{
        max-width: 770px !important;
    }

}


@media screen and (max-width: 1120px){

    .chef-hightlight_article2{
  
        max-width: 195px;

    }

    .chef-hightlight_article2-img_container{
        max-height: 325px;
    }

    .chef-article_teaser-items{
     
        max-width: 200px;
       
}

.chef-blockItem-article_slide{
 
    max-width: 165px;
   
    
}

.chef-article_container{
    max-width:680px !important;
}



}

.chef-hightlight_article-title-mobile{
    display: none;
}

@media  (max-width: 576px){


    .chef-highlight2-container_title{
        font-size: 25px;
        font-family: "Source Serif Pro", serif;
        font-weight: 600;
        padding: 0;
        height: 80px;
        flex-direction: column;
        justify-content: space-evenly;

        span{
            position: relative;
            right: 0;
            align-self: baseline;
            font-size: 14px;
        }
      }

    .chef-artikel_teaser-items-title{
   
        margin-left: 0;
        font-weight: 600;
        font-size: 23px;
        font-weight: "Source Serif Pro",serif;
        letter-spacing: .008em;
        margin-top: 40px;
        margin-bottom: 15px;
      }
   
    #chef-hightlight-swiper::part(pagination) {
        display: none;
      }
    
      .chef-hightlight-article_slide{
        flex-direction: column;
       }

       .chef-hightlight_article-title{
        display: none;
    
    }

    .chef-hightlight_article-title-mobile{
        display: block;
        font-size: 19px;
        font-family: "Source Serif Pro", serif;
        font-weight: 600;
        margin-top: 15px;
    }

    .chef-main_article-container{
        padding: 0 10px;
    }


    .chef-hightlight_article-img_container{
        margin-top: 15px;
    }

    .chef-hightlight_article-text_container{
        width: 100%;
        margin-left: 0px;
        
    }

    #chef-hightlight-swiper::part(button-next) {
        display: none;
      }
    
    
    #chef-hightlight-swiper::part(button-prev) {
        display: none;
      }

      .chef-highlight2-container{
   
        margin-top: 60px;
        height: 500px;
   
      }


      #chef-teaserblock-swiper::part(button-next) {
        display: none;
      }
    
    
    #chef-teaserblock-swiper::part(button-prev) {
        display: none;
      }

      .chef-highlight2{
        overflow-x: auto;
        white-space: nowrap;
        scroll-snap-type: x mandatory;
        justify-content: start;
    
      }


      .chef-hightlight_article2_container{
        height: 100%;
        display: flex;
        flex: 0 0 auto;
        margin-right: 15px;
        align-items: center;
      
    }

    .chef-hightlight_article2-text_container{
        white-space: normal;
    }

    .chef-hightlight_article2{
        max-width: 215px;
    }

    .chef-hightlight_article2-img_container{
        max-width: 210px;
    }


    .chef-artikel_teaser-items-body{

        flex-direction: column;
        margin-top: 0px;
      
    }


    .chef-article_teaser-items{
        
            max-width: none;
            gap: 0;
            max-height: none;
            height: 100%;
            width: 100%;
            margin-top: 0;
            margin-bottom: 30px;
      
    }
    
    
    .chef-article_teaser-items-img-container{
        height: 100%;
        width: 100%;
        max-height: none;
    
       
    }


    .chef-artikel_teaser-items_container{
        
        margin-top: 20px;
        height: auto;
       
    
    }
    
 
    .chef-artikel_teaser-items2-body{


        flex-wrap: wrap;
        justify-content:space-around;
      
      
    }


    .chef-article_teaser2-items{
     
        max-width: 150px;
        height: 330px;
        width: 100%;
        flex: 1 1 calc(50% - 10px);
    }

    .chef-article_teaser2-items-img-container{
        height: 100%;
        width: 100%;
        max-height: 200px;
    }


    .chef-highlight3{
       display: none;
      }

      .chef-highlight3-mobile{
        display:flex;
        flex: 1 1 calc(50% - 10px);
        flex-wrap: wrap;
        justify-content: space-around;
        
        
      }

      .chef-hightlight_article3_container{
        flex: 0 0 auto;
        width: 152px;
        height: 200px;
       
      }


      .chef-hightlight_article3-left{
        flex: 0 0 auto;
    max-width: 120px;
    max-height: none;
    height: 170px;
    margin-bottom: 10px;
  
      
    }
    .chef-hightlight_article3-right{
        flex: 0 0 auto;
        max-width: 120px;
        max-height: none;
        height: 170px;
        
    
      
    }

    .chef-hightlight_article3-title{
        font-size: 12px;
    }

    .chef-hightlight_article3-text{
        font-size: 19px;
    }


    .chef-artikel_teaser-block_container{
     display: none;
        
    }

    .chef-article-medrec{
        display: block;
        margin-top: 60px;
    }

}

