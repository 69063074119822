.facebook_head{
    width: 100%;

    
}

.facebook_head_container{
    height: 100%;
    width: 100%;
}

.facebook-head-logo_container{
   
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.facebook-head-img{
  

    height: 100%;

    img{
        width: 100%;
        height: 100%;
    }
}

.facebook-head-btns{
   
    display: flex;
    height: 100;
    align-items: center;
}

.facebook-head-btn-item_container{
    
    height: 30px;
    width: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 70%;
        height: 70%;
    }
}

#facebook-head-btn-search{
    width: 80%;
    height: 80%;
}


.facebook-head-menu_container{
    border-bottom: 1px solid rgb(209, 209, 209);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.facebook-head-menu-item{
    
    max-width: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#facebook-head-menu-item_home{
    border-bottom: 2px solid #0866ff;
}

.facebook-head-add-post_container{
 
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid #d1d1d1;
}

#facebook-head-add-post_item-acc{

 max-width: 40px;
 max-height: 40px;
 width: 100%;
 height: 100%;
 border-radius: 50%;
 margin-left: 1%;
 background: #E3E4E8;
 display: flex;
 justify-content: center;
 align-items: end;
}

#facebook-head-add-post_item-textfield{
    max-width: 230px;
    max-height: 30px;
    width: 100%;
    height: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 25px;
    margin-right: 2%;
    display: flex;
    align-items: center;

    div{
        color: black;
        font-size: 16px;
        margin-left: 15px;
        
    }
}

#facebook-head-add-post_item-add_img{

    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    margin-right: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}


.facebook-head-app-carousel_container{
    overflow: hidden;
    height: 180px;
   
}

.facebook-head-app-carousel-item{
    background: rgb(172, 172, 172);
    height: 160px;
    width: 75%;
    margin-left: 15px;
    border-radius: 15px;

    img{
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }
}

.facebook-head-app-carousel-items{
    height: 175px;
    margin-top: 10px;
    border-bottom: 4px solid #d1d1d1;
}