.prime-main-pic{
    
    width: 100%;
    height: 635px;
    
    position: absolute;
    top: 0;
    
}

#prime-main-pic-swiper{
    width: 100%;
    height: 100%;
  
    display: flex;
    justify-content: center;
    cursor: pointer;
}


.prime-main-pic-slide{
    
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
 
}

.prime-main-pic-slide-mainimg{
    position: absolute;
    width: 100%;

    
}

.prime-main-pic-slide-mainimg-name{
    width: 35%;
    height: 100%;
    z-index: 1;
    position: relative;
  
    display: flex;
    //background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 55%, transparent);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 55%, transparent);
    padding-left: 3%;
    flex-direction: column;
    justify-content: center;

    img{
        width: 100%;
        height: 100%;
    }

    span{
       
        color: white;
        font-size: 19px;
        margin-left: 2%;
        font-weight: 600;
    }
}

#prime-mainpic-title0{
    width: 45%;
    height: 100%;
}

.prime-mainpic-btns{
    
    width: 100%;
    height: 155px;
    display: flex;
    align-items: center;

    span{
        padding-right: 5%;
    }
}


.prime-main-pic-primeLabel{
    margin-top: 5%;
    display: flex;
    align-items: center;
   

}

.prime-mainpic-playbtn{
 
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

 
}

.prime-mainpic-btn{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: hsla(0,0%,100%,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
   
}


#prime-main-pic-swiper::part(button-next) {
    
    color: white;
    width: 0.8vw;
    opacity: 0;
    transition: 0.5s width;
  }
  
  
  
  #prime-main-pic-swiper::part(button-prev) {

    color: white;
    width: 0.8vw;
    opacity: 0;
    transition: 0.5s width;
   
  }
  #prime-main-pic-swiper::part(pagination) {

  position: absolute;
  top: 100%;
  
  
   
  }

  #prime-main-pic-swiper::part(bullet) {

background-color: gray;
   
  }
  #prime-main-pic-swiper::part(bullet-active) {

background-color: white;
   
  }

.prime-main-pic:hover{


#prime-main-pic-swiper::part(button-next) {

   
        opacity: 1;
        
        
      }
      
#prime-main-pic-swiper::part(button-prev) {
    
        opacity: 1;
       
      }

}

#prime-main-pic-swiper::part(button-next):hover {

   
   width: 1.1vw;
    
  }

#prime-main-pic-swiper::part(button-prev):hover {

   
   width: 1.1vw;
    
  }

