.tiktok-content{
 
  height: 100dvh;  //95%

  top: 0;
  background: black;
}

.tiktok_video-content{
  width: 100%;
  height: 100dvh;


  video{
 
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Timeline

.tiktok_video-content_timeline-container{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: black;
  z-index: 3;
  display: none;
 
}

.tiktok_video-content_timeline{
  width: 0%;
  height: 100%;
  background: white;
  transition: 1s linear all;
}

// Video Btns

.tiktok_contentVideo_btns-container{

  height: 400px;
  width: 50px;
  position: absolute;
  right: 5px;
  bottom: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
  transition: 0.5s all;

 
}

.tiktok_contentVideo_btn{

  flex-basis: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 13px;
  color: white;
  opacity: 1;
  line-height: 25px;
  

}

.tiktok_contentVideo_circle{
  border: 1px solid white;
  width: 80%;
  height: 65%;
  border-radius: 50%;
  
}

.tiktok_contentVideo_circle{
  
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    
  }
 
}

#tiktok_contentVideo_circle_spin{
  border: none;
  //animation: tiktokspin 8s infinite linear;
  
}


@keyframes tiktokspin{
  from {
    transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}


// Play Content Dialog

.tiktok-playContent-dialog{
  background-color: white;
  position: absolute;
  height: 100%;
  z-index: 5;
  width: 100%;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.tiktok-playContent_btn-container{
 
  width: 130px;
  height: 50px;

}

.tiktok-playContent_btn-text{
  
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  top: 150px;
}

.tiktok-playContent_btn{
  width: 100%;
  height: 100%;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  background: black;
  border-radius: 6px;
 
}

.tiktok_contentVideo_circle-plus{
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
}

// Top View Ads

.tiktok-top_view{
  width: 100%;
  height: 100dvh;
  background: black;
  position: absolute;
  top: 0;
  z-index: 3;

}

.tiktok-top_view-head{

  position: absolute;
  top: 0;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  transition: 0.5s all;
}

.tiktok-top_view-contaniner{
  position: relative;
  width: 100%;
  height: 100%;
  
}

.tiktok-top_view-icon_container{
  height: 100%;
  max-width: 120px;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;

  img{
    width: 100%;
    height: 100%;
  }
}

.tiktok-top_view-icon{
  height: 80%;
 
  
}

.tiktok-top_view-logo_name{
  color: white;
  text-shadow: 0px 0px 4px black;
  font-size: 23px;
  font-weight: 600;
}

.tiktok-top_view_skip-btn{
  border: 1px solid;
  height: 100%;
  max-width: 80px;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius:20px;
  margin-right: 10px;
  color: white;
  background: rgb(0, 0, 0);
  opacity: 0.25;
  font-size: 13px;
  z-index: 5;
  position: relative;
  
}

.tiktok-top_view_video-container{
  height: 100%;
  width: 100%;

  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tiktok-top_view_footer{

    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
}

.tiktok-top_view_footer-text{
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  text-shadow: 0px 0px 4px black;
  opacity: 0;
  transition: 0.5s all;
}

.tiktok-top_view_footer-text::before{
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  border-top: 2px solid white;
  border-left: 2px solid;
  transform: rotate(45deg);

}

// loader

.tiktok_video_loader{
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 

}

